import colorPalette from "hooks/ChartColor";
export const pieChartOption = {
  series: [
    {
      name: "Access From",
      type: "pie",
      radius: ["30%", "70%"],
      avoidLabelOverlap: false,
      label: {
        show: true,
        color: "#ADB5BD",
        fontSize: 10,
      },
      // emphasis: {
      //   label: {
      //     show: true,
      //     color: "#ADB5BD",
      //     fontSize: 8,
      //   },
      // },
      data: [
        { value: 1048, name: "Search Engine" },
        { value: 735, name: "Direct" },
        { value: 580, name: "Email" },
        { value: 484, name: "Union Ads" },
        { value: 300, name: "Video Ads" },
      ],
      color: colorPalette,
    },
  ],
};

import React, { useEffect, useRef, useState } from "react";
import styles from "./LoginBanner.module.scss"; // CSS 모듈 사용
import banner1 from "assets/images/banner/banner1.png";
import banner2 from "assets/images/banner/banner2.png";
import banner3 from "assets/images/banner/banner3.png";
import banner4 from "assets/images/banner/banner4.png";

const banners = [banner1, banner2, banner3, banner4];

function getNextIndex(length, idx) {
  return (idx + 1) % length;
}

const LoginBanner = () => {
  const [bannerIndex, setBannerIndex] = useState(0);
  const intervalRef = useRef(null);

  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      setBannerIndex((prev) => getNextIndex(banners.length, prev));
    }, 4000);
  };

  const onClickDot = (index) => {
    setBannerIndex(index);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      startInterval();
    }
  };

  useEffect(() => {
    startInterval();
    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <div className={styles.bannerContainer}>
      <img
        className={styles.loginBanner}
        src={banners[bannerIndex] ?? ""}
        alt="banner"
      />
      <BannerDot current={bannerIndex} onClickDot={onClickDot} />
    </div>
  );
};

const BannerDot = ({ current, onClickDot }) => {
  return (
    <div className={styles.dotWrap}>
      {banners.map((_, index) => (
        <div
          key={index}
          className={`${styles.dot} ${index === current ? styles.current : ""}`}
          onClick={() => onClickDot(index)}
        />
      ))}
    </div>
  );
};

export default LoginBanner;

import { mdiAlertCircle, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { IconButton } from "@mui/material";
import ComponentHeader from "components/componentheader/ComponentHeader";
import CustomTable from "components/customtable/CustomTable";
import { deleteCluster, getClusterList, getCurrentDate, insertCluster } from "data/CommonFunction";
import { openModalAtom, refreshTimeAtom } from "data/Store";
import getTableHeader from "hooks/GetTableHeader";
import MakeToast from "hooks/MakeToast";
import { useAtom, useSetAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";

export const MgmtCluster = () => {
  const headerItems = [
    {
      name: "번호",
      key: "clusterNo",
      width: "90px",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "클러스터명",
      key: "clusterName",
      width: "200px",
      center: 'no',
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "지역",
      key: "clusterLocation",
      center: 'no',
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "grafana 정보",
      key: "clusterDatasource",
      width: '300px',
      center: 'no',
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "VIM",
      key: "clusterVim",
      center: 'no',
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "",
      key: "delete",
      width: '120px',
      type: "delete",
      action: (_action, row) => setShowDeleteDialog(row),
    },
  ];

  const clickedRow = (v) => {
    console.debug(v);
  };

  const setRefreshTime = useSetAtom(refreshTimeAtom);
  const [clusterData, setClusterData] = useState();
  const [openModal, setOpenModal] = useAtom(openModalAtom);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showAddService, setShowAddService] = useState(false);

  const initClusterList = useCallback(() => {
    getClusterList().then((clusterResp) => {
      setClusterData(clusterResp);
    });

    setRefreshTime(getCurrentDate());
  }, [setRefreshTime]);

  const addService = () => {
    setShowAddService({
      clusterDatasource: "",
      clusterLocation: "",
      clusterName: "",
      clusterDomain: "",
      clusterVim: "",
      x: 0,
      y: 0,
      z: 0,
    });
  };

  useEffect(() => {
    initClusterList();

    if (openModal) {
      setOpenModal(false);
      addService();
    }
  }, [initClusterList, openModal, setOpenModal]);

  const deleteAction = (clusterNo) => {
    deleteCluster(clusterNo).then((result) => {
      if (result) {
        initClusterList();
        setShowDeleteDialog(false);
        MakeToast("success", `정상적으로 삭제가 완료되었습니다.`);
      }
    });
  };
  const getDeleteDialog = () => {
    return (
      <div className="modal-wrapper">
        <div className="modal-box">
          <div className="sm-dialog-header">
            <div className="flexRow N">
              <Icon path={mdiAlertCircle} size="18px" color="#F06262" />
              <p>{showDeleteDialog.clusterName} 삭제</p>
            </div>
          </div>

          <div className="sm-dialog-contents">
            <p>해당 클러스터를 삭제 하시겠습니까?</p>
          </div>
          <div className="sm-dialog-btn-wrap">
            <button className="btn cancel-btn" onClick={() => setShowDeleteDialog(false)}>
              취소
            </button>
            <button className="btn submit-btn" onClick={() => deleteAction(showDeleteDialog.clusterNo)}>
              확인
            </button>
          </div>
        </div>
      </div>
    );
  };

  const addCluster = () => {
    insertCluster(showAddService).then((result) => {
      if (result) {
        MakeToast("success", "정상적으로 클러스터가 생성 되었습니다.");
        initClusterList();
        setShowAddService(false);
      }
    });
  };

  return (
    <div className="page">
      {showDeleteDialog && getDeleteDialog()}
      {showAddService && (
        <div className="modal-wrapper">
          <div className="modal-box">
            <div className="modal-header">
              <p className="title">MEC 클러스터 생성</p>
              <IconButton onClick={() => setShowAddService(false)}>
                <Icon path={mdiClose} size="20px" color="#fff" />
              </IconButton>
            </div>
            <div className="modal-contents">
              <div className="add-service-wrap">
                <div className="flexCol">
                  <p className="label require">클러스터명</p>
                  <input
                    type="text"
                    placeholder="입력하세요."
                    value={showAddService.clusterName}
                    onChange={(e) =>
                      setShowAddService((prev) => {
                        return { ...prev, clusterName: e.target.value, clusterDomain: e.target.value };
                      })
                    }
                  />
                  <p className="label require">지역</p>
                  <input
                    type="text"
                    placeholder="입력하세요."
                    value={showAddService.clusterLocation}
                    onChange={(e) =>
                      setShowAddService((prev) => {
                        return { ...prev, clusterLocation: e.target.value };
                      })
                    }
                  />
                  <p className="label require">grafana 정보</p>
                  <input
                    type="text"
                    placeholder="입력하세요."
                    value={showAddService.clusterDatasource}
                    onChange={(e) =>
                      setShowAddService((prev) => {
                        return { ...prev, clusterDatasource: e.target.value };
                      })
                    }
                  />
                  <p className="label require">VIM</p>
                  <input
                    type="text"
                    placeholder="입력하세요."
                    value={showAddService.clusterVim}
                    onChange={(e) =>
                      setShowAddService((prev) => {
                        return { ...prev, clusterVim: e.target.value };
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="modal-btn-wrap" style={{ background: "inherit" }}>
              <button className="btn cancel-btn border-btn-main" onClick={() => setShowAddService(false)}>
                취소
              </button>
              <button className={`btn ${false ? "error" : ""} border-btn-main`} onClick={() => addCluster()}>
                생성
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="page-inner">
        <div className="mymec-service-wrap">
          <ComponentHeader title="클러스터 관리" addBtn={addService} />
          <div className="table-scroll-wrap">
            <CustomTable header={getTableHeader(headerItems)} rowData={clusterData} perPage={15} rowClickCallBack={clickedRow} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MgmtCluster;

import { mdiCheck, mdiChevronRight, mdiClose, mdiDelete, mdiPlay, mdiStop } from "@mdi/js";
import Icon from "@mdi/react";
import Tooltip from "@mui/material/Tooltip";
import { AppStatus, NormalStatus, ServiceStatus, agreeStatus, userAgreeStatus } from "data/StatusChipDatas";

const getDate = (date) => {
  const convertDate = new Date(date);

  const year = convertDate.getFullYear();
  const month = String(convertDate.getMonth() + 1).padStart(2, "0");
  const day = String(convertDate.getDate()).padStart(2, "0");

  let hours = convertDate.getHours();
  const minutes = String(convertDate.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "오후" : "오전";

  hours = hours % 12;
  hours = hours ? hours : 12;
  const formattedTime = `${ampm} ${String(hours).padStart(2, "0")}:${minutes}`;

  return `${year}-${month}-${day} / ${formattedTime}`;
};

const validResourceModal = (type, v) => {
  return (type === "app" && v === "run") || (type === "service" && v === "service") || (type === "userAgree" && v === "service");
};

const onClickFunc = (type, v, row, item) => {
  if (validResourceModal(type, v)) {
    return { onClick: () => item.action(row) };
  } else {
    return undefined;
  }
};

const getStatus = (type, v, row, item) => {
  if (v) {
    let status = AppStatus;
    switch (type) {
      case "service":
        status = ServiceStatus;
        break;
      case "normal":
        status = NormalStatus;
        break;
      case "agree":
        status = agreeStatus;
        break;
      case "userAgree":
        status = userAgreeStatus;
        break;
      default:
        break;
    }
    return (
      <div
        className={`status-chip flexRow ${validResourceModal(type, v) ? "is_running" : ""}`}
        style={{
          background: status[v].bgColor,
          color: status[v].color,
        }}
        {...onClickFunc(type, v, row, item)}
      >
        <p>{status[v].text}</p>
      </div>
    );
  }
};
export const getTableHeader = (items) => {
  const returnHeaderItems = [];
  items.forEach((item) => {
    const obj = {
      name: item.name,
      selector: (row) => row[item.key],
      width: item.width ? item.width : null,
      center: !item.center ? "true" : null,
      sortable: item.sortable ? true : false,
      cell: (row) => {
        if (Object.keys(row).length === 0) return <></>;
        switch (item.type) {
          case "text":
            return (
              <Tooltip title={row[item.key]}>
                <div onClick={() => item.action(row)}>{row[item.key]}</div>
              </Tooltip>
            );
          case "textUnderLine":
            return (
              <Tooltip title={row[item.key]}>
                <div style={{ textDecoration: "underline" }} onClick={() => item.action(row)}>
                  {row[item.key]}
                </div>
              </Tooltip>
            );
          case "date":
            return (
              <Tooltip title={getDate(row[item.key])}>
                <div onClick={() => item.action(row)}>{getDate(row[item.key])}</div>
              </Tooltip>
            );
          case "detail":
            return (
              <button className="table-go-detail-btn flexRow" onClick={() => item.action(row)}>
                <p>상세</p> <Icon path={mdiChevronRight} size="17px" />
              </button>
            );
          case "request":
            return row.userAgreeStatus === "nopending" ? (
              <button className="flexCol gray-btn-main" onClick={() => item.action(true, row)} style={{fontSize:'16px'}}>
                <p>요청하기</p>
              </button>
            ) : (
              <button className="flexCol gray-btn-main" onClick={() => item.action(false, row)} style={{fontSize:'16px'}}>
                <p>요청취소</p>
              </button>
            );
          case "appStatus":
            return getStatus("app", row[item.key], row, item);
          case "serviceStatus":
            return getStatus("service", row[item.key], row, item);
          case "normalStatus":
            return getStatus("normal", row[item.key], row, item);
          case "agreeStatus":
            return getStatus("agree", row[item.key], row, item);
          case "userAgreeStatus":
            return getStatus("userAgree", row[item.key], row, item);
          case "agreeYn":
            return row[item.key] === "Y" ? (
              <></>
            ) : (
              <div className="flexRow agree-yn">
                <Tooltip title="요청 승인">
                  <button onClick={() => item.action("Y", row)}>
                    <Icon path={mdiCheck} size="26px" />
                  </button>
                </Tooltip>
                <Tooltip title="요청 거절">
                  <button onClick={() => item.action("N", row)}>
                    <Icon path={mdiClose} size="26px" />
                  </button>
                </Tooltip>
              </div>
            );
          case "agreeUsers":
            return (
              <Tooltip title="요청 목록">
                <div className="agree-users" onClick={() => item.action(row)}>
                  {row[item.key][0]}/{row[item.key][1]}
                </div>
              </Tooltip>
            );
          case "control":
            return ["approve", "service"].includes(row.userAgreeStatus) ? (
              row.isServiced ? (
                <button className="control-btn border-btn-main" onClick={() => item.action("stop", row)}>
                  <Icon path={mdiStop} size="18px" color="#ADB5BD" />
                </button>
              ) : (
                <button className="control-btn border-btn-main" onClick={() => item.action("run", row)}>
                  <Icon path={mdiPlay} size="18px" color="#ADB5BD" />
                </button>
              )
            ) : (
              row.userAgreeStatus === "nopending" && (
                <button className="control-btn border-btn-main" onClick={() => item.action("request", row)}>
                  <Icon path={mdiCheck} size="18px" color="#ADB5BD" />
                </button>
              )
            );
          case "control2":
            return row.appStatus === "run" ? (
              <button className="control-btn border-btn-main" onClick={() => item.action("stop", row)}>
                <Icon path={mdiStop} size="18px" color="#ADB5BD" />
              </button>
            ) : (
              <button className="control-btn border-btn-main" onClick={() => item.action("run", row)}>
                <Icon path={mdiPlay} size="20px" color="#ADB5BD" />
              </button>
            );
          case "delete":
            return (
              <button className="control-btn border-btn-main" onClick={() => item.action("stop", row)}>
                <Icon path={mdiDelete} size="18px" color="#ADB5BD" />
              </button>
            );
          default:
            break;
        }
      },
    };
    returnHeaderItems.push(obj);
  });
  return returnHeaderItems;
};
export default getTableHeader;

import "./CustomCodeMirror.scss";
import CodeMirror from "react-codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
export const CustomCodeMirror = ({ value, isEdit, updateCallBack }) => {
  const options = {
    lineWrapping: true,
    lint: true,
    // mode: language,
    theme: "material",
    lineNumbers: true,
  };
  const handleChange = (value) => {
    updateCallBack(value);
  };
  return (
    <CodeMirror
      value={value}
      options={{ ...options, readOnly: !isEdit }}
      onChange={(v) => handleChange(v)}
    />
  );
};
export default CustomCodeMirror;

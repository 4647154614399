import { mdiEye, mdiEyeOff } from "@mdi/js";
import Icon from "@mdi/react";
import { Grid } from "@mui/material";
import axios from "axios";
import SignUpModal from "components/signup_modal/SignUpModal";
import { userAuthAtom } from "data/Store";
import MakeToast from "hooks/MakeToast";
import { useSetAtom } from "jotai";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import LoginWrap from "../../components/login/LoginWarp";
import LoginBanner from "../../components/login/LoginBanner";

const Login = () => {
  const navigate = useNavigate();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const [showSignUpModal, setShowSignUpModal] = useState(false);

  const [idError] = useState(false);
  const [pwError] = useState(false);
  const [inputId, setInputId] = useState("");
  const [inputPw, setInputPw] = useState("");
  const setUserAuth = useSetAtom(userAuthAtom);
  const loginConfirm = () => {
    axios
      .post("/api/user/login", { userId: inputId, userPasswd: inputPw })
      .then((loginResp) => {
        setUserAuth(loginResp.data);
        navigate("/");
      })
      .catch((err) => {
        MakeToast("fail", "로그인에 실패하였습니다.");
      });
  };

  return (
    <LoginWrap>
      <div className="page login-page">
        {showSignUpModal && <SignUpModal isShowHandler={setShowSignUpModal} />}
        <Grid container direction="row">
          <Grid
            item
            xs={12}
            md={8}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            <div className={"banner-area"}>
              <LoginBanner />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            alignItems={"center"}
            justifyContent={"center"}
            style={{
              display: "flex",
            }}
          >
            <div className="login-box-wrap">
              <div className="login-box">
                <div className="login-input-wrap">
                  <div className="logo">
                    <div className={"logo-giga"}>Giga</div>
                    <div className={"logo-mec"}>MEC</div>
                  </div>
                  <div className={"input-wrap"}>
                    <label>
                      <p>
                        아이디
                        <span className="require"></span>
                      </p>
                      <input
                        type="text"
                        className={idError ? "error" : ""}
                        value={inputId}
                        onChange={(e) => setInputId(e.target.value)}
                      />
                      <div className="error-msg">
                        {idError && <p>에러메세지</p>}
                      </div>
                    </label>
                    <label>
                      <p>
                        비밀번호
                        <span className="require"></span>
                      </p>
                      <div className="password-wrap">
                        <input
                          className={pwError ? "error" : ""}
                          type={passwordVisible ? "text" : "password"}
                          value={inputPw}
                          onChange={(e) => setInputPw(e.target.value)}
                          onKeyUp={(e) => {
                            e.key === "Enter" && loginConfirm();
                          }}
                        />
                        <Icon
                          path={passwordVisible ? mdiEye : mdiEyeOff}
                          size="20px"
                          color={pwError ? "#f06262" : "#9199A1"}
                          onClick={togglePasswordVisibility}
                          value={inputPw}
                          onChange={(e) => setInputPw(e.target.value)}
                        />
                      </div>
                      <div className="error-msg">
                        {pwError && <p>에러메세지</p>}
                      </div>
                    </label>
                    <div
                      className={`main-btn ${idError || pwError ? "disabled" : ""}`}
                      onClick={() => loginConfirm()}
                    >
                      로그인
                    </div>
                    <div
                      className="main-btn signup"
                      onClick={() => setShowSignUpModal(true)}
                    >
                      회원가입
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </LoginWrap>
  );
};

export default Login;

import { mdiClose, mdiEye, mdiEyeOff } from "@mdi/js";
import Icon from "@mdi/react";
import { IconButton } from "@mui/material";
import axios from "axios";
import MakeToast from "hooks/MakeToast";
import { useState } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import "./SignUpModal.scss";

export const SignUpModal = ({ isShowHandler }) => {
  const [passwordSignUpVisible, setPasswordSignUpVisible] = useState(false);
  const togglePasswordSignUpVisibility = () => {
    setPasswordSignUpVisible(!passwordSignUpVisible);
  };

  const postcode = useDaumPostcodePopup();

  const [pwError,] = useState(false);
  const [pwConfirmError,] = useState(false);
  const [userData, setUserData] = useState({
    mecUserTypeNo: 1,
    userAddress: "",
    userAddress2: "",
    userBirth: "",
    userEmail: "",
    userId: "",
    userName: "",
    userOrgAddress: "",
    userOrgAddress2: "",
    userOrgPostcode: "",
    userOrganization: "",
    userPasswd: "",
    userPhone: "",
    userPosition: "",
    userPostcode: "",
    userPasswdChk: "",
  });
  //input들에 error를 붙이면 빨간 에러표시가 남, 에러글자는 빨간색 고정
  return (
    <div className="modal-wrapper">
      <div className="modal-box">
        <div className="modal-header">
          <p className="title">GigaMEC 회원가입</p>
          <IconButton onClick={() => isShowHandler(false)}>
            <Icon path={mdiClose} size="20px" color="#fff" />
          </IconButton>
        </div>
        <div className="modal-contents">
          <div className="signup-contents flexCol">
            <div className="signup-grid flexRow">
              <div className="signup-input-wrap flexCol">
                <p className="label require">아이디</p>
                <input
                  type="text"
                  value={userData.userId}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, userId: e.target.value };
                    })
                  }
                  placeholder="입력하세요."
                />
                {/*<div className="input-msg">에러메시지</div>*/}
              </div>
            </div>
            <div className="signup-grid flexRow">
              <div className="signup-input-wrap flexCol">
                <p className="label require">비밀번호</p>
                <div className="password-wrap">
                  <input
                    type={passwordSignUpVisible ? "text" : "password"}
                    placeholder="입력하세요."
                    className={pwError ? "error" : ""}
                    value={userData.userPasswd}
                    onChange={(e) =>
                      setUserData((prev) => {
                        return { ...prev, userPasswd: e.target.value };
                      })
                    }
                  />
                  <Icon
                    path={passwordSignUpVisible ? mdiEye : mdiEyeOff}
                    size="20px"
                    color={pwError ? "#f06262" : "#9199A1"}
                    onClick={togglePasswordSignUpVisibility}
                  />
                </div>
                {/*<div className="input-msg">에러메시지</div>*/}
              </div>
              <div className="signup-input-wrap flexCol">
                <p className="label require">비밀번호 확인</p>
                <div className="password-wrap">
                  <input
                    type={passwordSignUpVisible ? "text" : "password"}
                    placeholder="입력하세요."
                    className={pwConfirmError ? "error" : ""}
                    value={userData.userPasswdChk}
                    onChange={(e) =>
                      setUserData((prev) => {
                        return { ...prev, userPasswdChk: e.target.value };
                      })
                    }
                  />
                  <Icon
                    path={passwordSignUpVisible ? mdiEye : mdiEyeOff}
                    size="20px"
                    color={pwConfirmError ? "#f06262" : "#9199A1"}
                    onClick={togglePasswordSignUpVisibility}
                  />
                </div>
                {/*<div className="input-msg">에러메시지</div>*/}
              </div>
            </div>
            <div className="signup-grid flexRow">
              <div className="signup-input-wrap flexCol">
                <p className="label require">이름</p>
                <input
                  type="text"
                  placeholder="입력하세요."
                  value={userData.userName}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, userName: e.target.value };
                    })
                  }
                />
                {/*<div className="input-msg">에러메시지</div>*/}
              </div>
              <div className="signup-input-wrap flexCol">
                <p className="label require">생년월일</p>
                <input
                  type="text"
                  placeholder="yyyy/MM/dd"
                  value={userData.userBirth}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, userBirth: e.target.value };
                    })
                  }
                />
                {/*<div className="input-msg">에러메시지</div>*/}
              </div>
            </div>
            <div className="signup-grid flexRow">
              <div className="signup-input-wrap flexCol">
                <p className="label require">휴대폰</p>
                <input
                  type="text"
                  placeholder="입력하세요."
                  value={userData.userPhone}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, userPhone: e.target.value };
                    })
                  }
                />
                {/*<div className="input-msg">에러메시지</div>*/}
              </div>
              <div className="signup-input-wrap flexCol">
                <p className="label require">이메일</p>
                <input
                  type="text"
                  placeholder="입력하세요."
                  value={userData.userEmail}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, userEmail: e.target.value };
                    })
                  }
                />
                {/*<div className="input-msg">에러메시지</div>*/}
              </div>
            </div>
            <div className="signup-grid flexRow">
              <div className="signup-input-wrap flexCol">
                <p className="label require">주소</p>
                <input
                  type="text"
                  placeholder="우편번호"
                  value={userData.userPostcode}
                  readOnly
                />
              </div>
              <div className="post-btn-wrap flexCol">
                <button
                  onClick={() => {
                    postcode({
                      onComplete: (data) => {
                        setUserData((prev) => {
                          return {
                            ...prev,
                            userPostcode: data.zonecode,
                            userAddress:
                              data.userSelectedType === "R"
                                ? data.roadAddress
                                : data.jibunAddress,
                          };
                        });
                      },
                    });
                  }}
                >
                  우편번호 검색
                </button>
              </div>
            </div>
            <div className="alone-grid">
              <div className="signup-input-wrap flexCol">
                <input
                  type="text"
                  placeholder="주소"
                  value={userData.userAddress}
                  readOnly
                />
              </div>
            </div>
            <div className="alone-grid">
              <div className="signup-input-wrap flexCol">
                <input
                  type="text"
                  placeholder="상세주소"
                  value={userData.userAddress2}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, userAddress2: e.target.value };
                    })
                  }
                />
              </div>
            </div>
            <div className="signup-grid flexRow">
              <div className="signup-input-wrap flexCol">
                <p className="label require">소속</p>
                <input
                  type="text"
                  placeholder="입력하세요."
                  value={userData.userOrganization}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, userOrganization: e.target.value };
                    })
                  }
                />
                {/*<div className="input-msg">에러메시지</div>*/}
              </div>
              <div className="signup-input-wrap flexCol">
                <p className="label require">직책</p>
                <input
                  type="text"
                  placeholder="입력하세요."
                  value={userData.userPosition}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, userPosition: e.target.value };
                    })
                  }
                />
                {/*<div className="input-msg">에러메시지</div>*/}
              </div>
            </div>
            <div className="signup-grid flexRow">
              <div className="signup-input-wrap flexCol">
                <p className="label require">주소</p>
                <input
                  type="text"
                  placeholder="우편번호"
                  value={userData.userOrgPostcode}
                  readOnly
                />
              </div>
              <div className="post-btn-wrap flexCol">
                <button
                  onClick={() => {
                    postcode({
                      onComplete: (data) => {
                        setUserData((prev) => {
                          return {
                            ...prev,
                            userOrgPostcode: data.zonecode,
                            userOrgAddress:
                              data.userSelectedType === "R"
                                ? data.roadAddress
                                : data.jibunAddress,
                          };
                        });
                      },
                    });
                  }}
                >
                  우편번호 검색
                </button>
              </div>
            </div>
            <div className="alone-grid">
              <div className="signup-input-wrap flexCol">
                <input
                  type="text"
                  placeholder="주소"
                  value={userData.userOrgAddress}
                  readOnly
                />
              </div>
            </div>
            <div className="alone-grid">
              <div className="signup-input-wrap flexCol">
                <input
                  type="text"
                  placeholder="상세주소"
                  value={userData.userOrgAddress2}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, userOrgAddress2: e.target.value };
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-btn-wrap">
          <button
            className="btn cancel-btn border-btn-main"
            onClick={() => isShowHandler(false)}
          >
            취소
          </button>
          <button
            className={`btn ${false ? "error" : ""} blue-btn-main`}
            onClick={() => {
              let validFlag = true;
              for (let i = 0; i < Object.keys(userData).length - 1; i++) {
                const property = Object.keys(userData)[i];
                if (userData[property] === "") {
                  MakeToast("fail", `${property} 값이 누락되었습니다.`);
                  validFlag = false;
                  break;
                }
              }

              if (validFlag && userData.userPasswd !== userData.userPasswdChk) {
                MakeToast("fail", `비밀번호를 확인해주세요.`);
                validFlag = false;
              }

              validFlag &&
                axios
                  .post("/api/user", userData)
                  .then(() => {
                    MakeToast("success", "가입요청이 안료되었습니다.");
                    isShowHandler(false);
                  })
                  .catch((userErr) => {
                    MakeToast(
                      "fail",
                      userErr.response.data?.message || userErr.response.data
                    );
                  });
            }}
          >
            가입신청
          </button>
        </div>
      </div>
    </div>
  );
};
export default SignUpModal;

import axios from "axios";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import isLeapYear from "dayjs/plugin/isLeapYear";
import MakeToast from "hooks/MakeToast";
import { useEffect, useRef } from "react";

dayjs.extend(isLeapYear);
dayjs.locale("ko");

const CancelToken = axios.CancelToken;
let cancelList = [];

export const axiosCancel = () => {
  cancelList.forEach((cancel) => {
    cancel();
  });

  cancelList = [];
};

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const getAppStatus = async () => {
  try {
    let result = {};
    const userRes = await axios.get("/api/userns", {
      cancelToken: new CancelToken(function executor(c) {
        cancelList.push(c);
      }),
    });
    const appRes = await axios.get("/api/mecapp", {
      cancelToken: new CancelToken(function executor(c) {
        cancelList.push(c);
      }),
    });

    let initCnt = 0,
      runCnt = 0,
      stopCnt = 0,
      userArr = [],
      appCnt = appRes ? appRes.data.length : 0;

    appRes?.data.map((appRow) => {
      userRes?.data.map((userRow) => {
        if (userRow.userNsNo === appRow.mecUserNsNo) {
          appRow.userNsName = userRow.userNsName;
        }
        return userRow;
      });

      if (!userArr.includes(appRow.mecUserNsNo)) {
        userArr.push(appRow.mecUserNsNo);
      }

      if (appRow.mecAppStatus === "init") {
        appRow.appStatus = "init";
        initCnt++;
      } else if (appRow.mecAppStatus === "Running") {
        appRow.appStatus = "run";
        runCnt++;
      } else {
        appRow.appStatus = "stop";
        stopCnt++;
      }
      return appRow;
    });

    result = {
      user: userArr.length,
      app: appCnt,
      init: initCnt,
      run: runCnt,
      stop: stopCnt,
    };

    return result;
  } catch (error) {
    return null;
  }
};

export const getServiceData = async (userNo) => {
  try {
    let result = {};
    const serviceRes = await axios.get("/api/mecservice2", {
      cancelToken: new CancelToken(function executor(c) {
        cancelList.push(c);
      }),
    });
    const userInServiceResp = await axios
      .get("/api/mecservice2/user", {
        cancelToken: new CancelToken(function executor(c) {
          cancelList.push(c);
        }),
      })
      .catch((error) => {
        MakeToast("fail", error.message || error.response.data.message);
        return [];
      });
    const userNsResp = await axios.get("/api/userns", {
      cancelToken: new CancelToken(function executor(c) {
        cancelList.push(c);
      }),
    });

    let reqCnt = 0,
      apprCnt = 0,
      totalCnt = serviceRes ? serviceRes.data.length : 0,
      delCnt = 0,
      rejCnt = 0,
      waitCnt = 0,
      reqUserCnt = 0,
      apprUserCnt = 0,
      runCnt = 0;

    if (userNo) {
      const myUserNsNo = userNsResp.data.filter((userNs) => userNs.mecUserNo === userNo)[0].userNsNo;

      let myServiceResult = [];
      let allServiceResult = [];

      serviceRes.data.forEach((mySvc) => {
        const myService = { ...mySvc };
        if (myService.mecUserNsNo === myUserNsNo) {
          myService.isServiced = false;

          switch (myService.isconfirm) {
            case 0:
              waitCnt++;
              myService.userAgreeStatus = "pending";
              break;
            case 1:
              if (myService.mecServiceStatus === "Running") {
                myService.isServiced = true;
                myService.userAgreeStatus = "service";
                runCnt++;
              } else {
                myService.userAgreeStatus = "approve";
                apprCnt++;
              }
              break;
            case 2:
              myService.userAgreeStatus = "denied";
              rejCnt++;
              break;
            case -1:
            default:
              myService.userAgreeStatus = "nopending";
              break;
          }

          myServiceResult.push(myService);
        }
      });

      serviceRes.data.forEach((allSvc) => {
        const allService = { ...allSvc };
        if (allService.isconfirm === 1) {
          allService.regdate = dayjs(allService.regdate).format("YYYY-MM-DD HH:mm:ss");

          const userNs = userNsResp.data.filter((user) => user.userNsNo === allService.mecUserNsNo);

          allService.mecUserNo = userNs.length > 0 ? userNs[0].mecUserNo : 0;
          allService.developer = userNs.length > 0 ? userNs[0].userNsName : "";
          allService.nowUser = userInServiceResp.data.filter(
            (userInSvc) => userInSvc.mecServiceNo === allService.serviceNo && userInSvc.isconfirm === 1
          ).length;

          const mySvcConfirm = userInServiceResp.data.filter((userInSvc) => userInSvc.mecServiceNo === allService.serviceNo && userInSvc.mecUserNo === userNo);

          allService.userAgreeStatus = "nopending";

          if (mySvcConfirm.length > 0) {
            switch (mySvcConfirm[0].isconfirm) {
              case 0:
                allService.userAgreeStatus = "pending";
                break;
              case 1:
                allService.userAgreeStatus = "approve";
                if (allService.mecServiceStatus === "Running") {
                  allService.userAgreeStatus = "service";
                }
                break;
              case 2:
                allService.userAgreeStatus = "denied";
                break;
              case -1:
              default:
                allService.userAgreeStatus = "nopending";
                break;
            }
          }

          allServiceResult.push(allService);
        }
      });

      allServiceResult.sort((a, b) => {
        if (a.userAgreeStatus === "service" && b.userAgreeStatus !== "service") return -1;
        if (a.userAgreeStatus !== "service" && b.userAgreeStatus === "service") return 1;

        return 0;
      });

      result = {
        myService: myServiceResult,
        allService: allServiceResult,
        totalCnt: myServiceResult.length,
        waitCnt: waitCnt,
        apprCnt: apprCnt,
        rejCnt: rejCnt,
        runCnt: runCnt,
        nopendingCnt: myServiceResult.length - (waitCnt + apprCnt + rejCnt + runCnt),
      };
    } else {
      serviceRes.data.map((serviceRow) => {
        serviceRow.agreeYn = "Y";
        switch (serviceRow.isconfirm) {
          case 2:
            rejCnt++;
            serviceRow.serviceState = "deniedApproval";
            break;
          case 0:
            reqCnt++;
            serviceRow.serviceState = "approval";
            serviceRow.agreeYn = "N";
            break;
          case 1:
            if (serviceRow.mecServiceStatus === "init") {
              serviceRow.serviceState = "standby";
              apprCnt++;
            } else {
              serviceRow.serviceState = "service";
              runCnt++;
            }
            break;
          default:
            delCnt++;
            serviceRow.serviceState = "beforeRequest";
            break;
        }
        const userNs = userNsResp.data.filter((user) => user.userNsNo === serviceRow.mecUserNsNo);
        serviceRow.userId = userNs.length > 0 ? userNs[0].userNsName : "";
        serviceRow.userNo = userNs.length > 0 ? userNs[0].mecUserNo : "";

        let confirmUser = 0,
          reqUser = 0;
        const userInSvc = userInServiceResp.data.filter((user) => user.mecServiceNo === serviceRow.serviceNo);
        userInSvc.map((user) => {
          user.regdate = dayjs(user.regdate).format("YYYY-MM-DD HH:mm:ss");
          switch (user.isconfirm) {
            case 1:
              user.agreeStatus = "ok";
              user.agreeYn = "Y";
              confirmUser++;
              break;
            case 0:
              user.agreeStatus = "call";
              user.agreeYn = "N";
              reqUser++;
              break;
            default:
              user.agreeStatus = "denied";
              user.agreeYn = "Y";
              break;
          }

          return user;
        });

        serviceRow.userInService = userInSvc;

        serviceRow.agreeUsers = [userInSvc.length, confirmUser];
        apprUserCnt += confirmUser;
        reqUserCnt += reqUser;

        serviceRow.regdate = dayjs(serviceRow.regdate).format("YYYY-MM-DD HH:mm:ss");

        return serviceRow;
      });

      result = {
        total: totalCnt,
        requested: reqCnt,
        approved: apprCnt,
        rejected: rejCnt,
        deleted: delCnt,
        running: runCnt,
        reqUserCnt: reqUserCnt,
        apprUserCnt: apprUserCnt,
        serviceList: serviceRes.data,
      };
    }

    return result;
  } catch (error) {
    return null;
  }
};

export const getUserStatus = async () => {
  try {
    let result = {};
    const userRes = await axios.get("/api/user", {
      cancelToken: new CancelToken(function executor(c) {
        cancelList.push(c);
      }),
    });

    let reqCnt = 0,
      apprCnt = 0,
      totalCnt = userRes ? userRes.data.length : 0,
      rejCnt = 0;

    userRes?.data.map((userRow) => {
      switch (userRow.isconfirm) {
        case 0:
          reqCnt++;
          break;
        case 1:
          apprCnt++;
          break;
        default:
          rejCnt++;
          break;
      }
      return userRow;
    });

    result = {
      total: totalCnt,
      requested: reqCnt,
      approved: apprCnt,
      rejected: rejCnt,
    };

    return result;
  } catch (error) {
    return null;
  }
};

export const getOrchStatus = async () => {
  try {
    let orchRes = {};
    try {
      orchRes = await axios.get("/api/mano/dayvimcount", {
        cancelToken: new CancelToken(function executor(c) {
          cancelList.push(c);
        }),
      });
    } catch (error) {
      orchRes = { data: { year: 0, month: 0, date: 0 } };
    }

    return orchRes.data;
  } catch (error) {
    return null;
  }
};

export const getClusterDetail = async (clusterNo) => {
  try {
    let result = {};
    const clusterDetail = await axios
      .get(`/api/meccluster/${clusterNo}`, {
        cancelToken: new CancelToken(function executor(c) {
          cancelList.push(c);
        }),
      })
      .catch((error) => {
        MakeToast("fail", "clusterDetail in getClusterDetail error: " + error.message || error.response.data.message);
        return { data: {} };
      });
    const clusterInfo = await axios
      .get(`/api/grafana/clusterInformation/${clusterNo}`, {
        cancelToken: new CancelToken(function executor(c) {
          cancelList.push(c);
        }),
      })
      .catch((error) => {
        MakeToast("fail", "clusterInfo in getClusterDetail error: " + error.message || error.response.data.message);
        return { data: {} };
      });

    !clusterInfo.data.networkinfo && MakeToast("fail", "Grafana Error : " + clusterDetail.data.clusterName ?? "");

    //setVim
    const vimResp = await axios.get(`/api/vim/setvim?vim=${clusterDetail.data.clusterVim}`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelList.push(c);
      }),
    });

    const nodeResult = [];
    const podResult = {};

    if (vimResp.data === "fail") {
      MakeToast("fail", "Vim Error : " + clusterDetail.data.clusterName ?? "");
    } else {
      const mecOrch = await axios
        .get(`/api/mano/orchestration/${clusterNo}`, {
          cancelToken: new CancelToken(function executor(c) {
            cancelList.push(c);
          }),
        })
        .catch((error) => {
          MakeToast("fail", "mecOrch in getClusterDetail error: " + error.message || error.response.data.message);
          return { data: { broker: 0, user: 0, vim: 0, method: 0 } };
        });

      clusterInfo.data["mecOrch"] = mecOrch.data;

      const nodeList = await axios
        .get(`/api/vim/listNode`, {
          cancelToken: new CancelToken(function executor(c) {
            cancelList.push(c);
          }),
        })
        .catch((error) => {
          MakeToast("fail", "vim error: " + error.message || error.response.data.message);
          return { data: [] };
        });

      const podList = await axios
        .get(`/api/vim/listPod`, {
          cancelToken: new CancelToken(function executor(c) {
            cancelList.push(c);
          }),
        })
        .catch((error) => {
          MakeToast("fail", "vim error: " + error.message || error.response.data.message);
          return { data: [] };
        });

      nodeList?.data.forEach((node) => {
        nodeResult.push({
          id: `${clusterNo}|${node.metadata.name}`,
          name: node.metadata.name,
          ip: node.status.addresses.filter((addr) => addr.type === "InternalIP")[0].address,
          date: dayjs(node.metadata.creationTimestamp).format("YYYY-MM-DD HH:mm:ss"),
        });
      });

      podList.data.forEach((pod) => {
        if (!podResult[pod.spec.nodeName]) {
          podResult[pod.spec.nodeName] = [];
        }

        podResult[pod.spec.nodeName].push({
          name: pod.metadata.name,
          ip: pod.status.podIP,
          createdDate: dayjs(pod.metadata.creationTimestamp).format("YYYY.MM.DD HH:mm:ss"),
        });
      });
    }

    const diskChart = { unit: "", legend: [], series: [], xAxis: [] };
    const diskIoRead = clusterInfo.data.diskinfo?.read ?? { unit: "", xAxis: [], legend: [] };
    const diskIoWrite = clusterInfo.data.diskinfo?.write ?? { unit: "", xAxis: [], legend: [] };
    diskChart.unit = diskIoRead.unit;
    diskChart.xAxis = diskIoRead.xAxis.map((xtime) => {
      return dayjs.unix(xtime).format("HH:mm:ss");
    });

    diskIoRead.legend.forEach((readNode) => {
      diskChart.legend.push(`${readNode}-Read`);
      const series = {
        name: `${readNode}-Read`,
        type: "line",
        symbolSize: 10,
        data: diskIoRead.series[readNode],
      };
      diskChart.series.push(series);
    });

    diskIoWrite.legend.forEach((writeNode) => {
      diskChart.legend.push(`${writeNode}-Write`);
      const series = {
        name: `${writeNode}-Write`,
        type: "line",
        symbolSize: 10,
        data: diskIoWrite.series[writeNode],
      };
      diskChart.series.push(series);
    });

    const nodeInfo = {};

    Object.keys(clusterInfo.data?.nodeinfo ?? {}).forEach((nodeKey) => {
      const nodeItem = clusterInfo.data.nodeinfo[nodeKey];

      const diskChart = { unit: "", legend: [], series: [], xAxis: [] };
      const diskIoRead = nodeItem.diskIO.read;
      const diskIoWrite = nodeItem.diskIO.write;

      diskChart.unit = diskIoRead.unit;
      diskChart.xAxis = diskIoRead.xAxis.map((xtime) => {
        return dayjs.unix(xtime).format("HH:mm:ss");
      });

      diskChart.legend.push(`${nodeKey}-Read`);
      const readSeries = {
        name: `${nodeKey}-Read`,
        type: "line",
        symbolSize: 10,
        data: diskIoRead.series,
      };
      diskChart.series.push(readSeries);

      diskChart.legend.push(`${nodeKey}-Write`);
      const writeSeries = {
        name: `${nodeKey}-Write`,
        type: "line",
        symbolSize: 10,
        data: diskIoWrite.series,
      };
      diskChart.series.push(writeSeries);

      nodeInfo[nodeKey] = { ...nodeItem, diskIO: diskChart };
    });

    result = {
      clusterDetail: clusterDetail?.data,
      clusterInfo: { ...clusterInfo.data, vimStat: vimResp.data },
      diskIo: diskChart,
      nodeList: nodeResult,
      podList: podResult,
      nodeInfo: nodeInfo,
    };

    return result;
  } catch (error) {
    return null;
  }
};

export const getCurrentDate = () => {
  return dayjs(new Date()).format("YYYY.MM.DD(ddd) HH:mm");
};

export const getAppList = async (userNo) => {
  const userResp = await axios.get("/api/userns");
  const appResp = await axios.get(`/api/mecapp${userNo ? `/userno/${userNo}` : ""}`);

  if (userNo) {
    const result = {};
    let initCnt = 0,
      runCnt = 0,
      stopCnt = 0;

    userResp.data.forEach((nsRow) => {
      if (nsRow.mecUserNo === userNo) result["userNs"] = nsRow;
    });

    result["userNsData"] = [
      { label: "Namespace", value: result.userNs.userNsName },
      { label: "CPU", value: result.userNs.userNsCpu / 1000 },
      { label: "Memory", value: result.userNs.userNsMemory },
    ];

    appResp.data.forEach((appRow) => {
      userResp.data.forEach((userRow) => {
        if (userRow.userNsNo === appRow.mecUserNsNo) {
          appRow.mecUserNo = userRow.mecUserNo;
          appRow.userNsName = userRow.userNsName;
        }
      });

      if (appRow.mecAppStatus === "init") {
        appRow.appStatus = "init";
        initCnt++;
      } else if (appRow.mecAppStatus === "Running") {
        appRow.appStatus = "run";
        runCnt++;
      } else {
        appRow.appStatus = "stop";
        stopCnt++;
      }
    });

    result["appStat"] = [
      {
        label: "앱",
        value: appResp.data.length,
      },
      {
        label: "초기화",
        value: initCnt,
      },
      {
        label: "실행중",
        value: runCnt,
      },
      {
        label: "종료",
        value: stopCnt,
      },
    ];

    result["appData"] = appResp.data;

    return result;
  } else {
    let initCnt = 0,
      runCnt = 0,
      stopCnt = 0,
      userArr = [];

    appResp.data.forEach((appRow) => {
      userResp.data.forEach((userRow) => {
        if (userRow.userNsNo === appRow.mecUserNsNo) {
          appRow.mecUserNo = userRow.mecUserNo;
          appRow.userNsName = userRow.userNsName;
        }
      });

      if (!userArr.includes(appRow.mecUserNsNo)) {
        userArr.push(appRow.mecUserNsNo);
      }

      if (appRow.mecAppStatus === "init") {
        appRow.appStatus = "init";
        initCnt++;
      } else if (appRow.mecAppStatus === "Running") {
        appRow.appStatus = "run";
        runCnt++;
      } else {
        appRow.appStatus = "stop";
        stopCnt++;
      }
    });

    return {
      appStat: {
        user: userArr.length,
        app: appResp.data.length,
        init: initCnt,
        run: runCnt,
        stop: stopCnt,
      },
      appData: appResp.data,
    };
  }
};

export const getResourceType = async () => {
  const resourceTypeResp = await axios.get("/api/mecresourcetype");

  let podList = [],
    deploymentList = [],
    serviceList = [];
  resourceTypeResp.data.forEach((resourceType) => {
    switch (resourceType.resourceTypeName.toLowerCase()) {
      case "deploy":
      case "deployment":
      case "deployments":
        deploymentList.push(resourceType.resourceTypeNo);
        break;
      case "pod":
      case "pods":
        podList.push(resourceType.resourceTypeNo);
        break;
      case "service":
      case "services":
        serviceList.push(resourceType.resourceTypeNo);
        break;
      default:
        break;
    }
  });

  return {
    pod: podList,
    deployment: deploymentList,
    service: serviceList,
  };
};

export const getAppDetail = async (appNo) => {
  const detailResp = await axios.get(`/api/mecresource/mecapp/${appNo}`);
  return detailResp?.data;
};

export const serviceConfirm = async (serviceNo, isconfirm, serviceName, mecUserNo) => {
  let result = true;

  mecUserNo
    ? await axios.put(`/api/mecservice2/${serviceNo}/user/${mecUserNo}/state/${isconfirm}`).catch((error) => {
        MakeToast("fail", "confirm error: " + error.message || error.response.data.message);
        result = false;
      })
    : await axios
        .put(`/api/mecservice2`, {
          serviceNo: serviceNo,
          isconfirm: isconfirm,
          serviceName: serviceName,
        })
        .catch((error) => {
          MakeToast("fail", "confirm error: " + error.message || error.response.data.message);
          result = false;
        });

  return result;
};

export const getTop5Service = async () => {
  try {
    const top5Resp = await axios.get("/api/mecservice2/top5", {
      cancelToken: new CancelToken(function executor(c) {
        cancelList.push(c);
      }),
    });
    const rankService = top5Resp.data;

    rankService.sort((a, b) => {
      if (a.total_cnt < b.total_cnt) return 1;
      if (a.total_cnt > b.total_cnt) return -1;
      if (a.use_cnt < b.use_cnt) return 1;
      if (a.use_cnt > b.use_cnt) return -1;

      return 0;
    });

    for (let i = rankService.length; i < 5; i++) {
      rankService.push({ serviceName: "", total_cnt: 0, use_cnt: 0 });
    }

    return rankService;
  } catch (error) {
    return null;
  }
};

export const getServiceDetail = async (userNo, serviceNo) => {
  const detailResp = await axios.get(`/api/vim/listAllResources/${userNo}/serviceno/${serviceNo}`).catch((error) => {
    MakeToast("fail", "vim error: " + error.message || error.response.data.message);
    return { data: [] };
  });

  const ingressResp = await axios.get("/api/vim/ingressurl").catch((error) => {
    MakeToast("fail", "vim error: " + error.message || error.response.data.message);
    return { data: "" };
  });
  const ingressUrl = ingressResp.data;

  const podList = [],
    deployList = [],
    serviceList = [],
    ingressList = [];

  detailResp.data = detailResp?.data || {};
  detailResp.data.pod?.forEach((podData, idx) => {
    const podItem = {
      no: idx,
      name: podData.metadata.name,
      status: podData.status.phase,
      regDate: dayjs(podData.metadata.creationTimestamp).format("YYYY-MM-DD HH:mm:ss"),
    };

    podList.push(podItem);
  });

  detailResp.data.deployment?.forEach((data, idx) => {
    const item = {
      no: idx,
      name: data.metadata.name,
      namespace: data.metadata.namespace,
      regDate: dayjs(data.metadata.creationTimestamp).format("YYYY-MM-DD HH:mm:ss"),
    };

    deployList.push(item);
  });

  detailResp.data.service?.forEach((data, idx) => {
    const item = {
      no: idx,
      name: data.metadata.name,
      namespace: data.metadata.namespace,
      regDate: dayjs(data.metadata.creationTimestamp).format("YYYY-MM-DD HH:mm:ss"),
    };

    serviceList.push(item);
  });

  detailResp.data.ingress?.map((data, idx) => {
    const item = {
      no: idx,
      name: data.metadata.name,
      namespace: data.metadata.namespace,
      regDate: dayjs(data.metadata.creationTimestamp).format("YYYY-MM-DD HH:mm:ss"),
    };

    ingressList.push(item);

    const linkList = [];
    data.spec?.rules &&
      data.spec.rules.forEach((rule) => {
        rule.http.paths.forEach((path) => {
          let p = path.path;
          const idx = p.indexOf("(");

          if (idx > 0) {
            p = p.substring(0, idx);
          }

          p = p + "/";
          const linkHref = ingressUrl + "" + p;
          const linkName = path.backend.service.name;
          linkList.push({ linkHref: linkHref, linkName: linkName });
        });
      });
    data["linkList"] = linkList;

    return data;
  });

  detailResp.data["podList"] = podList;
  detailResp.data["deployList"] = deployList;
  detailResp.data["serviceList"] = serviceList;
  detailResp.data["ingressList"] = ingressList;

  return detailResp.data;
};

export const getClusterList = async () => {
  const clusterResp = await axios.get("/api/meccluster").catch((error) => {
    MakeToast("fail", "getClusterList error: " + error.message || error.response.data.message);
    return { data: [] };
  });
  return clusterResp.data;
};

export const getGrafanaUrl = async () => {
  const grafanaResp = await axios.get("/api/vim/grafanaurl").catch((error) => {
    MakeToast("fail", "vim error: " + error.message || error.response.data.message);
    return { data: "" };
  });
  return grafanaResp?.data;
};

export const getIngressUrl = async () => {
  const ingressResp = await axios.get("/api/vim/ingressurl").catch((error) => {
    MakeToast("fail", "vim error: " + error.message || error.response.data.message);
    return { data: "" };
  });
  return ingressResp?.data;
};

export const getOrchData = async () => {
  const orchResp = await axios.get("/api/mano/broker");

  const brokerLabelArr = [];
  const brokerCountArr = [];

  const userLabelArr = [];
  const userCountArr = [];

  const vimLabelArr = [];
  const vimCountArr = [];

  const methodLabelArr = [];
  const methodCountArr = [];

  const brokerData = [];
  const userData = [];
  const vimData = [];
  const methodData = [];

  orchResp.data.map((orch) => {
    const brokerIdx = brokerLabelArr.indexOf(orch.broker_address);
    const userIdx = userLabelArr.indexOf(orch.user_agent);
    const vimIdx = vimLabelArr.indexOf(orch.vim_address);
    const methodIdx = methodLabelArr.indexOf(orch.method);

    if (brokerIdx === -1) {
      brokerLabelArr.push(orch.broker_address);
      brokerCountArr.push(1);
    } else {
      brokerCountArr[brokerIdx]++;
    }

    if (userIdx === -1) {
      userLabelArr.push(orch.user_agent);
      userCountArr.push(1);
    } else {
      userCountArr[userIdx]++;
    }

    if (vimIdx === -1) {
      vimLabelArr.push(orch.vim_address);
      vimCountArr.push(1);
    } else {
      vimCountArr[vimIdx]++;
    }

    if (methodIdx === -1) {
      methodLabelArr.push(orch.method);
      methodCountArr.push(1);
    } else {
      methodCountArr[methodIdx]++;
    }

    orch.date = dayjs(orch.date).format("YYYY-MM-DD HH:mm:ss");

    return orch;
  });

  brokerLabelArr.forEach((label, idx) => {
    brokerData.push({ value: brokerCountArr[idx], name: label });
  });
  brokerData.sort((a, b) => b.value - a.value);

  userLabelArr.forEach((label, idx) => {
    userData.push({ value: userCountArr[idx], name: label });
  });
  userData.sort((a, b) => b.value - a.value);

  vimLabelArr.forEach((label, idx) => {
    vimData.push({ value: vimCountArr[idx], name: label });
  });
  vimData.sort((a, b) => b.value - a.value);

  methodLabelArr.forEach((label, idx) => {
    methodData.push({ value: methodCountArr[idx], name: label });
  });
  methodData.sort((a, b) => b.value - a.value);

  return {
    orchList: orchResp.data,
    brokerData: brokerData,
    userData: userData,
    vimData: vimData,
    methodData: methodData,
  };
};

export const getVimData = async () => {
  const vimResp = await axios.get("/api/mano/vim");

  const year = dayjs().startOf("year");
  const month = dayjs().startOf("month");
  const weekStart = dayjs().startOf("week");
  const weekEnd = dayjs().endOf("week");
  const date = dayjs().startOf("day");

  let yearLabelArr = [];
  let yearCountArr = [];
  let yearDataArr = [];

  let monthLabelArr = [];
  let monthCountArr = [];
  let monthDataArr = [];

  let weekLabelArr = [];
  let weekCountArr = [];
  let weekDataArr = [];

  let dayLabelArr = [];
  let dayCountArr = [];
  let dayDataArr = [];

  vimResp.data.forEach((vimRow) => {
    const yearIdx = yearLabelArr.indexOf(vimRow.function);
    const monthIdx = monthLabelArr.indexOf(vimRow.function);
    const weekIdx = weekLabelArr.indexOf(vimRow.function);
    const dayIdx = dayLabelArr.indexOf(vimRow.function);

    const vimDate = dayjs(vimRow.date);

    if (vimDate >= weekStart && vimDate <= weekEnd) {
      if (weekIdx === -1) {
        weekLabelArr.push(vimRow.function);
        weekCountArr.push(1);
      } else {
        weekCountArr[weekIdx]++;
      }
    }

    if (vimDate >= year) {
      if (yearIdx === -1) {
        yearLabelArr.push(vimRow.function);
        yearCountArr.push(1);
      } else {
        yearCountArr[yearIdx]++;
      }

      if (vimDate >= month) {
        if (monthIdx === -1) {
          monthLabelArr.push(vimRow.function);
          monthCountArr.push(1);
        } else {
          monthCountArr[monthIdx]++;
        }

        if (vimDate >= date) {
          if (dayIdx === -1) {
            dayLabelArr.push(vimRow.function);
            dayCountArr.push(1);
          } else {
            dayCountArr[dayIdx]++;
          }
        }
      }
    }
  });

  yearLabelArr.forEach((label, idx) => {
    yearDataArr.push({ value: yearCountArr[idx], name: label });
  });
  yearDataArr.sort((a, b) => {
    return b.value - a.value;
  });

  monthLabelArr.forEach((label, idx) => {
    monthDataArr.push({ value: monthCountArr[idx], name: label });
  });
  monthDataArr.sort((a, b) => {
    return b.value - a.value;
  });

  weekLabelArr.forEach((label, idx) => {
    weekDataArr.push({ value: weekCountArr[idx], name: label });
  });
  weekDataArr.sort((a, b) => {
    return b.value - a.value;
  });

  dayLabelArr.forEach((label, idx) => {
    dayDataArr.push({ value: monthCountArr[idx], name: label });
  });
  dayDataArr.sort((a, b) => {
    return b.value - a.value;
  });

  return {
    vimList: vimResp.data,
    vimYear: yearDataArr,
    vimMonth: monthDataArr,
    vimWeek: weekDataArr,
    vimDay: dayDataArr,
  };
};

export const getUserData = async () => {
  const userResp = await axios.get("/api/user");
  let confirmUser = 0,
    requestUser = 0,
    denyUser = 0;
  userResp.data.map((user) => {
    switch (user.isconfirm) {
      case 1:
        user.agreeStatus = "ok";
        user.agreeYn = "Y";
        confirmUser++;
        break;
      case 0:
        user.agreeStatus = "call";
        user.agreeYn = "N";
        requestUser++;
        break;
      default:
        user.agreeStatus = "denied";
        user.agreeYn = "Y";
        denyUser++;
        break;
    }

    return user;
  });

  return {
    userList: userResp.data,
    confirmUser: confirmUser,
    requestUser: requestUser,
    denyUser: denyUser,
    totalUser: confirmUser + requestUser + denyUser,
  };
};

export const userEdit = async (userData) => {
  let result = true;

  await axios.put("/api/user", { ...userData, userPasswd: "" }).catch((userErr) => {
    MakeToast("fail", userErr.response.data?.message || userErr.response.data);
    result = false;
  });

  return result;
};

export const getUserServiceData = async (userNo, userNsNo) => {
  const serviceResp = await axios.get("/api/mecservice2");
  const userInServiceResp = await axios.get("/api/mecservice2/user");
  const allService = serviceResp.data.filter((allSvc) => allSvc.isconfirm === 1);
  const myService = serviceResp.data.filter((service) => service.mecUserNsNo === userNsNo);
  const confirmService = userInServiceResp.data.filter(
    (serviceUser) =>
      serviceUser.mecUserNo === userNo && serviceUser.isconfirm === 1 && allService.filter((allSvc) => allSvc.serviceNo === serviceUser.mecServiceNo).length > 0
  );
  const rankService = myService.filter((service) => service.isconfirm === 1);
  rankService.map((rankSvc) => {
    const totalUser = userInServiceResp.data.filter((serviceUser) => serviceUser.mecServiceNo === rankSvc.serviceNo);
    rankSvc.total_cnt = totalUser.length;
    rankSvc.use_cnt = totalUser.filter((serviceUser) => serviceUser.isconfirm === 1).length;

    return rankSvc;
  });

  rankService.sort((a, b) => {
    if (a.total_cnt < b.total_cnt) return 1;
    if (a.total_cnt > b.total_cnt) return -1;
    if (a.use_cnt < b.use_cnt) return 1;
    if (a.use_cnt > b.use_cnt) return -1;

    return 0;
  });

  for (let i = rankService.length; i < 5; i++) {
    rankService.push({ serviceName: "", total_cnt: 0, use_cnt: 0 });
  }

  return {
    serviceStat: [
      {
        label: "전체 서비스",
        value: allService.length,
      },
      {
        label: "내 서비스",
        value: myService.length,
      },
      {
        label: "사용 서비스",
        value: confirmService.length,
      },
    ],
    allService: allService,
    myService: myService,
    confirmService: confirmService,
    rankService: rankService,
  };
};

export const controlApp = async (type, appNo) => {
  return type === "run" ? await axios.get(`/api/mecapp/startapp/${appNo}`) : await axios.delete(`/api/mecapp/stopapp/${appNo}`);
};

export const insertResource = async (data) => {
  let result = true;

  await axios.post("/api/mecresource", data).catch((error) => {
    MakeToast("fail", `insert error: ${error.message || error.response.data.message}`);
    result = false;
  });

  return result;
};

export const updateResource = async (data) => {
  let result = true;
  await axios.put("/api/mecresource", data).catch((error) => {
    result = false;
    MakeToast("fail", `update error: ${error.message || error.response.data.message}`);
  });

  return result;
};

export const updateResourceOrder = async (priorityList) => {
  let result = true;

  if (priorityList.length > 0) {
    const promises = priorityList.map(async (row) => {
      const data = { resourceNo: row.id, resourceOrder: row.indexNumber };
      return await axios.put("/api/mecresource/order", data).catch((error) => {
        MakeToast("fail", `update order error: ${error.message || error.response.data.message}`);
      });
    });
    await Promise.all(promises);

    return result;
  }

  return false;
};

export const deleteResource = async (resourceNo) => {
  return await axios.delete(`/api/mecresource/${resourceNo}`);
};

export const getUserNsNo = async (userNo) => {
  let userNsNo = 0;
  await axios.get("/api/userns").then((userNsResp) => {
    userNsNo = userNsResp.data.filter((userNs) => userNs.mecUserNo === userNo)[0].userNsNo;
  });

  return userNsNo;
};

export const addMyApp = async (data) => {
  let result = true;

  if (data.appName === "") {
    MakeToast("fail", "앱 이름을 입력해주세요.");
    return false;
  }

  await axios.post("/api/mecapp", data).catch((error) => {
    MakeToast("fail", "insert error: " + error.message || error.response.data.message);
    result = false;
  });

  return result;
};

export const controlService = async (type, data) => {
  let result = true;

  type === "run"
    ? await axios.get(`/api/mecservice2/startservice/${data.serviceNo}`).catch((error) => {
        MakeToast("fail", `service error: ${error.message || error.response.data.message}`);
        result = false;
      })
    : type === "stop"
    ? await axios.delete(`/api/mecservice2/stopservice/${data.serviceNo}`).catch((error) => {
        MakeToast("fail", `service error: ${error.message || error.response.data.message}`);
        result = false;
      })
    : await axios.put("/api/mecservice2", { isconfirm: 0, serviceNo: data.serviceNo, serviceName: data.serviceName }).catch((error) => {
        MakeToast("fail", `service error: ${error.message || error.response.data.message}`);
        result = false;
      });

  return result;
};

export const editService = async (data) => {
  let result = true;

  await axios.put("/api/mecservice2", data).catch((error) => {
    MakeToast("fail", `update error: ${error.message || error.response.data.message}`);
    result = false;
  });

  return result;
};

export const insertService = async (data) => {
  let result = true;

  if (data.serviceName === "") {
    MakeToast("fail", "서비스 이름을 입력해주세요.");
    return false;
  }

  await axios.post("/api/mecservice2", data).catch((error) => {
    MakeToast("fail", "insert error: " + error.message || error.response.data.message);
    result = false;
  });

  return result;
};

export const requestServiceUser = async (data, userNo) => {
  let result = true;

  data.isRequest
    ? await axios.post(`/api/mecservice2/${data.serviceNo}/user/${userNo}`).catch((error) => {
        MakeToast("fail", "update error: " + error.message || error.response.data.message);
        result = false;
      })
    : await axios.delete(`/api/mecservice2/${data.serviceNo}/user/${userNo}`).catch((error) => {
        MakeToast("fail", "cancel error: " + error.message || error.response.data.message);
        result = false;
      });

  return result;
};

export const getUserServiceDetail = async (serviceNo) => {
  const detailResp = await axios.get(`/api/mecresource/mecservice/${serviceNo}`);
  return detailResp?.data;
};

export const getUserDashboardChart = async (clusterNo, userId) => {
  //임시 clusterNo
  const clusterNoRes = await axios.get("/api/grafana/getClusterNos");
  const clusterNoTmp = clusterNoRes.data && clusterNoRes.data.length > 0 ? clusterNoRes.data[0] : 1;

  const chartResp = await axios.get(`/api/grafana/userClusterInformation/${clusterNoTmp}/${userId}`).catch((error) => {
    MakeToast("fail", "grafana error: " + error.message || error.response.data.message);
    return { data: false };
  });

  const chartResult = {
    cpu: { unit: "", legend: [], series: [], xAxis: [] },
    memory: { unit: "", legend: [], series: [], xAxis: [] },
    packet: { unit: "", legend: [], series: [], xAxis: [] },
    iops: { unit: "", legend: [], series: [], xAxis: [] },
  };

  if (chartResp.data) {
    const chartData = chartResp.data;

    if (!chartData) {
      MakeToast("fail", "grafana error");
    } else {
      //cpu
      chartResult.cpu.unit = chartData.cpu?.unit ?? "";
      chartResult.cpu.xAxis = chartData.cpu?.xAxis.map((xtime) => {
        return dayjs.unix(xtime).format("HH:mm:ss");
      });
      chartResult.cpu.legend.push(`${userId}-cpu`);
      chartResult.cpu?.series.push({ name: `${userId}-cpu`, type: "line", symbolSize: 10, data: chartData.cpu?.series });

      //memory
      chartResult.memory.unit = chartData.memory?.unit ?? "";
      chartResult.memory.xAxis = chartData.memory?.xAxis.map((xtime) => {
        return dayjs.unix(xtime).format("HH:mm:ss");
      });
      chartResult.memory.legend.push(`${userId}-memory`);
      chartResult.memory.series.push({ name: `${userId}-memory`, type: "line", symbolSize: 10, data: chartData.memory?.series });

      //packet
      chartResult.packet.unit = chartData.networkPacketsReceive?.unit;
      chartResult.packet.xAxis = chartData.networkPacketsReceive?.xAxis.map((xtime) => {
        return dayjs.unix(xtime).format("HH:mm:ss");
      });
      chartResult.packet.legend.push(`${userId}-receive`);
      chartResult.packet.series.push({ name: `${userId}-receive`, type: "line", symbolSize: 10, data: chartData.networkPacketsReceive?.series });
      chartResult.packet.legend.push(`${userId}-transmit`);
      chartResult.packet.series.push({ name: `${userId}-transmit`, type: "line", symbolSize: 10, data: chartData.networkPacketsTransmit?.series });

      //iops
      chartResult.iops.unit = chartData.iopsRead?.unit;
      chartResult.iops.xAxis = chartData.iopsRead?.xAxis.map((xtime) => {
        return dayjs.unix(xtime).format("HH:mm:ss");
      });
      chartResult.iops.legend.push(`${userId}-read`);
      chartResult.iops.series.push({ name: `${userId}-read`, type: "line", symbolSize: 10, data: chartData.iopsRead?.series });
      chartResult.iops.legend.push(`${userId}-write`);
      chartResult.iops.series.push({ name: `${userId}-write`, type: "line", symbolSize: 10, data: chartData.iopsWrite?.series });
    }
  }

  return chartResult;
};

export const getRunningAppDetail = async (userNo, appNo) => {
  const podResp = await axios.get(`/api/vim/listPod/${userNo}/${appNo}`).catch((error) => {
    MakeToast("fail", "vim error: " + error.message || error.response.data.message);
    return { data: [] };
  });
  const deployResp = await axios.get(`/api/vim/listDeployment/${userNo}/${appNo}`).catch((error) => {
    MakeToast("fail", "vim error: " + error.message || error.response.data.message);
    return { data: [] };
  });
  const serviceResp = await axios.get(`/api/vim/listService/${userNo}/${appNo}`).catch((error) => {
    MakeToast("fail", "vim error: " + error.message || error.response.data.message);
    return { data: [] };
  });
  const ingressListResp = await axios.get(`/api/vim/listIngress/${userNo}/${appNo}`).catch((error) => {
    MakeToast("fail", "vim error: " + error.message || error.response.data.message);
    return { data: [] };
  });

  const detailResp = {
    data: {
      pod: podResp.data,
      deployment: deployResp.data,
      service: serviceResp.data,
      ingress: ingressListResp.data,
    },
  };

  const ingressResp = await axios.get("/api/vim/ingressurl").catch((error) => {
    MakeToast("fail", "vim error: " + error.message || error.response.data.message);
    return { data: "" };
  });
  const ingressUrl = ingressResp.data;

  const podList = [],
    deployList = [],
    serviceList = [],
    ingressList = [];

  detailResp.data = detailResp?.data || {};
  detailResp.data.pod?.forEach((podData, idx) => {
    const podItem = {
      no: idx,
      name: podData.metadata.name,
      status: podData.status.phase,
      regDate: dayjs(podData.metadata.creationTimestamp).format("YYYY-MM-DD HH:mm:ss"),
    };

    podList.push(podItem);
  });

  detailResp.data.deployment?.forEach((data, idx) => {
    const item = {
      no: idx,
      name: data.metadata.name,
      namespace: data.metadata.namespace,
      regDate: dayjs(data.metadata.creationTimestamp).format("YYYY-MM-DD HH:mm:ss"),
    };

    deployList.push(item);
  });

  detailResp.data.service?.forEach((data, idx) => {
    const item = {
      no: idx,
      name: data.metadata.name,
      namespace: data.metadata.namespace,
      regDate: dayjs(data.metadata.creationTimestamp).format("YYYY-MM-DD HH:mm:ss"),
    };

    serviceList.push(item);
  });

  detailResp.data.ingress?.map((data, idx) => {
    const item = {
      no: idx,
      name: data.metadata.name,
      namespace: data.metadata.namespace,
      regDate: dayjs(data.metadata.creationTimestamp).format("YYYY-MM-DD HH:mm:ss"),
    };

    ingressList.push(item);

    const linkList = [];
    data.spec?.rules &&
      data.spec.rules.forEach((rule) => {
        rule.http.paths.forEach((path) => {
          let p = path.path;
          const idx = p.indexOf("(");

          if (idx > 0) {
            p = p.substring(0, idx);
          }

          p = p + "/";
          const linkHref = ingressUrl + "" + p;
          const linkName = path.backend.service.name;
          linkList.push({ linkHref: linkHref, linkName: linkName });
        });
      });
    data["linkList"] = linkList;

    return data;
  });

  detailResp.data["podList"] = podList;
  detailResp.data["deployList"] = deployList;
  detailResp.data["serviceList"] = serviceList;
  detailResp.data["ingressList"] = ingressList;

  return detailResp.data;
};

export const getDefaultServiceYaml = () => {
  return `apiVersion: v1
kind: Service
metadata:
  name: \${service_name}
spec:
  selector:
    app: \${deployment_label}
  ports:
    - port: \${port}`;
};

export const deleteCluster = async (clusterNo) => {
  let result = true;

  await axios.delete(`/api/meccluster/${clusterNo}`).catch((error) => {
    MakeToast("fail", "delete error: " + error.message || error.response.data.message);
    result = false;
  });

  return result;
};

export const insertCluster = async (data) => {
  let result = true;

  await axios.post("/api/meccluster", data).catch((error) => {
    MakeToast("fail", "insert error: " + error.message || error.response.data.message);
    result = false;
  });

  return result;
};

export const deleteApp = async (appNo) => {
  let result = true;

  await axios.delete(`/api/mecapp/${appNo}`).catch((error) => {
    MakeToast("fail", "delete error: " + error.message || error.response.data.message);
    result = false;
  });

  return result;
};

export const deleteService = async (serviceNo) => {
  let result = true;

  await axios.delete(`/api/mecservice2/${serviceNo}`).catch((error) => {
    MakeToast("fail", "delete error: " + error.message || error.response.data.message);
    result = false;
  });

  return result;
};

export const getCommaNumber = (num) => {
  if (num > 9999) {
    return "9,999+";
  } else if (num > 999) {
    return num.toLocaleString();
  } else {
    return num?.toString();
  }
};
export default getCommaNumber;

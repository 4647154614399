import { mdiAlertCircle, mdiCheckboxMarkedCircle } from "@mdi/js";
import Icon from "@mdi/react";
import ComponentHeader from "components/componentheader/ComponentHeader";
import CustomTable from "components/customtable/CustomTable";
import MgmtUserModal from "components/mgmtuser_modal/MgmtUserModal";
import { getCurrentDate, getUserData, getUserStatus, userEdit } from "data/CommonFunction";
import { refreshTimeAtom } from "data/Store";
import getCommaNumber from "hooks/GetCommaNumber";
import getTableHeader from "hooks/GetTableHeader";
import MakeToast from "hooks/MakeToast";
import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import "./MgmtUser.scss";

export const MgmtUser = () => {
  const headerItems = [
    {
      name: "번호",
      key: "userNo",
      width: "90px",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "아이디",
      key: "userId",
      center: "no",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "이름",
      key: "userName",
      center: "no",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "이메일",
      key: "userEmail",
      center: "no",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "연락처",
      key: "userPhone",
      center: "no",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "상태",
      key: "agreeStatus",
      width: "235px",
      type: "agreeStatus",
      action: (row) => clickedRow(row),
    },
    {
      name: "승인/거절",
      key: "agreeYn",
      width: "235px",
      type: "agreeYn",
      action: (type, row) => agreeHandler(type, row),
    },

    {
      name: "",
      key: "action",
      center: "no",
      width: "115px",
      type: "detail",
      action: (row) => showDetailHandler(row),
    },
  ];

  const clickedRow = (v) => {
    console.debug(v);
  };

  const [isEdit, setIsEdit] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const showDetailHandler = (row) => {
    setUserInfo(row);
    setIsEdit(row.agreeYn === "Y");
    setShowDetail(true);
  };

  const [showAgreeDialog, setShowAgreeDialog] = useState(false);
  const [agreeState, setAgreeState] = useState("");
  const agreeHandler = (type, row) => {
    setAgreeState(type);
    setShowAgreeDialog(row);
  };

  const [userData, setUserData] = useState();
  const [userStat, setUserStat] = useState();
  const setRefreshTime = useSetAtom(refreshTimeAtom);

  useEffect(() => {
    if (!showDetail && !showAgreeDialog) {
      getUserData().then((userResp) => {
        setUserData(userResp);
      });

      getUserStatus().then((userStatResp) => {
        setUserStat(userStatResp);
      });

      setRefreshTime(getCurrentDate());
    }
  }, [setRefreshTime, showAgreeDialog, showDetail]);

  const agreeAction = (userData, isconfirm) => {
    const confirmData = { ...userData, isconfirm: isconfirm };

    userEdit(confirmData).then((result) => {
      if (result) {
        setShowAgreeDialog(false);
        setShowDetail(false);
        MakeToast("success", `정상적으로 ${agreeState === "Y" ? "승인" : "거절"}이 완료되었습니다.`);
      }
    });
  };

  const getAgreeDialog = () => {
    return (
      <div className="modal-wrapper">
        <div className="modal-box">
          <div className="sm-dialog-header">
            {agreeState === "Y" ? (
              <div className="flexRow Y">
                <Icon path={mdiCheckboxMarkedCircle} size="36px" color="#2C9AFF" />
                <p>요청승인</p>
              </div>
            ) : (
              <div className="flexRow N">
                <Icon path={mdiAlertCircle} size="36px" color="#F06262" />
                <p>요청거절</p>
              </div>
            )}
          </div>

          <div className="sm-dialog-contents">
            <p>요청을 {agreeState === "Y" ? "승인" : "거절"}하시겠습니까?</p>
          </div>
          <div className="sm-dialog-btn-wrap">
            <button className="btn cancel-btn" onClick={() => setShowAgreeDialog(false)}>
              취소
            </button>
            <button className="btn submit-btn" onClick={() => agreeAction(showAgreeDialog, agreeState === "Y" ? 1 : 2)}>
              확인
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="page">
      {showAgreeDialog && getAgreeDialog()}
      {showDetail && <MgmtUserModal isEdit={isEdit} isShowHandler={setShowDetail} userInfo={userInfo} confirmAction={agreeAction} />}
      <div className="page-inner">
        <div className="mgmtsv-wrap flexCol">
          <ComponentHeader title="사용자 관리" />
          <div className="mgmtsv-mgmt-header box-info flexRow">
            <div className="left-wrap flexRow">
              <div className="info flexRow">
                <p className="title">전체 사용자</p>
                <p className="value">{getCommaNumber(userStat?.total)}</p>
              </div>
            </div>

            <div className="right-wrap flexRow">
              <div className="info flexRow">
                <p className="title">
                  <span style={{ background: "#2C9AFF" }} />
                  승인
                </p>
                <p className="value">{getCommaNumber(userStat?.approved)}</p>
              </div>
              <div className="info flexRow">
                <p className="title">
                  <span style={{ background: "#FFD43B" }} />
                  요청
                </p>
                <p className="value">{getCommaNumber(userStat?.requested)}</p>
              </div>
              <div className="info flexRow">
                <p className="title">
                  <span style={{ background: "#F06262" }} />
                  거절
                </p>
                <p className="value">{getCommaNumber(userStat?.rejected)}</p>
              </div>
            </div>
          </div>
          <div className="table-scroll-wrap">
            <CustomTable header={getTableHeader(headerItems)} rowData={userData?.userList} perPage={15} rowClickCallBack={clickedRow} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MgmtUser;

export const mecAppInfo = [
  {
    label: "앱 생성 사용자",
    icon: "icon-icon_myinfo",
    value: 0,
  },
  {
    label: "앱",
    icon: "icon-icon_app",
    value: 0,
  },
  {
    label: "초기화",
    icon: "icon-icon_refresh",
    value: 0,
  },
  {
    label: "실행",
    icon: "icon-icon_run",
    value: 0,
  },
  {
    label: "종료됨",
    icon: "icon-icon_stop",
    value: 0,
  },
];
export const clusterInfo = [
  {
    label: "오케스트레이터",
    value: 0,
  },
  {
    label: "사용자",
    value: 0,
  },
  {
    label: "VIM",
    value: 0,
  },
  {
    label: "Method",
    value: 0,
  },
];
export const mecServiceInfo = [
  {
    label: "전체 서비스",
    value: 0,
  },
  {
    label: "사용자 요청",
    value: 0,
  },
  {
    label: "승인",
    value: 0,
  },
];
export const top5MecService = [
  {
    label: "Location Service",
    value: 0,
    width: 0,
  },
  {
    label: "Bandwidth Service",
    value: 0,
    width: 0,
  },
  {
    label: "User Identity Service",
    value: 0,
    width: 0,
  },
  {
    label: "Radio Network Info service",
    value: 0,
    width: 0,
  },
  {
    label: "Device Application service",
    value: 0,
    width: 0,
  },
];
export const top3MecService = [
  {
    label: "Location Service",
    value: 0,
    width: 0,
  },
  {
    label: "Bandwidth Service",
    value: 0,
    width: 0,
  },
  {
    label: "User Identity Service",
    value: 0,
    width: 0,
  },
  {
    label: "Bandwidth Service",
    value: 0,
    width: 0,
  },
  {
    label: "User Identity Service",
    value: 0,
    width: 0,
  },
  {
    label: "Bandwidth Service",
    value: 0,
    width: 0,
  },
  {
    label: "User Identity Service",
    value: 0,
    width: 0,
  },
  {
    label: "Bandwidth Service",
    value: 0,
    width: 0,
  },
  {
    label: "User Identity Service",
    value: 0,
    width: 0,
  },
  {
    label: "Bandwidth Service",
    value: 0,
    width: 90,
  },
  {
    label: "User Identity Service",
    value: 0,
    width: 0,
  },
];
export const userStatus = [
  {
    label: "Approved",
    value: 0,
  },
  {
    label: "Request",
    value: 0,
  },
  {
    label: "Rejected",
    value: 0,
  },
];
export const orchestrationInfo = [
  {
    label: "Year",
    value: 0,
  },
  {
    label: "Month",
    value: 0,
  },
  {
    label: "Day",
    value: 0,
  },
];
export const chart1Option = {
  backgroundColor: "#17181a",
  title: {
    text: "Disk IO (Read / Write)",
    textStyle: {
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Pretendard",
      fontSize: "23px"
    },
  },
  tooltip: {
    trigger: "axis",
    appendToBody: true,
  },
  legend: {
    data: [],
    right: 0,
    show: false,
  },
  grid: {
    left: "3%",
    right: "3%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [],
  },
  yAxis: {
    type: "value",
    splitNumber: 2,
    axisLabel: {
      formatter: function (value, index) {
        return value === 0 ? `(mb/s)${value}` : value;
      },
    },
  },
  series: [],
};
export const chart2Option = {
  backgroundColor: "#17181a",
  title: {
    text: "Bandwidth(kb/s)",
    textStyle: {
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Pretendard",
    },
  },
  grid: {
    left: "0%",
    right: "0%",
    bottom: "0%",
    top: "25%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    data: ["Receive", "Transmit"],
  },
  yAxis: {
    type: "value",
    // min: 0,
    // max: 1000,
  },
  series: [
    {
      data: [
        {
          value: 0,
          itemStyle: {
            color: "#2B7BFF",
          },
        },
        {
          value: 0,
          itemStyle: {
            color: "#38D9A9",
          },
        },
      ],
      type: "bar",
      showBackground: true,
      backgroundStyle: {
        color: "#ADB5BD3D",
      },
    },
  ],
};
export const chart3Option = {
  backgroundColor: "#17181a",
  title: {
    text: "Packet(p/s)",
    textStyle: {
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Pretendard",
    },
  },
  grid: {
    left: "0%",
    right: "0%",
    bottom: "0%",
    top: "25%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    data: ["Receive", "Transmit"],
  },
  yAxis: {
    type: "value",
    // min: 0,
    // max: 1000,
  },
  series: [
    {
      data: [
        {
          value: 0,
          itemStyle: {
            color: "#2B7BFF",
            width: "20px",
          },
        },
        {
          value: 0,
          itemStyle: {
            color: "#38D9A9",
          },
        },
      ],
      type: "bar",
      showBackground: true,
      backgroundStyle: {
        color: "#ADB5BD3D",
      },
    },
  ],
};
export const chart4Option = {
  backgroundColor: "#17181a",
  title: {
    text: "Disk IO (Read / Write)",
    textStyle: {
      fontSize: "23px",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Pretendard",
    },
  },
  tooltip: {
    trigger: "axis",
    appendToBody: true,
  },
  legend: {
    data: [],
    right: 0,
    textStyle: {
      color: "#fff",
      fontWeight: 500,
    },
  },
  grid: {
    left: "3%",
    right: "3%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24],
  },
  yAxis: {
    type: "value",
    splitNumber: 2,
    min: 0,
    // max: 1000,
    axisLabel: {
      formatter: function (value, index) {
        return value === 0 ? `(mb/s)${value}` : value;
      },
    },
  },
  series: [],
};
export const chart5Option = {
  backgroundColor: "#17181a",
  title: {
    text: "Bandwidth / Packet(합산)",
    textStyle: {
      fontSize: "23px",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Pretendard",
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    appendToBody: true,
  },
  legend: {
    right: 0,
    textStyle: {
      color: "#fff",
      fontWeight: 500,
    },
  },
  grid: {
    left: "3%",
    right: "4%",
    top: "18%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    type: "value",
    boundaryGap: [0, 0.01],
  },
  yAxis: {
    type: "category",
    data: ["Bandwidth(kb/s)", "Packet(p/s)"],
  },
  series: [
    {
      name: "Receive",
      type: "bar",
      data: [0, 0],
    },
    {
      name: "Transmit",
      type: "bar",
      data: [0, 0],
    },
  ],
};
export const serverRacItems = [];

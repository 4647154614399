import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import "./MgmtUserModal.scss";
import axios from "axios";
import MakeToast from "hooks/MakeToast";
import { userEdit } from "data/CommonFunction";

export const MgmtUserModal = ({ isShowHandler, isEdit, userInfo, confirmAction }) => {
  const [userData, setUserData] = useState();

  useEffect(() => {
    setUserData(userInfo);
  }, [userInfo]);

  const postcode = useDaumPostcodePopup();

  const userDelete = (userNo) => {
    axios
      .delete(`/api/user/${userNo}`)
      .then((userResp) => {
        MakeToast("success", "사용자가 삭제되었습니다.");
        isShowHandler(false);
      })
      .catch((userErr) => {
        MakeToast("fail", userErr.response.data?.message || userErr.response.data);
      });
  };

  const editUser = () => {
    userEdit(userData).then((result) => {
      if (result) {
        isShowHandler(false);
        MakeToast("success", "사용자가 수정되었습니다.");
      }
    });
  };

  //input들에 error를 붙이면 빨간 에러표시가 남, 에러글자는 빨간색 고정
  return (
    <div className="modal-wrapper">
      <div className="modal-box">
        <div className="modal-header">
          <p className="title">GigaMEC 회원{isEdit ? " 가입 신청" : "정보"}</p>
          <IconButton onClick={() => isShowHandler(false)}>
            <Icon path={mdiClose} size="20px" color="#fff" />
          </IconButton>
        </div>
        <div className="modal-contents">
          <div className={`signup-contents flexCol ${isEdit ? "is-edit" : "no-edit"}`}>
            <div className="signup-grid flexRow">
              <div className="signup-input-wrap flexCol">
                <p className="label require">아이디</p>
                <input
                  type="text"
                  placeholder="입력하세요."
                  disabled={isEdit}
                  value={userData?.userId || ""}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, userId: e.target.value };
                    })
                  }
                />
                {/* <div className="input-msg">에러메시지</div> */}
              </div>
            </div>
            <div className="signup-grid flexRow">
              <div className="signup-input-wrap flexCol">
                <p className="label require">이름</p>
                <input
                  type="text"
                  placeholder="입력하세요."
                  value={userData?.userName || ""}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, userName: e.target.value };
                    })
                  }
                />
                {/* <div className="input-msg">에러메시지</div> */}
              </div>
              <div className="signup-input-wrap flexCol">
                <p className="label require">생년월일</p>
                <input
                  type="text"
                  placeholder="yyyy/MM/dd"
                  value={userData?.userBirth || ""}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, userBirth: e.target.value };
                    })
                  }
                />
                {/* <div className="input-msg">에러메시지</div> */}
              </div>
            </div>
            <div className="signup-grid flexRow">
              <div className="signup-input-wrap flexCol">
                <p className="label require">휴대폰</p>
                <input
                  type="text"
                  placeholder="입력하세요."
                  value={userData?.userPhone || ""}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, userPhone: e.target.value };
                    })
                  }
                />
                {/* <div className="input-msg">에러메시지</div> */}
              </div>
              <div className="signup-input-wrap flexCol">
                <p className="label require">이메일</p>
                <input
                  type="text"
                  placeholder="입력하세요."
                  value={userData?.userEmail || ""}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, userEmail: e.target.value };
                    })
                  }
                />
                {/* <div className="input-msg">에러메시지</div> */}
              </div>
            </div>
            <div className="signup-grid flexRow">
              <div className="signup-input-wrap flexCol">
                <p className="label require">주소</p>
                <input type="text" placeholder="우편번호" value={userData?.userPostcode || ""} readOnly />
              </div>
              <div className="post-btn-wrap flexCol">
                <button
                  onClick={() => {
                    isEdit &&
                      postcode({
                        onComplete: (data) => {
                          setUserData((prev) => {
                            return {
                              ...prev,
                              userPostcode: data.zonecode,
                              userAddress: data.userSelectedType === "R" ? data.roadAddress : data.jibunAddress,
                            };
                          });
                        },
                      });
                  }}
                >
                  우편번호 검색
                </button>
              </div>
            </div>
            <div className="alone-grid">
              <div className="signup-input-wrap flexCol">
                <input type="text" placeholder="주소" value={userData?.userAddress || ""} readOnly />
              </div>
            </div>
            <div className="alone-grid">
              <div className="signup-input-wrap flexCol">
                <input
                  type="text"
                  placeholder="상세주소"
                  value={userData?.userAddress2 || ""}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, userAddress2: e.target.value };
                    })
                  }
                />
              </div>
            </div>
            <div className="signup-grid flexRow">
              <div className="signup-input-wrap flexCol">
                <p className="label require">소속</p>
                <input
                  type="text"
                  placeholder="입력하세요."
                  value={userData?.userOrganization || ""}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, userOrganization: e.target.value };
                    })
                  }
                />
                {/* <div className="input-msg">에러메시지</div> */}
              </div>
              <div className="signup-input-wrap flexCol">
                <p className="label require">직책</p>
                <input
                  type="text"
                  placeholder="입력하세요."
                  value={userData?.userPosition || ""}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, userPosition: e.target.value };
                    })
                  }
                />
                {/* <div className="input-msg">에러메시지</div> */}
              </div>
            </div>
            <div className="signup-grid flexRow">
              <div className="signup-input-wrap flexCol">
                <p className="label require">직장 주소</p>
                <input type="text" placeholder="우편번호" value={userData?.userOrgPostcode || ""} readOnly />
              </div>
              <div className="post-btn-wrap flexCol">
                <button
                  onClick={() => {
                    isEdit &&
                      postcode({
                        onComplete: (data) => {
                          setUserData((prev) => {
                            return {
                              ...prev,
                              userOrgPostcode: data.zonecode,
                              userOrgAddress: data.userSelectedType === "R" ? data.roadAddress : data.jibunAddress,
                            };
                          });
                        },
                      });
                  }}
                >
                  우편번호 검색
                </button>
              </div>
            </div>
            <div className="alone-grid">
              <div className="signup-input-wrap flexCol">
                <input type="text" placeholder="주소" value={userData?.userOrgAddress || ""} readOnly />
              </div>
            </div>
            <div className="alone-grid">
              <div className="signup-input-wrap flexCol">
                <input
                  type="text"
                  placeholder="상세주소"
                  value={userData?.userOrgAddress2 || ""}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, userOrgAddress2: e.target.value };
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {isEdit ? (
          <div className="modal-btn-wrap update-user">
            <button className="btn red-btn-main" onClick={() => userDelete(userData?.userNo)}>
              삭제
            </button>
            <div className="flexRow">
              <button className="btn cancel-btn border-btn-main" onClick={() => isShowHandler(false)}>
                취소
              </button>
              <button className={`btn submit-btn ${false ? "error" : ""} blue-btn-main`} onClick={() => editUser()}>
                수정
              </button>
            </div>
          </div>
        ) : (
          <div className="modal-btn-wrap">
            <button className="btn red-btn-main" onClick={() => confirmAction(userData, 2)}>
              거절
            </button>
            <button className={`btn submit-btn ${false ? "error" : ""} blue-btn-main`} onClick={() => confirmAction(userData, 1)}>
              승인
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default MgmtUserModal;

import CustomTable from "components/customtable/CustomTable";
import { getOrchData } from "data/CommonFunction";
import { pieChartOption1 } from "data/OrchestrationMock";
import * as echarts from "echarts";
import getTableHeader from "hooks/GetTableHeader";
import { useEffect, useRef, useState } from "react";
import "./Orchestration.scss";

export const Orchestration = () => {
  const headerItems = [
    {
      name: "발생일시",
      key: "date",
      center: "no",
      width: "270px",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "브로커 주소",
      key: "broker_address",
      center: "no",
      width: "170px",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "사용자 에이전트",
      key: "user_agent",
      center: "no",
      width: "170px",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "VIM 주소",
      key: "vim_address",
      center: "no",
      width: "246px",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "API 이름",
      key: "request_uri",
      center: "no",
      width: "129px",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "API 타입",
      key: "method",
      center: "no",
      width: "122px",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "응답코드",
      key: "return_code",
      center: "no",
      width: "122px",
      type: "text",
      action: (row) => clickedRow(row),
    },
  ];
  const clickedRow = (v) => {
    console.debug(v);
  };

  const returnList = (top5List) => {
    return top5List?.slice(0, 5).map((item, index) => {
      return (
        <div className="list-item flexRow" key={`item-${index}`}>
          <p>
            <span>{index + 1}. </span>
            {item.name}
          </p>
          <p className="item-value">{item.value}</p>
        </div>
      );
    });
  };

  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);
  const chartRef4 = useRef(null);

  useEffect(() => {
    const chartInst = {};
    if (chartRef1.current) {
      const chart = echarts.init(chartRef1.current);
      chart.setOption(pieChartOption1);
      chartInst["broker"] = chart;
    }
    if (chartRef2.current) {
      const chart = echarts.init(chartRef2.current);
      chart.setOption(pieChartOption1);
      chartInst["user"] = chart;
    }
    if (chartRef3.current) {
      const chart = echarts.init(chartRef3.current);
      chart.setOption(pieChartOption1);
      chartInst["vim"] = chart;
    }
    if (chartRef4.current) {
      const chart = echarts.init(chartRef4.current);
      chart.setOption(pieChartOption1);
      chartInst["method"] = chart;
    }

    chartInst.broker && chartInst.user && chartInst.vim && chartInst.method && setChartInstance(chartInst);
  }, [chartRef1, chartRef2, chartRef3, chartRef4]);

  const [chartInstance, setChartInstance] = useState();
  const [orchData, setOrchData] = useState();

  useEffect(() => {
    chartInstance &&
      getOrchData().then((orchResp) => {
        setOrchData(orchResp);

        let brokerOption = chartInstance.broker.getOption();
        brokerOption.series[0].data = orchResp.brokerData;
        chartInstance.broker.setOption(brokerOption);

        let userOption = chartInstance.user.getOption();
        userOption.series[0].data = orchResp.userData;
        chartInstance.user.setOption(userOption);

        let vimOption = chartInstance.vim.getOption();
        vimOption.series[0].data = orchResp.vimData;
        chartInstance.vim.setOption(vimOption);

        let methodOption = chartInstance.method.getOption();
        methodOption.series[0].data = orchResp.methodData;
        chartInstance.method.setOption(methodOption);
      });
  }, [chartInstance]);

  return (
    // <div className="page">
    //   <div className="page-inner">
        <div className="orchestration-wrap flexCol">
          <div className="contents-wrap flexRow" style={{ overflowY: "auto", height: "100%" }}>
            <div className="left-sec box-info flexCol">
              <div className="pie-chart-wrap flexRow">
                <div className="pie-info-wrap flexCol">
                  <p className="info-title">Orchestration Event</p>
                  <div className="pie-info-list">{returnList(orchData?.brokerData)}</div>
                  {/*<div className="no-chart-wrap flexCol">
                    <Icon size="35px" path={mdiAlert} color="#868E96" />
                    <p>데이터가 존재 하지 않습니다.</p>
      </div>*/}
                </div>
                <div className="pie-chart" ref={chartRef1}></div>
              </div>
              <div className="pie-chart-wrap flexRow">
                <div className="pie-info-wrap flexCol">
                  <p className="info-title">User Agent Event</p>
                  <div className="pie-info-list">{returnList(orchData?.userData)}</div>
                  {/*<div className="no-chart-wrap flexCol">
                    <Icon size="35px" path={mdiAlert} color="#868E96" />
                    <p>데이터가 존재 하지 않습니다.</p>
    </div>*/}
                </div>
                <div className="pie-chart" ref={chartRef2}></div>
              </div>
              <div className="pie-chart-wrap flexRow">
                <div className="pie-info-wrap flexCol">
                  <p className="info-title">VIM Event</p>
                  <div className="pie-info-list">{returnList(orchData?.vimData)}</div>
                  {/* <div className="no-chart-wrap flexCol">
                    <Icon size="35px" path={mdiAlert} color="#868E96" />
                    <p>데이터가 존재 하지 않습니다.</p>
                  </div> */}
                </div>
                <div className="pie-chart" ref={chartRef3}></div>
              </div>
              <div className="pie-chart-wrap flexRow">
                <div className="pie-info-wrap flexCol">
                  <p className="info-title">API Type</p>
                  <div className="pie-info-list">{returnList(orchData?.methodData)}</div>
                  {/* <div className="no-chart-wrap flexCol">
                    <Icon size="35px" path={mdiAlert} color="#868E96" />
                    <p>데이터가 존재 하지 않습니다.</p>
                  </div> */}
                </div>
                <div className="pie-chart" ref={chartRef4}></div>
              </div>
            </div>
            <div className="table-scroll-wrap right-sec box-info">
              <CustomTable header={getTableHeader(headerItems)} rowData={orchData?.orchList} perPage={17} rowClickCallBack={clickedRow} />
            </div>
          </div>
        </div>
    //   </div>
    // </div>
  );
};
export default Orchestration;

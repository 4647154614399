export const colorPalette = [
  "#FF6B6B",
  "#F7839A",
  "#DA77F2",
  "#845EF7",
  "#5C7CFA",
  "#4DABF7",
  "#3BC9DB",
  "#38D9A9",
  "#69DB7C",
  "#A9E34B",
  "#FFD43B",
  "#FFA94D",
];
export default colorPalette;

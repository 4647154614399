import ComponentHeader from "components/componentheader/ComponentHeader";
import { Tab, Tabs } from "@mui/material";
import Orchestration from "pages/admin/orchestration/Orchestration";
import VIM from "pages/admin/vim/VIM";
import { useState } from "react";
import "./OrchestrationVIM.scss";

export const OrchestrationVIM = () => {
  const [tabValue, setTabValue] = useState(0);
  
  const handleTabValueChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  return (
    <div className="page">
      <div className="page-inner">
        <div className="orchestrationvim-wrap">
          <ComponentHeader title="오케스트레이션/VIM" />
          <div className="tab-box">
            <div className="tab-contents">
              <div className="tab-wrapper flexRow">
                <Tabs value={tabValue} onChange={handleTabValueChange}>
                  <Tab label="오케스트레이션" />
                  <Tab label="VIM" />
                </Tabs>
              </div>
              {tabValue === 0 && <Orchestration />}
              {tabValue === 1 && <VIM />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrchestrationVIM;
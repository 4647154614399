export const userData = [
  {
    label: "Namespace",
    value: "User",
  },
  {
    label: "CPU",
    value: 5,
  },
  {
    label: "Memory",
    value: 5000,
  },
];
export const userMyMecApp = [
  {
    label: "앱",
    value: 124,
  },
  {
    label: "파드",
    value: 32,
  },
  {
    label: "디플로이먼트",
    value: 5,
  },
  {
    label: "서비스",
    value: 5,
  },
];
export const userChart1Option = {
  backgroundColor: "#17181a",
  title: {
    text: "CPU Usage",
    textStyle: {
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Pretendard",
    },
  },
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: ["edge-worker02", "edge-worker03", "edge-worker04"],
    left: 0,
    bottom: 0,
    textStyle: {
      color: "#fff",
      fontWeight: 500,
    },
  },
  grid: {
    left: "3%",
    right: "3%",
    bottom: "12%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24],
  },
  yAxis: {
    type: "value",
    splitNumber: 2,
    axisLabel: {
      formatter: function (value, index) {
        return value === 0 ? `(mb/s)${value}` : value;
      },
    },
  },
  series: [
    {
      name: "edge-worker02",
      type: "line",
      symbol: "circle",
      symbolSize: 10,
      markLine: {
        symbol: ["none", "none"],
        label: { show: false },
        lineStyle: {
          color: "#2b3034",
        },
        data: [
          { xAxis: 1 },
          { xAxis: 3 },
          { xAxis: 5 },
          { xAxis: 7 },
          { xAxis: 9 },
        ],
      },
      lineStyle: {
        color: "#2B7BFF",
      },
      itemStyle: {
        borderWidth: 1,
        borderColor: "#2B7BFF",
        color: "#17181a",
      },

      data: [249, 300, 330, 380, 520, 440, 470, 490, 500, 1000, 300, 290],
    },
    {
      name: "edge-worker03",
      type: "line",
      symbolSize: 10,
      lineStyle: {
        color: "#38D9A9",
      },
      itemStyle: {
        borderWidth: 1,
        borderColor: "#38D9A9",
        color: "#17181a",
      },
      data: [280, 220, 420, 440, 390, 470, 530, 590, 470, 700, 500, 320],
    },
    {
      name: "edge-worker04",
      type: "line",
      symbolSize: 10,
      lineStyle: {
        color: "#FFD43B",
      },
      itemStyle: {
        borderWidth: 1,
        borderColor: "#FFD43B",
        color: "#17181a",
      },
      data: [180, 120, 320, 340, 290, 170, 730, 190, 370, 600, 400, 420],
    },
  ],
};
export const userChart2Option = {
  backgroundColor: "#17181a",
  title: {
    text: "Memory Usage (w/o cache)",
    textStyle: {
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Pretendard",
    },
  },
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: ["edge-worker02", "edge-worker03"],
    left: 0,
    bottom: 0,
    textStyle: {
      color: "#fff",
      fontWeight: 500,
    },
  },
  grid: {
    left: "3%",
    right: "3%",
    bottom: "12%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24],
  },
  yAxis: {
    type: "value",
    splitNumber: 2,
    axisLabel: {
      formatter: function (value, index) {
        return value === 0 ? `(mb/s)${value}` : value;
      },
    },
  },
  series: [
    {
      name: "edge-worker02",
      type: "line",
      symbol: "circle",
      symbolSize: 10,
      markLine: {
        symbol: ["none", "none"],
        label: { show: false },
        lineStyle: {
          color: "#2b3034",
        },
        data: [
          { xAxis: 1 },
          { xAxis: 3 },
          { xAxis: 5 },
          { xAxis: 7 },
          { xAxis: 9 },
        ],
      },
      lineStyle: {
        color: "#2B7BFF",
      },
      itemStyle: {
        borderWidth: 1,
        borderColor: "#2B7BFF",
        color: "#17181a",
      },

      data: [249, 300, 330, 380, 520, 440, 470, 490, 500, 1000, 300, 290],
    },
    {
      name: "edge-worker03",
      type: "line",
      symbolSize: 10,
      lineStyle: {
        color: "#38D9A9",
      },
      itemStyle: {
        borderWidth: 1,
        borderColor: "#38D9A9",
        color: "#17181a",
      },
      data: [280, 220, 420, 440, 390, 470, 530, 590, 470, 700, 500, 320],
    },
  ],
};
export const userChart3Option = {
  backgroundColor: "#17181a",
  title: {
    text: "Rate of Received Packets",
    textStyle: {
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Pretendard",
    },
  },
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: ["edge-worker02", "edge-worker03", "edge-worker04"],
    left: 0,
    bottom: 0,
    textStyle: {
      color: "#fff",
      fontWeight: 500,
    },
  },
  grid: {
    left: "3%",
    right: "3%",
    bottom: "12%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24],
  },
  yAxis: {
    type: "value",
    splitNumber: 2,
    axisLabel: {
      formatter: function (value, index) {
        return value === 0 ? `(mb/s)${value}` : value;
      },
    },
  },
  series: [
    {
      name: "edge-worker02",
      type: "line",
      symbol: "circle",
      symbolSize: 10,
      markLine: {
        symbol: ["none", "none"],
        label: { show: false },
        lineStyle: {
          color: "#2b3034",
        },
        data: [
          { xAxis: 1 },
          { xAxis: 3 },
          { xAxis: 5 },
          { xAxis: 7 },
          { xAxis: 9 },
        ],
      },
      lineStyle: {
        color: "#2B7BFF",
      },
      itemStyle: {
        borderWidth: 1,
        borderColor: "#2B7BFF",
        color: "#17181a",
      },

      data: [249, 300, 330, 380, 520, 440, 470, 490, 500, 1000, 300, 290],
    },
    {
      name: "edge-worker03",
      type: "line",
      symbolSize: 10,
      lineStyle: {
        color: "#38D9A9",
      },
      itemStyle: {
        borderWidth: 1,
        borderColor: "#38D9A9",
        color: "#17181a",
      },
      data: [280, 220, 420, 440, 390, 470, 530, 590, 470, 700, 500, 320],
    },
    {
      name: "edge-worker04",
      type: "line",
      symbolSize: 10,
      lineStyle: {
        color: "#FFD43B",
      },
      itemStyle: {
        borderWidth: 1,
        borderColor: "#FFD43B",
        color: "#17181a",
      },
      data: [180, 120, 320, 340, 290, 170, 730, 190, 370, 600, 400, 420],
    },
  ],
};
export const userChart4Option = {
  backgroundColor: "#17181a",
  title: {
    text: "IOPS (Reads + Writes)",
    textStyle: {
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Pretendard",
    },
  },
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: ["edge-worker02", "edge-worker03", "edge-worker04"],
    left: 0,
    bottom: 0,
    textStyle: {
      color: "#fff",
      fontWeight: 500,
    },
  },
  grid: {
    left: "3%",
    right: "3%",
    bottom: "12%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24],
  },
  yAxis: {
    type: "value",
    splitNumber: 2,
    axisLabel: {
      formatter: function (value, index) {
        return value === 0 ? `(mb/s)${value}` : value;
      },
    },
  },
  series: [
    {
      name: "edge-worker02",
      type: "line",
      symbol: "circle",
      symbolSize: 10,
      markLine: {
        symbol: ["none", "none"],
        label: { show: false },
        lineStyle: {
          color: "#2b3034",
        },
        data: [
          { xAxis: 1 },
          { xAxis: 3 },
          { xAxis: 5 },
          { xAxis: 7 },
          { xAxis: 9 },
        ],
      },
      lineStyle: {
        color: "#2B7BFF",
      },
      itemStyle: {
        borderWidth: 1,
        borderColor: "#2B7BFF",
        color: "#17181a",
      },

      data: [249, 300, 330, 380, 520, 440, 470, 490, 500, 1000, 300, 290],
    },
    {
      name: "edge-worker03",
      type: "line",
      symbolSize: 10,
      lineStyle: {
        color: "#38D9A9",
      },
      itemStyle: {
        borderWidth: 1,
        borderColor: "#38D9A9",
        color: "#17181a",
      },
      data: [280, 220, 420, 440, 390, 470, 530, 590, 470, 700, 500, 320],
    },
    {
      name: "edge-worker04",
      type: "line",
      symbolSize: 10,
      lineStyle: {
        color: "#FFD43B",
      },
      itemStyle: {
        borderWidth: 1,
        borderColor: "#FFD43B",
        color: "#17181a",
      },
      data: [180, 120, 320, 340, 290, 170, 730, 190, 370, 600, 400, 420],
    },
  ],
};

import "./Gauge.scss";

export const Gauge = ({ label, percent }) => {
  return (
    <div className="gauge-wrap">
      <p className="gauge-label">{label}</p>
      <div className="gauge-container">
        <div className="flexRow">
          <p>{`${percent}`}</p>
          <span>%</span>
        </div>
        <svg width="200" height="130" viewBox="0 0 200 130">
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#CB1657" />
              <stop offset="40%" stopColor="#7A54E7" />
              <stop offset="100%" stopColor="#10EDE0" />
            </linearGradient>
          </defs>
          <circle
            r="80"
            cx="100"
            cy="100"
            fill="transparent"
            stroke="#343A40"
            strokeLinecap="round"
            strokeWidth="24"
            strokeDasharray="calc(50 * calc(2*3.14*80) / 100)  calc(2*3.14*80)"
            transform="rotate(180) translate(-200, -200)"
          ></circle>
          <circle
            r="80"
            cx="100"
            cy="100"
            fill="transparent"
            stroke="url(#gradient)"
            strokeLinecap="round"
            strokeWidth="24"
            strokeDasharray={`calc((${percent} * calc(2*3.14*80) / 100) / 2)  calc(2*3.14*80)`}
            transform="rotate(180) translate(-200, -200)"
          ></circle>
        </svg>
      </div>
    </div>
  );
};
export default Gauge;

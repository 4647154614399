import { mdiAlertCircle, mdiCheckboxMarkedCircle, mdiClose, mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import { Box, IconButton, Tab, Tabs } from "@mui/material";
import CustomCodeMirror from "components/codemirror/CustomCodeMirror";
import ComponentHeader from "components/componentheader/ComponentHeader";
import CustomTable from "components/customtable/CustomTable";
import {
  controlService,
  deleteResource,
  deleteService,
  editService,
  getCurrentDate,
  getResourceType,
  getServiceData,
  getServiceDetail,
  getUserNsNo,
  getUserServiceDetail,
  insertResource,
  insertService,
  requestServiceUser,
  updateResource,
  updateResourceOrder,
} from "data/CommonFunction";
import { openModalAtom, refreshTimeAtom, userAuthAtom } from "data/Store";
import getCommaNumber from "hooks/GetCommaNumber";
import getTableHeader from "hooks/GetTableHeader";
import MakeToast from "hooks/MakeToast";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "./MyMecService.scss";

export const MyMecService = () => {
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [tabDetailData, setTabDetailData] = useState();
  const [serviceDetailData, setServiceDetailData] = useState();
  const [yamlText, setYamlText] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [showAgreeDialog, setShowAgreeDialog] = useState(false);
  const [runState, setRunState] = useState("");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showRequestDialog, setShowRequestDialog] = useState(false);
  const [resourceType, setResourceType] = useState({
    pod: [],
    deployment: [],
    service: [],
  });
  const [showAddService, setShowAddService] = useState(false);
  const [addDialogData, setAddDialogData] = useState();
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [getPriorityData, setPriorityData] = useState();
  const [showPriorityDialog, setShowPriorityDialog] = useState(false);
  const [showServiceInfo, setShowServiceInfo] = useState(false);
  const userAuthData = useAtomValue(userAuthAtom);
  const setRefreshTime = useSetAtom(refreshTimeAtom);
  const [serviceData, setServiceData] = useState();
  const [openModal, setOpenModal] = useAtom(openModalAtom);
  const [selectedResource, setSelectedResource] = useState(null);
  const [showResourceModal, setShowResourceModal] = useState(false);
  const [resourceDetailData, setResourceDetailData] = useState();
  const [resourceTabValue, setResourceTabValue] = useState(0);
  const [showIngressModal, setShowIngressModal] = useState(false);
  const [showDeleteServiceDialog, setShowDeleteServiceDialog] = useState();

  const headerItems = [
    {
      name: "번호",
      key: "serviceNo",
      width: "90px",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "서비스 이름",
      key: "serviceName",
      width: "600px",
      center: "no",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "파드",
      key: "servicePodNum",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "디플로이먼트",
      key: "serviceDeploymentNum",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "서비스",
      key: "serviceServiceNum",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "서비스 요청 상태",
      key: "userAgreeStatus",
      type: "userAgreeStatus",
      action: (row) => {
        getServiceDetail(userAuthData.userNo, row.serviceNo).then((detailResp) => {
          setResourceTabValue(0);
          setResourceDetailData(detailResp.pod[0]);
          setShowResourceModal(detailResp);
        });
      },
    },
    {
      name: "제어",
      key: "control",
      type: "control",
      action: (type, row) => agreeHandler(type, row),
    },
    {
      name: "상세",
      key: "action",
      center: "no",
      width: "115px",
      type: "detail",
      action: (row) => goDetail(row),
    },
    {
      name: "삭제",
      key: "delete",
      type: "delete",
      action: (action, row) => {
        if (row.isServiced) {
          MakeToast("fail", "실행 중인 서비스는 편집할 수 없습니다.");
        } else {
          setShowDeleteServiceDialog(row);
        }
      },
    },
  ];

  const bottomTableHeaderItems = [
    {
      name: "번호",
      key: "serviceNo",
      width: "90px",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "서비스 이름",
      key: "serviceName",
      width: "600px",
      center: "no",
      type: "textUnderLine",
      action: (row) => setShowServiceInfo(row),
    },
    {
      name: "개발자",
      key: "developer",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "현재 사용자",
      key: "nowUser",
      width: "200px",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "생성일시",
      key: "regdate",
      center: "no",
      width: "270px",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "서비스 요청 상태",
      key: "userAgreeStatus",
      type: "userAgreeStatus",
      action: (row) => {
        getServiceDetail(row.mecUserNo, row.serviceNo).then((detailResp) => {
          setShowIngressModal({ ...detailResp.ingress[0], serviceDescription: row.serviceDescription } ?? {});
        });
      },
    },
    {
      name: "",
      key: "action",
      center: "no",
      width: "115px",
      type: "request",
      action: (isRequest, row) => setShowRequestDialog({ ...row, isRequest: isRequest }),
    },
  ];

  const clickedRow = (v) => {
    console.debug(v);
  };

  const modalResourcePodTableHeaderItems = [
    {
      name: "이름",
      key: "name",
      width: "260px",
      center: "no",
      type: "text",
      action: (row) => clickedDetailRow(row, "pod"),
    },
    {
      name: "상태",
      key: "status",
      width: "178px",
      center: "no",
      type: "text",
      action: (row) => clickedDetailRow(row, "pod"),
    },
    {
      name: "생성일시",
      key: "regDate",
      width: "280px",
      center: "no",
      type: "text",
      action: (row) => clickedDetailRow(row, "pod"),
    },
  ];

  const modalResourceTableHeaderItems = (type) => {
    return [
      {
        name: "이름",
        key: "name",
        width: "260px",
        center: "no",
        type: "text",
        action: (row) => clickedDetailRow(row, type),
      },
      {
        name: "네임스페이스",
        key: "namespace",
        width: "178px",
        center: "no",
        type: "text",
        action: (row) => clickedDetailRow(row, type),
      },
      {
        name: "생성일시",
        key: "regDate",
        width: "280px",
        center: "no",
        type: "text",
        action: (row) => clickedDetailRow(row, type),
      },
    ];
  };

  const getDetailHeader = (type) => {
    const headerItem = type === "pod" ? modalResourcePodTableHeaderItems : modalResourceTableHeaderItems(type);

    return headerItem;
  };

  const clickedDetailRow = (row, type) => {
    switch (type) {
      case "deployment":
        setResourceDetailData(showResourceModal?.deployment[row.no]);
        break;
      case "service":
        setResourceDetailData(showResourceModal?.service[row.no]);
        break;
      case "ingress":
        setResourceDetailData(showResourceModal?.ingress[row.no]);
        break;
      default:
        setResourceDetailData(showResourceModal?.pod[row.no]);
        break;
    }
  };

  const handleResourceTabValueChange = (event, newValue) => {
    switch (newValue) {
      case 1:
        setResourceDetailData(showResourceModal?.deployment[0]);
        break;
      case 2:
        setResourceDetailData(showResourceModal?.service[0]);
        break;
      case 3:
        setResourceDetailData(showResourceModal?.ingress[0]);
        break;
      default:
        setResourceDetailData(showResourceModal?.pod[0]);
        break;
    }
    setResourceTabValue(newValue);
  };

  const goDetail = (v) => {
    setYamlText("");
    setSelectedResource(null);
    getUserServiceDetail(v.serviceNo).then((detailResp) => {
      setTabValue(0);
      setTabDetailData({ serviceNo: v.serviceNo, serviceDetail: detailResp });
      setShowDetailModal(v.isServiced ? "isServiced" : "isNotServiced");
      setServiceDetailData(v);
    });
  };

  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel" className="panel-wrapper flexRow" hidden={value !== index} {...other}>
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  const getItemStyle = (draggableStyle) => ({
    margin: "0 0 8px 0",
    ...draggableStyle,
  });

  const productListJSX = (item) => {
    return (
      <div className="priority-item flexRow">
        <p className="num">{item.indexNumber}</p>
        <p className="name box">{item.name}</p>
        <p className="key box">{item.key}</p>
        <em className="icon-icon_drag_handle" />
      </div>
    );
  };

  const agreeHandler = (type, row) => {
    setRunState(type);
    setShowAgreeDialog(row);
  };
  const agreeAction = () => {
    controlService(runState, showAgreeDialog).then((result) => {
      if (result) {
        MakeToast("success", `정상적으로 ${runState === "run" ? "실행" : runState === "stop" ? "정지" : "신청"}이(가) 완료되었습니다.`);
        initService();
        setShowAgreeDialog(false);
      }
    });
  };

  const getRequestDialog = () => {
    return (
      <div className="modal-wrapper">
        <div className="modal-box">
          <div className="sm-dialog-header">
            {showRequestDialog.isRequest ? (
              <div className="flexRow Y">
                <Icon path={mdiCheckboxMarkedCircle} size="36px" color="#2C9AFF" />
                <p>서비스 요청</p>
              </div>
            ) : (
              <div className="flexRow N">
                <Icon path={mdiAlertCircle} size="36px" color="#F06262" />
                <p>서비스 요청 취소</p>
              </div>
            )}
          </div>

          <div className="sm-dialog-contents">
            <p>해당 서비스를 {showRequestDialog.isRequest ? "요청" : "요청 취소"} 하시겠습니까?</p>
          </div>
          <div className="sm-dialog-btn-wrap">
            <button className="btn cancel-btn" onClick={() => setShowRequestDialog(false)}>
              취소
            </button>
            <button
              className="btn submit-btn"
              onClick={(result) => {
                if (result) {
                  requestServiceUser(showRequestDialog, userAuthData.userNo).then(() => {
                    MakeToast("success", `서비스 ${showRequestDialog.isRequest ? "요청" : "요청 취소"}에 성공하였습니다.`);
                    initService();
                    setShowRequestDialog(false);
                  });
                }
              }}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    );
  };

  const getAgreeDialog = () => {
    return (
      <div className="modal-wrapper">
        <div className="modal-box">
          <div className="sm-dialog-header">
            {runState === "stop" ? (
              <div className="flexRow N">
                <Icon path={mdiAlertCircle} size="36px" color="#F06262" />
                <p>서비스 정지</p>
              </div>
            ) : (
              <div className="flexRow Y">
                <Icon path={mdiCheckboxMarkedCircle} size="36px" color="#2C9AFF" />
                <p>서비스 {runState === "run" ? "실행" : "신청"}</p>
              </div>
            )}
          </div>

          <div className="sm-dialog-contents">
            <p>{runState === "run" ? "실행" : runState === "stop" ? "정지" : "신청"}하시겠습니까?</p>
          </div>
          <div className="sm-dialog-btn-wrap">
            <button className="btn cancel-btn" onClick={() => setShowAgreeDialog(false)}>
              취소
            </button>
            <button className="btn submit-btn" onClick={() => agreeAction()}>
              확인
            </button>
          </div>
        </div>
      </div>
    );
  };

  const getAddDialog = () => {
    const text = tabValue === 0 ? "파드 " : tabValue === 1 ? "디플로이먼트 " : "서비스 ";
    return (
      <div className="modal-wrapper" style={{ zIndex: 9998 }}>
        <div className="modal-box">
          <div className="modal-header">
            <p className="title">{text} 추가</p>
            <IconButton onClick={() => setShowAddDialog(false)}>
              <Icon path={mdiClose} size="20px" color="#fff" />
            </IconButton>
          </div>

          <div className="modal-contents">
            <div className="add-pod-wrap-wrap flexCol">
              <div className="input-log-wrap">
                <CustomCodeMirror
                  value={addDialogData?.resourceYaml}
                  isEdit
                  updateCallBack={(value) =>
                    setAddDialogData((prev) => {
                      return { ...prev, resourceYaml: value };
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="modal-btn-wrap" style={{ background: "inherit" }}>
            <button className="btn cancel-btn border-btn-main" onClick={() => setShowAddDialog(false)}>
              취소
            </button>
            <button
              className={`btn blue-btn-main`}
              onClick={() => {
                insertResource(addDialogData).then((result) => {
                  if (result) {
                    MakeToast("success", `${text} 저장에 성공하였습니다.`);
                    getUserServiceDetail(addDialogData.mecServiceNo).then((detailResp) => {
                      setTabDetailData({
                        serviceNo: addDialogData.mecServiceNo,
                        serviceDetail: detailResp,
                      });
                    });
                    initService();
                    setShowAddDialog(false);
                  }
                });
              }}
            >
              저장
            </button>
          </div>
        </div>
      </div>
    );
  };

  const getDeleteDialog = () => {
    const text = tabValue === 0 ? "파드" : tabValue === 1 ? "디플로이먼트" : "서비스";
    return (
      <div className="modal-wrapper" style={{ zIndex: 9998 }}>
        <div className="modal-box">
          <div className="sm-dialog-header">
            <div className="flexRow N">
              <Icon path={mdiAlertCircle} size="18px" color="#F06262" />
              <p>{text} 삭제</p>
            </div>
          </div>

          <div className="sm-dialog-contents">
            <p>해당 {text}를 삭제 하시겠습니까?</p>
          </div>
          <div className="sm-dialog-btn-wrap">
            <button className="btn cancel-btn" onClick={() => setShowDeleteDialog(false)}>
              취소
            </button>
            <button className="btn submit-btn" onClick={() => deleteAction()}>
              확인
            </button>
          </div>
        </div>
      </div>
    );
  };

  const getDeleteServiceDialog = () => {
    return (
      <div className="modal-wrapper">
        <div className="modal-box">
          <div className="sm-dialog-header">
            <div className="flexRow N">
              <Icon path={mdiAlertCircle} size="18px" color="#F06262" />
              <p>{showDeleteServiceDialog.serviceName} 삭제</p>
            </div>
          </div>

          <div className="sm-dialog-contents">
            <p>해당 서비스를 삭제 하시겠습니까?</p>
          </div>
          <div className="sm-dialog-btn-wrap">
            <button className="btn cancel-btn" onClick={() => setShowDeleteServiceDialog(false)}>
              취소
            </button>
            <button className="btn submit-btn" onClick={() => deleteServiceAction()}>
              확인
            </button>
          </div>
        </div>
      </div>
    );
  };

  const onDragEnd = useCallback((result, items) => {
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setPriorityData(
      items.map((priority, index) => ({
        ...priority,
        indexNumber: index + 1,
      }))
    );
  }, []);

  const initService = useCallback(() => {
    getResourceType().then((resourceTypeResp) => {
      setResourceType(resourceTypeResp);
    });

    getServiceData(userAuthData.userNo).then((serviceResp) => {
      setServiceData(serviceResp);
      setRefreshTime(getCurrentDate());
    });
  }, [setRefreshTime, userAuthData.userNo]);

  const getPriorityDialog = useMemo(() => {
    return (
      <div className="modal-wrapper" style={{ zIndex: 9998 }}>
        <div className="modal-box">
          <div className="modal-header">
            <p className="title">우선순위 설정</p>
            <IconButton onClick={() => setShowPriorityDialog(false)}>
              <Icon path={mdiClose} size="20px" color="#fff" />
            </IconButton>
          </div>
          <div className="modal-contents">
            <div className="drag-priority">
              <DragDropContext onDragEnd={(result) => onDragEnd(result, [...getPriorityData])}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {getPriorityData
                        .sort((a, b) => (a.indexNumber < b.indexNumber ? -1 : 1))
                        .map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(provided.draggableProps.style)}
                              >
                                {productListJSX(item, index)}
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
          <div className="modal-btn-wrap" style={{ background: "inherit" }}>
            <button className="btn cancel-btn border-btn-main" onClick={() => setShowPriorityDialog(false)}>
              취소
            </button>
            <button
              className={`btn blue-btn-main`}
              onClick={() =>
                updateResourceOrder(getPriorityData).then((result) => {
                  if (result) {
                    MakeToast("success", "우선순위 저장에 성공하였습니다.");
                    getUserServiceDetail(tabDetailData.serviceNo).then((detailResp) => {
                      setTabDetailData({
                        serviceNo: tabDetailData.serviceNo,
                        serviceDetail: detailResp,
                      });
                    });
                    initService();
                    setShowPriorityDialog(false);
                  }
                })
              }
            >
              저장
            </button>
          </div>
        </div>
      </div>
    );
  }, [getPriorityData, initService, onDragEnd, tabDetailData]);

  const deleteAction = () => {
    deleteResource(showDeleteDialog.resourceNo).then(() => {
      getUserServiceDetail(showDeleteDialog.mecServiceNo).then((detailResp) => {
        setTabDetailData({
          serviceNo: showDeleteDialog.mecServiceNo,
          serviceDetail: detailResp,
        });
      });
      initService();
      setShowDeleteDialog(false);
      MakeToast("success", `정상적으로 삭제가 완료되었습니다.`);
    });
  };

  const deleteServiceAction = () => {
    deleteService(showDeleteServiceDialog.serviceNo).then((result) => {
      if (result) {
        initService();
        setShowDeleteServiceDialog(false);
        MakeToast("success", `정상적으로 삭제가 완료되었습니다.`);
      }
    });
  };

  const addService = useCallback(() => {
    getUserNsNo(userAuthData.userNo).then((userNsNo) => {
      setShowAddService({
        mecUserNsNo: userNsNo,
        secret: "",
        serviceDaemonsetNum: 0,
        serviceDeploymentNum: 0,
        serviceDescription: "",
        serviceName: "",
        servicePodNum: 0,
        serviceRequestCpu: 0,
        serviceRequestStorage: 0,
        serviceRequestTraffic: 0,
        serviceServiceNum: 0,
      });
    });
  }, [userAuthData.userNo]);

  useEffect(() => {
    initService();

    if (openModal) {
      setOpenModal(false);
      addService();
    }
  }, [addService, initService, openModal, setOpenModal]);

  const getServiceEdit = useMemo(() => {
    return (
      <>
        <p className="condition-text">{serviceDetailData?.serviceName}</p>
        <div className="desc-sec-wrap flexRow">
          <textarea
            className="desc-sec2"
            value={serviceDetailData?.serviceDescription}
            onChange={(e) =>
              setServiceDetailData((prev) => {
                return { ...prev, serviceDescription: e.target.value };
              })
            }
          />
          <div
            className="edit-btn flexCol"
            onClick={() =>
              editService(serviceDetailData).then((result) => {
                if (result) {
                  initService();
                  setShowDetailModal(false);
                }
              })
            }
          >
            <Icon path={mdiPencil} size="18px" color="#ADB5BD" />
          </div>
        </div>
      </>
    );
  }, [initService, serviceDetailData]);

  useEffect(() => {
    if (tabDetailData?.serviceDetail.length > 0) {
      const typeArray = ["pod", "deployment", "service"];
      const resourceTmp =
        tabDetailData.serviceDetail.filter((row) => {
          return resourceType[typeArray[tabValue]].includes(row.resourceTypeNo);
        })[0] ?? null;
      setSelectedResource((prev) => {
        if (prev && prev.resourceTypeNo === resourceTmp?.resourceTypeNo) {
          return prev;
        } else {
          setYamlText(resourceTmp?.resourceYaml ?? "");
          return resourceTmp;
        }
      });
    }
  }, [resourceType, tabDetailData, tabValue]);

  const getDetailTabs = useCallback(
    (yamlText, setYamlText) => {
      const callDelete = (action, row) => {
        if (showDetailModal === "isServiced") {
          MakeToast("fail", "실행 중인 서비스는 편집할 수 없습니다.");
          return false;
        } else {
          setShowDeleteDialog(row);
        }
      };

      const modalTableHeaderItems = [
        {
          name: "번호",
          key: "resourceNo",
          width: "80px",
          type: "text",
          action: (row) => {
            setYamlText(row.resourceYaml ?? "");
            setSelectedResource(row.resourceYaml ? row : null);
          },
        },
        {
          name: "이름",
          key: "resourceName",
          width: "430px",
          center: "no",
          type: "text",
          action: (row) => {
            setYamlText(row.resourceYaml ?? "");
            setSelectedResource(row.resourceYaml ? row : null);
          },
        },
        {
          name: "우선순위",
          key: "resourceOrder",
          type: "text",
          width: "105px",
          action: (row) => {
            setYamlText(row.resourceYaml ?? "");
            setSelectedResource(row.resourceYaml ? row : null);
          },
        },
        {
          name: "삭제",
          key: "delete",
          type: "delete",
          action: (action, row) => callDelete(action, row),
        },
      ];

      const handleTabValueChange = (event, newValue) => {
        setTabValue(newValue);
      };

      return (
        <>
          <div className="tab-wrapper flexRow">
            <Tabs value={tabValue} onChange={handleTabValueChange}>
              <Tab label="파드" />
              <Tab label="디플로이먼트" />
              <Tab label="서비스" />
            </Tabs>
            <div className="modal-tab-btn-wrap flexRow">
              <button
                className="modal-tab-btn border-btn-main"
                onClick={() => {
                  if (showDetailModal === "isServiced") {
                    MakeToast("fail", "실행 중인 서비스는 편집할 수 없습니다.");
                  } else {
                    const resourceTypeNo = tabValue === 0 ? resourceType.pod[0] : tabValue === 1 ? resourceType.deployment[0] : resourceType.service[0];
                    const resourceOrder =
                      tabDetailData && tabDetailData.serviceDetail.length > 0 ? Math.max(...tabDetailData.serviceDetail.map((o) => o.resourceOrder)) + 1 : 1;

                    setAddDialogData({
                      mecAppNo: 0,
                      resourceName: "",
                      resourceTypeNo: resourceTypeNo,
                      resourceOrder: resourceOrder,
                      resourceYaml: "",
                      mecServiceNo: tabDetailData.serviceNo,
                    });
                    setShowAddDialog(true);
                  }
                }}
              >
                {tabValue === 0 ? "파드 " : tabValue === 1 ? "디플로이먼트 " : "서비스 "}
                추가
              </button>
              <button
                className="modal-tab-btn border-btn-main"
                onClick={() => {
                  if (showDetailModal === "isServiced") {
                    MakeToast("fail", "실행 중인 서비스는 편집할 수 없습니다.");
                  } else {
                    let priorityList = [];
                    tabDetailData?.serviceDetail.forEach((row) => {
                      const resourceTypeName = resourceType.pod.includes(row.resourceTypeNo)
                        ? "파드"
                        : resourceType.deployment.includes(row.resourceTypeNo)
                        ? "디플로이먼트"
                        : "서비스";
                      priorityList.push({
                        indexNumber: row.resourceOrder,
                        id: row.resourceNo,
                        name: resourceTypeName,
                        key: row.resourceName,
                      });
                    });
                    setPriorityData(priorityList);
                    setShowPriorityDialog(true);
                  }
                }}
              >
                우선순위 설정
              </button>
            </div>
          </div>
          <CustomTabPanel value={tabValue} index={0}>
            <div className="left-sec">
              <CustomTable
                header={getTableHeader(modalTableHeaderItems)}
                rowData={tabDetailData?.serviceDetail?.filter((row) => {
                  return resourceType.pod.includes(row.resourceTypeNo);
                })}
                perPage={7}
                rowClickCallBack={(row) => {
                  setYamlText(row.resourceYaml ?? "");
                  setSelectedResource(row.resourceYaml ? row : null);
                }}
              />
            </div>
            <div className="right-sec" style={{ height: "456px" }}>
              <div className="input-log-wrap">
                <CustomCodeMirror value={yamlText} isEdit={selectedResource} updateCallBack={(value) => setYamlText(value)} />
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <div className="left-sec">
              <CustomTable
                header={getTableHeader(modalTableHeaderItems)}
                rowData={tabDetailData?.serviceDetail?.filter((row) => {
                  return resourceType.deployment.includes(row.resourceTypeNo);
                })}
                perPage={7}
                rowClickCallBack={(row) => {
                  setYamlText(row.resourceYaml ?? "");
                  setSelectedResource(row.resourceYaml ? row : null);
                }}
              />
            </div>
            <div className="right-sec" style={{ height: "456px" }}>
              <div className="input-log-wrap">
                <CustomCodeMirror value={yamlText} isEdit={selectedResource} updateCallBack={(value) => setYamlText(value)} />
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={2}>
            <div className="left-sec">
              <CustomTable
                header={getTableHeader(modalTableHeaderItems)}
                rowData={tabDetailData?.serviceDetail?.filter((row) => {
                  return resourceType.service.includes(row.resourceTypeNo);
                })}
                perPage={7}
                rowClickCallBack={(row) => {
                  setYamlText(row.resourceYaml ?? "");
                  setSelectedResource(row.resourceYaml ? row : null);
                }}
              />
            </div>
            <div className="right-sec" style={{ height: "456px" }}>
              <div className="input-log-wrap">
                <CustomCodeMirror value={yamlText} isEdit={selectedResource} updateCallBack={(value) => setYamlText(value)} />
              </div>
            </div>
          </CustomTabPanel>
        </>
      );
    },
    [resourceType, selectedResource, showDetailModal, tabDetailData, tabValue]
  );

  return (
    <div className="page">
      {showIngressModal && (
        <div className="modal-wrapper">
          <div className="modal-box">
            <div className="modal-header">
              <p className="title">MEC 서비스 사용법</p>
              <IconButton onClick={() => setShowIngressModal(false)}>
                <Icon path={mdiClose} size="20px" color="#fff" />
              </IconButton>
            </div>
            <div className="modal-contents" style={{ minWidth: "984px" }}>
              <div className="signup-input-wrap flexCol">
                <div className="flexRow wd100">
                  <p className="condition-text" style={{ width: "20%" }}>
                    이름
                  </p>
                  <p className="label" style={{ fontSize: "26px" }}>
                    {showIngressModal.metadata?.labels.mecservice ?? ""}
                  </p>
                </div>
                <div className="flexRow wd100">
                  <p className="condition-text" style={{ width: "20%" }}>
                    서비스 링크
                  </p>
                  <div className="flexCol" style={{ alignItems: "flex-start" }}>
                    {showIngressModal.linkList.map((link) => (
                      <div className="label" style={{ fontSize: "26px" }}>
                        <a href={link.linkHref} target="_blank" rel="noreferrer">
                          {link.linkName}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flexRow wd100">
                  <p className="condition-text" style={{ width: "20%" }}>
                    사용 방법 안내
                  </p>
                  <div className="desc-sec-wrap flexRow" style={{ width: "80%" }}>
                    <textarea className="desc-sec2" value={showIngressModal.serviceDescription ?? ""} readOnly style={{ height: "400px" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showResourceModal && (
        <div className="modal-wrapper">
          <div className="modal-box">
            <div className="modal-header">
              <p className="title">앱 상태</p>
              <IconButton onClick={() => setShowResourceModal(false)}>
                <Icon path={mdiClose} size="20px" color="#fff" />
              </IconButton>
            </div>
            <div className="modal-contents">
              <div className="tab-wrapper flexRow">
                <Tabs value={resourceTabValue} onChange={handleResourceTabValueChange}>
                  <Tab label="파드" />
                  <Tab label="디플로이먼트" />
                  <Tab label="서비스" />
                  <Tab label="인그레스" />
                </Tabs>
              </div>

              <CustomTabPanel value={resourceTabValue} index={0}>
                <div className="left-sec2">
                  <CustomTable
                    header={getTableHeader(getDetailHeader("pod"))}
                    rowData={showResourceModal?.podList}
                    perPage={7}
                    rowClickCallBack={(row) => clickedDetailRow(row, "pod")}
                  />
                </div>
                {resourceTabValue === 0 && (
                  <div className="right-sec2">
                    <div className="data-table-viewer">
                      <table>
                        <tbody>
                          <tr>
                            <td className="table-key">
                              <p className="center">이름</p>
                            </td>
                            <td>
                              <p className="center">{resourceDetailData?.metadata.name}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>라벨</p>
                            </td>
                            <td>
                              <div className="black-box">
                                {resourceDetailData &&
                                  Object.entries(resourceDetailData.metadata.labels).map(([key, value]) => {
                                    return (
                                      <div className="black-box-line flexRow" key={"label_" + key}>
                                        <span className="key">{key}</span>
                                        <span className="value">{value ?? "null"}</span>
                                      </div>
                                    );
                                  })}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">노드</p>
                            </td>
                            <td>
                              <p className="center">{resourceDetailData?.spec.nodeName}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">호스트 IP</p>
                            </td>
                            <td>
                              <p className="center">{resourceDetailData?.status.hostIP}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>컨디션</p>
                            </td>
                            <td>
                              {resourceDetailData?.status?.conditions?.map((condition, idx) => {
                                return (
                                  <div className="black-box" key={"cond_" + idx}>
                                    {Object.entries(condition).map(([key, value]) => {
                                      return (
                                        <div className="black-box-line flexRow" key={"cond_" + idx + key}>
                                          <span className="key">{key}</span>
                                          <span className="value">{value ?? "null"}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={resourceTabValue} index={1}>
                <div className="left-sec2">
                  <CustomTable
                    header={getTableHeader(getDetailHeader("deployment"))}
                    rowData={showResourceModal?.deployList}
                    perPage={7}
                    rowClickCallBack={(row) => clickedDetailRow(row, "deployment")}
                  />
                </div>
                {resourceTabValue === 1 && (
                  <div className="right-sec2">
                    <div className="data-table-viewer">
                      <table>
                        <tbody>
                          <tr>
                            <td className="table-key">
                              <p className="center">이름</p>
                            </td>
                            <td>
                              <p className="center">{resourceDetailData?.metadata.name}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>라벨</p>
                            </td>
                            <td>
                              <div className="black-box">
                                {resourceDetailData &&
                                  Object.entries(resourceDetailData.metadata.labels).map(([key, value]) => {
                                    return (
                                      <div className="black-box-line flexRow" key={"label_" + key}>
                                        <span className="key">{key}</span>
                                        <span className="value">{value ?? "null"}</span>
                                      </div>
                                    );
                                  })}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">레플리카셋</p>
                            </td>
                            <td>
                              <p className="center">{resourceDetailData?.spec.replicas}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">상태</p>
                            </td>
                            <td>
                              <p className="center">
                                {"Ready Replicas: "}
                                {resourceDetailData?.status.readyReplicas}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>컨디션</p>
                            </td>
                            <td>
                              {resourceDetailData?.status?.conditions?.map((condition, idx) => {
                                return (
                                  <div className="black-box" key={"cond_" + idx}>
                                    {Object.entries(condition).map(([key, value]) => {
                                      return (
                                        <div className="black-box-line flexRow" key={"cond_" + idx + key}>
                                          <span className="key">{key}</span>
                                          <span className="value">{value ?? "null"}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={resourceTabValue} index={2}>
                <div className="left-sec2">
                  <CustomTable
                    header={getTableHeader(getDetailHeader("service"))}
                    rowData={showResourceModal?.serviceList}
                    perPage={7}
                    rowClickCallBack={(row) => clickedDetailRow(row, "service")}
                  />
                </div>
                {resourceTabValue === 2 && (
                  <div className="right-sec2">
                    <div className="data-table-viewer">
                      <table>
                        <tbody>
                          <tr>
                            <td className="table-key">
                              <p className="center">이름</p>
                            </td>
                            <td>
                              <p className="center">{resourceDetailData?.metadata.name}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">클러스터 IP</p>
                            </td>
                            <td>
                              <p className="center">{resourceDetailData?.spec.clusterIP}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>포트</p>
                            </td>
                            <td>
                              {resourceDetailData?.spec?.ports?.map((portRow, idx) => {
                                return (
                                  <div className="black-box" key={"port_" + idx}>
                                    {Object.entries(portRow).map(([key, value]) => {
                                      return (
                                        <div className="black-box-line flexRow" key={"cond_" + idx + key}>
                                          <span className="key">{key}</span>
                                          <span className="value">{value ?? "null"}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">서비스 타입</p>
                            </td>
                            <td>
                              <p className="center">{resourceDetailData?.spec.type}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={resourceTabValue} index={3}>
                <div className="left-sec2">
                  <CustomTable
                    header={getTableHeader(getDetailHeader("ingress"))}
                    rowData={showResourceModal?.ingressList}
                    perPage={7}
                    rowClickCallBack={(row) => clickedDetailRow(row, "ingress")}
                  />
                </div>
                {resourceTabValue === 3 && (
                  <div className="right-sec2">
                    <div className="data-table-viewer">
                      <table>
                        <tbody>
                          <tr>
                            <td className="table-key">
                              <p className="center">이름</p>
                            </td>
                            <td>
                              <p className="center">{resourceDetailData?.metadata.name}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>라벨</p>
                            </td>
                            <td>
                              <div className="black-box">
                                {resourceDetailData &&
                                  Object.entries(resourceDetailData.metadata.labels).map(([key, value]) => {
                                    return (
                                      <div className="black-box-line flexRow" key={"label_" + key}>
                                        <span className="key">{key}</span>
                                        <span className="value">{value ?? "null"}</span>
                                      </div>
                                    );
                                  })}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">클래스 이름</p>
                            </td>
                            <td>
                              <p className="center">{resourceDetailData?.spec.ingressClassName}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">링크</p>
                            </td>
                            <td>
                              {resourceDetailData &&
                                resourceDetailData.linkList.map((link) => {
                                  return (
                                    <p className="center" key={"link_" + link}>
                                      <a href={link.linkHref} target="_blank" rel="noreferrer">
                                        {link.linkName}
                                      </a>
                                    </p>
                                  );
                                })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </CustomTabPanel>
            </div>
          </div>
        </div>
      )}

      {showAddDialog && getAddDialog()}
      {showDeleteDialog && getDeleteDialog()}
      {showDeleteServiceDialog && getDeleteServiceDialog()}
      {showPriorityDialog && getPriorityDialog}
      {showServiceInfo && (
        <div className="modal-wrapper">
          <div className="modal-box">
            <div className="modal-header">
              <p className="title">서비스 정보</p>
              <IconButton onClick={() => setShowServiceInfo(false)}>
                <Icon path={mdiClose} size="20px" color="#fff" />
              </IconButton>
            </div>
            <div className="modal-contents" style={{ minWidth: "984px" }}>
              <div className="signup-input-wrap flexCol">
                <div className="flexRow wd100">
                  <p className="condition-text" style={{ width: "20%" }}>
                    이름
                  </p>
                  <p className="label" style={{ fontSize: "26px" }}>
                    {showServiceInfo.serviceName ?? ""}
                  </p>
                </div>
                <div className="flexRow wd100">
                  <p className="condition-text" style={{ width: "20%" }}>
                    사용 방법 안내
                  </p>
                  <div className="desc-sec-wrap flexRow" style={{ width: "80%" }}>
                    <textarea className="desc-sec2" value={showServiceInfo.serviceDescription ?? ""} readOnly style={{ height: "400px" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showAddService && (
        <div className="modal-wrapper">
          <div className="modal-box">
            <div className="modal-header">
              <p className="title">서비스 생성</p>
              <IconButton onClick={() => setShowAddService(false)}>
                <Icon path={mdiClose} size="20px" color="#fff" />
              </IconButton>
            </div>
            <div className="modal-contents">
              <div className="add-service-wrap">
                <div className="flexCol">
                  <p className="label require">서비스 이름</p>
                  <input
                    type="text"
                    placeholder="입력하세요."
                    value={showAddService.serviceName}
                    onChange={(e) =>
                      setShowAddService((prev) => {
                        return { ...prev, serviceName: e.target.value };
                      })
                    }
                  />
                </div>
                <div className="flexCol">
                  <p className="label require">서비스 설명</p>
                  <textarea
                    className="huge"
                    placeholder="입력하세요."
                    value={showAddService.serviceDescription}
                    onChange={(e) =>
                      setShowAddService((prev) => {
                        return { ...prev, serviceDescription: e.target.value };
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="modal-btn-wrap" style={{ background: "inherit" }}>
              <button className="btn cancel-btn border-btn-main" onClick={() => setShowAddService(false)}>
                취소
              </button>
              <button
                className={`btn ${false ? "error" : ""} border-btn-main`}
                onClick={() =>
                  insertService(showAddService).then((result) => {
                    if (result) {
                      MakeToast("success", "서비스 생성이 완료되었습니다.");
                      initService();
                      setShowAddService(false);
                    }
                  })
                }
              >
                생성
              </button>
            </div>
          </div>
        </div>
      )}
      {showRequestDialog && getRequestDialog()}
      {showAgreeDialog && getAgreeDialog()}
      {showDetailModal && (
        <div className="modal-wrapper">
          <div className="modal-box">
            <div className="modal-header">
              <p className="title">서비스 상세</p>
              <IconButton onClick={() => setShowDetailModal(false)}>
                <Icon path={mdiClose} size="20px" color="#fff" />
              </IconButton>
            </div>
            <div className="modal-contents">
              {getServiceEdit}
              {getDetailTabs(yamlText, setYamlText)}
            </div>
            <div className="modal-btn-wrap" style={{ background: "inherit" }}>
              <button className="btn cancel-btn border-btn-main" onClick={() => setShowDetailModal(false)}>
                취소
              </button>
              <button
                className={`btn blue-btn-main`}
                onClick={() => {
                  if (showDetailModal === "isServiced") {
                    MakeToast("fail", "실행 중인 서비스는 편집할 수 없습니다.");
                  } else {
                    selectedResource &&
                      updateResource({ ...selectedResource, resourceYaml: yamlText }).then((result) => {
                        if (result) {
                          MakeToast("success", "리소스 수정 성공하였습니다.");
                          const resourceTmp = { ...selectedResource };
                          initService();
                          getUserServiceDetail(resourceTmp.mecServiceNo).then((detailResp) => {
                            setTabDetailData({ serviceNo: resourceTmp.mecServiceNo, serviceDetail: detailResp });
                            const targetResource = detailResp.filter((row) => {
                              return row.resourceNo === resourceTmp.resourceNo;
                            })[0];
                            setYamlText(targetResource.resourceYaml);
                            setSelectedResource(targetResource);
                          });
                        }
                      });
                  }
                }}
              >
                수정
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="page-inner" style={{ overflowY: "auto", paddingRight: "2px" }}>
        <div className="mymec-service-wrap">
          <ComponentHeader title="내 MEC 서비스" addBtn={addService} />
          <div className="app-mgmt-header box-info flexRow" style={{ padding: "11px 24px" }}>
            <div className="left-wrap flexRow">
              <div className="info flexRow">
                <p className="title">내 서비스</p>
                <p className="value">{getCommaNumber(serviceData?.totalCnt)}</p>
              </div>
            </div>
            <div className="right-wrap flexRow">
              <div className="info flexRow">
                <p className="title">
                  <span style={{ background: "#15EA6A" }} />
                  실행중
                </p>
                <p className="value">{getCommaNumber(serviceData?.runCnt)}</p>
              </div>
              <div className="info flexRow">
                <p className="title">
                  <span style={{ background: "#ADB5BD" }} />
                  요청전
                </p>
                <p className="value">{getCommaNumber(serviceData?.nopendingCnt)}</p>
              </div>
              <div className="info flexRow">
                <p className="title">
                  <span style={{ background: "#FFD43B" }} />
                  승인대기
                </p>
                <p className="value">{getCommaNumber(serviceData?.waitCnt)}</p>
              </div>
              <div className="info flexRow">
                <p className="title">
                  <span style={{ background: "#2C9AFF" }} />
                  승인
                </p>
                <p className="value">{getCommaNumber(serviceData?.apprCnt)}</p>
              </div>
              <div className="info flexRow">
                <p className="title">
                  <span style={{ background: "#F06262" }} />
                  거절
                </p>
                <p className="value">{getCommaNumber(serviceData?.rejCnt)}</p>
              </div>
            </div>
          </div>
          <div className="table-scroll-wrap">
            <CustomTable header={getTableHeader(headerItems)} rowData={serviceData?.myService} perPage={6} rowClickCallBack={clickedRow} />
          </div>
          <ComponentHeader title="전체 MEC 서비스" isNoBack />
          <div className="table-scroll-wrap">
            <CustomTable header={getTableHeader(bottomTableHeaderItems)} rowData={serviceData?.allService} perPage={6} rowClickCallBack={clickedRow} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyMecService;

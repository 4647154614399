import "./MultiGauge.scss";

export const MultiGauge = ({ cpu, mem, disk }) => {
  const calculateRadius = (value, type) => {
    let radius = 0;
    switch (type) {
      case "cpu":
        radius = 100;
        break;
      case "mem":
        radius = 65;
        break;
      case "disk":
        radius = 30;
        break;
      default:
        break;
    }
    const circumference = 2 * Math.PI * radius;
    return (value * circumference) / 100;
  };

  return (
    <div className="multi-gauge-wrap flexRow">
      <div className="label-container flexCol">
        <div className="label-wrap flexCol">
          <div className="label flexRow">
            <p className="name name1">CPU</p>
            <p className="per">{cpu}%</p>
          </div>
          <div className="label flexRow">
            <p className="name name2">Memory</p>
            <p className="per">{mem}%</p>
          </div>
          <div className="label flexRow">
            <p className="name name3">Disk</p>
            <p className="per">{disk}%</p>
          </div>
        </div>
      </div>
      <div className="gauge-container">
        <svg width="300" height="300">
          <circle
            cx="150"
            cy="150"
            r="100"
            fill="none"
            stroke="#343A40"
            strokeWidth="19"
            strokeLinecap="round"
          />
          <circle
            cx="150"
            cy="150"
            r="65"
            fill="none"
            stroke="#343A40"
            strokeWidth="19"
            strokeLinecap="round"
          />
          <circle
            cx="150"
            cy="150"
            r="30"
            fill="none"
            stroke="#343A40"
            strokeWidth="19"
            strokeLinecap="round"
          />
          <circle
            cx="150"
            cy="150"
            r="100"
            fill="none"
            stroke="#69DB7C"
            strokeWidth="19"
            strokeLinecap="round"
            strokeDasharray={`${calculateRadius(cpu, "cpu")}, 628`}
            transform="rotate(180) translate(-300, -300)"
          />

          <circle
            cx="150"
            cy="150"
            r="65"
            fill="none"
            stroke="#FFD43B"
            strokeWidth="19"
            strokeLinecap="round"
            strokeDasharray={`${calculateRadius(mem, "mem")}, 408`}
            transform="rotate(180) translate(-300, -300)"
          />

          <circle
            cx="150"
            cy="150"
            r="30"
            fill="none"
            stroke="#3BC9DB"
            strokeWidth="19"
            strokeLinecap="round"
            strokeDasharray={`${calculateRadius(disk, "disk")}, 189`}
            transform="rotate(180) translate(-300, -300)"
          />
        </svg>
      </div>
    </div>
  );
};

export default MultiGauge;

import { getClusterList } from "data/CommonFunction";
import { LocalItems } from "data/MapData";
import { useEffect, useState } from "react";
import "./ClusterMap.scss";
import MakeToast from "hooks/MakeToast";
export const ClusterMap = ({ selectedLocal, localSelect, selectedClusterId, clusterSelect }) => {
  const [locationData, setLocationData] = useState([]);
  useEffect(() => {
    getClusterList().then((clusterResp) => {
      const locationData = LocalItems.map((item) => {
        item.cluster = clusterResp.filter((row) => row.clusterLocation.split(" ")[0] === item.label);

        if (item.cluster.includes(clusterResp[0])) {
          !selectedLocal && localSelect(item.class);
          !selectedClusterId && clusterSelect(clusterResp[0].clusterNo);
        }
        return item;
      });
      setLocationData(locationData);
    });
  }, [clusterSelect, localSelect, selectedClusterId, selectedLocal]);

  return (
    <div className="map-sec">
      {locationData.map((item, index) => {
        return (
          <div
            className={`local ${item.class} ${selectedLocal === item.class ? "active" : ""}`}
            key={`item-${index}`}
            onClick={() => {
              if (item.cluster.length > 0) {
                localSelect(item.class);
              } else {
                MakeToast("fail", "해당 지역에 클러스터가 없습니다");
              }
            }}
          >
            <p>
              {item.label}({item.cluster.length})
            </p>
            {selectedLocal === item.class && (
              <div className="cluster-items-wrap flexCol">
                {item.cluster.map((c, index) => {
                  return (
                    <div
                      className={`cluster-item flexRow ${selectedClusterId === c.clusterNo ? "active" : ""}`}
                      key={`item-${index}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        clusterSelect(c.clusterNo);
                      }}
                    >
                      <em className="icon-icon_cluster_stack" />
                      <p>{c.clusterName}</p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default ClusterMap;

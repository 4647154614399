import ComponentHeader from "components/componentheader/ComponentHeader";
import { getCurrentDate, getGrafanaUrl, useInterval } from "data/CommonFunction";
import { refreshTimeAtom, userAuthAtom } from "data/Store";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import "./Monitoring.scss";

export const MonitoringUser = () => {
  const [iframeSrc, setIframeSrc] = useState("");
  const setRefreshTime = useSetAtom(refreshTimeAtom);
  const userAuth = useAtomValue(userAuthAtom);

  useEffect(() => {
    getGrafanaUrl().then((grafanaUrl) => {
      setIframeSrc(
        `${grafanaUrl}/d/RPNZ5-hSk/kubernetes-gigamec_dashboard_namespaced?orgId=1&refresh=10s&var-datasource=prometheus&var-cluster=&var-namespace=${userAuth.userId}&kiosk`
      );

      setRefreshTime(getCurrentDate());
    });
  }, [setRefreshTime, userAuth.userId]);

  useInterval(() => {
    setRefreshTime(getCurrentDate());
  }, 10000);

  return (
    <div className="page">
      <div className="page-inner">
        <div className="monitoring-wrap flexCol">
          <ComponentHeader title="모니터링" />
          <iframe src={iframeSrc} className="iframe-wrap flexCol" title="grafanaFrame" />
        </div>
      </div>
    </div>
  );
};
export default MonitoringUser;

import toast from "react-hot-toast";
import Icon from "@mdi/react";
import { mdiCheckboxMarkedCircle, mdiAlert, mdiCloseCircle, mdiAlertCircle } from "@mdi/js";
export const MakeToast = (toastType, text) => {
  const obj = {
    success: {
      color: "#44B94B",
      title: "Success",
      icon: <Icon path={mdiCheckboxMarkedCircle} size="24px" color="rgba(255,255,255,0.8)" />,
    },
    warn: {
      color: "#FA8527",
      title: "Warning",
      icon: <Icon path={mdiAlert} size="24px" color="rgba(255,255,255,0.8)" />,
    },
    fail: {
      color: "#F06262",
      title: "Fail",
      icon: <Icon path={mdiCloseCircle} size="24px" color="rgba(255,255,255,0.8)" />,
    },
    normal: {
      color: "#2C9AFF",
      title: "Info",
      icon: <Icon path={mdiAlertCircle} size="24px" color="rgba(255,255,255,0.8)" />,
    },
  };
  toast.custom((t) => (
    <div className="toast-wrap flexRow status" style={{ background: obj[toastType].color }}>
      <div className="icon-wrap flexCol">{obj[toastType].icon}</div>
      <div className="text-wrap flexCol">
        <p className="title">{obj[toastType].title}</p>
        <p>{text}</p>
      </div>
    </div>
  ));
};
export default MakeToast;

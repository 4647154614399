import { atom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";
import { getCurrentDate } from "./CommonFunction";

export const refreshTimeAtom = atom(getCurrentDate());
// export const userAuthAtom = atom({ mecUserTypeNo: -1 }); // 유저 인증: 새로고침 시 증발
const storage = createJSONStorage(() => sessionStorage);
export const userAuthAtom = atomWithStorage("userAuth", sessionStorage.getItem("userAuth") || { mecUserTypeNo: -1 }, storage); // 유저 인증: 새로고침 후에도 유지

export const monitoringAtom = atom({});

export const openModalAtom = atom(false);

import { userAuthAtom } from "data/Store";
import { useAtomValue } from "jotai";
import MgmtService from "pages/admin/mgmtservice/MgmtService";
import MgmtUser from "pages/admin/mgmtuser/MgmtUser";
import Monitoring from "pages/admin/monitoring/Monitoring";
import OrchestrationVIM from "pages/admin/orchestrationvim/OrchestrationVIM";
import Login from "pages/login/Login";
import UserDashboard from "pages/user/dashboard/UserDashboard";
import MonitoringUser from "pages/user/monitoring/Monitoring";
import MyMecApp from "pages/user/myapp/MyMecApp";
import MyMecService from "pages/user/myservice/MyMecService";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import Header from "./components/layout/Header";
import "./components/layout/Modals.scss";
import Dashboard from "./pages/admin/dashboard/Dashboard";
import DashboardDetail from "./pages/admin/dashboard/detail/DashboardDetail";
import MgmtApp from "./pages/admin/mgmtapp/MgmtApp";
import MgmtCluster from "pages/admin/mgmtcluster/MgmtCluster";

function App() {
  const userAuth = useAtomValue(userAuthAtom);

  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Toaster position="top-center" reverseOrder={false} />
        {userAuth.mecUserTypeNo === -1 && (
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="*" element={<Navigate replace to="/login" />} />
          </Routes>
        )}
        {userAuth.mecUserTypeNo === 0 && (
          <Routes>
            <Route path="admin">
              <Route path="main" element={<Dashboard />} />
              <Route path="main/detail/:clusterNo" element={<DashboardDetail />} />
              <Route path="mgmtapp" element={<MgmtApp />} />
              <Route path="mgmtsv" element={<MgmtService />} />
              <Route path="monitoring" element={<Monitoring />} />
              <Route path="orchestrationvim" element={<OrchestrationVIM />} />
              <Route path="mgmtuser" element={<MgmtUser />} />
              <Route path="mgmtcluster" element={<MgmtCluster />} />
              <Route path="" element={<Navigate replace to="/admin/main" />} />
            </Route>
            <Route path="*" element={<Navigate replace to="/admin/main" />} />
          </Routes>
        )}
        {userAuth.mecUserTypeNo === 1 && (
          <Routes>
            <Route path="user">
              <Route path="main" element={<UserDashboard />} />
              <Route path="monitoring" element={<MonitoringUser />} />
              <Route path="myservice" element={<MyMecService />} />
              <Route path="mymec" element={<MyMecApp />} />
              <Route path="" element={<Navigate replace to="/user/main" />} />
            </Route>
            <Route path="*" element={<Navigate replace to="/user/main" />} />
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
}
export default App;

import ComponentHeader from "components/componentheader/ComponentHeader";
import { getCurrentDate, getGrafanaUrl, useInterval } from "data/CommonFunction";
import { monitoringAtom, refreshTimeAtom } from "data/Store";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import "./Monitoring.scss";

export const Monitoring = () => {
  const dataSource = useAtomValue(monitoringAtom);
  const [iframeSrc, setIframeSrc] = useState("");
  const setRefreshTime = useSetAtom(refreshTimeAtom);

  useEffect(() => {
    dataSource.dataSource &&
      getGrafanaUrl().then((grafanaUrl) => {
        setIframeSrc(`${grafanaUrl}/d/g0yYVPCVk/kubernetes-gigamec_dashboard?orgId=1&refresh=10s&var-datasource=${dataSource.dataSource}&kiosk`);

        setRefreshTime(getCurrentDate());
      });
  }, [dataSource.dataSource, setRefreshTime]);

  useInterval(() => {
    setRefreshTime(getCurrentDate());
  }, 10000);
  
  return (
    <div className="page">
      <div className="page-inner">
        <div className="monitoring-wrap flexCol">
          <ComponentHeader title="모니터링" isMonitoring />
          <iframe src={iframeSrc} className="iframe-wrap flexCol" title="grafanaFrame" />
        </div>
      </div>
    </div>
  );
};
export default Monitoring;

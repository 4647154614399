export const AppStatus = {
  run: {
    text: "실행중",
    color: "#15EA6A",
    bgColor: "#15EA6A14",
  },
  stop: {
    text: "종료",
    color: "#ADB5BD",
    bgColor: "#ADB5BD14",
  },
  init: {
    text: "초기화",
    color: "#FFD43B",
    bgColor: "#FFD43B14",
  },
};
export const NormalStatus = {
  run: {
    text: "Running",
    color: "#15EA6A",
    bgColor: "#15EA6A14",
  },
  stop: {
    text: "Stopped",
    color: "#ADB5BD",
    bgColor: "#ADB5BD14",
  },
  init: {
    text: "INIT",
    color: "#FFD43B",
    bgColor: "#FFD43B14",
  },
};
export const ServiceStatus = {
  standby: {
    text: "승인",
    color: "#2C9AFF",
    bgColor: "#2C9AFF14",
  },
  service: {
    text: "실행중",
    color: "#15EA6A",
    bgColor: "#15EA6A14",
  },
  beforeRequest: {
    text: "신청대기",
    color: "#ADB5BD",
    bgColor: "#ADB5BD14",
  },
  approval: {
    text: "승인요청",
    color: "#FFD43B",
    bgColor: "#FFD43B14",
  },
  deniedApproval: {
    text: "승인거절",
    color: "#F06262",
    bgColor: "#F0626214",
  },
};
export const agreeStatus = {
  call: {
    text: "승인요청",
    color: "#FFD43B",
    bgColor: "#FFD43B14",
  },
  ok: {
    text: "승인완료",
    color: "#2C9AFF",
    bgColor: "#2C9AFF14",
  },
  denied: {
    text: "거절",
    color: "#F06262",
    bgColor: "#F0626214",
  },
};

export const userAgreeStatus = {
  pending: {
    text: "승인대기",
    color: "#FFD43B",
    bgColor: "#FFD43B14",
  },
  approve: {
    text: "승인",
    color: "#2C9AFF",
    bgColor: "#2C9AFF14",
  },
  denied: {
    text: "거절",
    color: "#F06262",
    bgColor: "#F0626214",
  },
  nopending: {
    text: "요청전",
    color: "#ADB5BD",
    bgColor: "#ADB5BD14",
  },
  service: {
    text: "실행중",
    color: "#15EA6A",
    bgColor: "#15EA6A14",
  },
};

import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import CustomTable from "components/customtable/CustomTable";
import { getAppList, getCurrentDate, getTop5Service, getUserDashboardChart, getUserServiceData, useInterval } from "data/CommonFunction";
import { openModalAtom, refreshTimeAtom, userAuthAtom } from "data/Store";
import { userChart1Option, userChart2Option, userChart3Option, userChart4Option } from "data/UserDataMock";
import * as echarts from "echarts";
import getCommaNumber from "hooks/GetCommaNumber";
import getTableHeader from "hooks/GetTableHeader";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./UserDashboard.scss";

const UserDashboard = () => {
  const navigate = useNavigate();
  const headerItems = [
    {
      name: "이름",
      key: "appName",
      center: "no",
      width: "235px",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "파드",
      key: "appPodNum",
      width: "120px",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "디플로이먼트",
      key: "appDeploymentNum",
      width: "120px",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "서비스",
      key: "appServiceNum",
      width: "120px",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "상태",
      key: "appStatus",
      width: "120px",
      type: "appStatus",
      action: (row) => clickedRow(row),
    },
  ];
  const clickedRow = (v) => {
    console.debug(v);
  };

  const userAuthData = useAtomValue(userAuthAtom);
  const [chartInstance, setChartInstance] = useState();
  const setRefreshTime = useSetAtom(refreshTimeAtom);
  const [appState, setAppState] = useState();
  const [serviceState, setServiceState] = useState();
  const [top5MecService, setTop5MecService] = useState();
  const setOpenModal = useSetAtom(openModalAtom);

  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);
  const chartRef4 = useRef(null);

  useEffect(() => {
    const chartInst = {};

    if (chartRef1.current) {
      const chart = echarts.init(chartRef1.current, "dark");
      chart.setOption(userChart1Option);
      chartInst["cpu"] = chart;
    }
    if (chartRef2.current) {
      const chart = echarts.init(chartRef2.current, "dark");
      chart.setOption(userChart2Option);
      chartInst["memory"] = chart;
    }
    if (chartRef3.current) {
      const chart = echarts.init(chartRef3.current, "dark");
      chart.setOption(userChart3Option);
      chartInst["packet"] = chart;
    }
    if (chartRef4.current) {
      const chart = echarts.init(chartRef4.current, "dark");
      chart.setOption(userChart4Option);
      chartInst["iops"] = chart;
    }

    chartInst.cpu && chartInst.memory && chartInst.packet && chartInst.iops && setChartInstance(chartInst);
  }, [chartRef1, chartRef2, chartRef3, chartRef4]);

  const getDashboardData = useCallback(() => {
    if (chartInstance) {
      //todo: user clusterNo param
      getUserDashboardChart(1, userAuthData.userId).then((chartResp) => {
        Object.keys(chartResp).forEach((chartKey) => {
          const chartOption = chartInstance[chartKey].getOption();
          chartOption.legend[0].data = chartResp[chartKey].legend;
          chartOption.xAxis[0].data = chartResp[chartKey].xAxis;
          chartOption.series = chartResp[chartKey].series;
          chartOption.yAxis[0].axisLabel.formatter = (value, index) => {
            return value === 0 && chartResp[chartKey].unit !== "" ? `(${chartResp[chartKey].unit})${value}` : value;
          };

          chartInstance[chartKey].setOption(chartOption, true);
          chartInstance[chartKey].resize();
        });
      });
      getAppList(userAuthData.userNo).then((appResp) => {
        setAppState(appResp);
        getUserServiceData(userAuthData.userNo, appResp.userNs.userNsNo).then((svcResp) => {
          setServiceState(svcResp);
        });
      });
      getTop5Service().then((top5Stat) => {
        setTop5MecService(top5Stat);
      });
      setRefreshTime(getCurrentDate());
    }
  }, [chartInstance, setRefreshTime, userAuthData.userId, userAuthData.userNo]);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  useInterval(() => {
    getDashboardData();
  }, 10000);

  return (
    <div className="page" style={{ background: "#212529" }}>
      <div className="page-inner no-scroll">
        <div className="user-dash-wrap">
          <div className="user-left flexCol"  style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", minWidth: "40%", height: "100%" }}>
            <div className="my-allocation box-layout">
              <div className="box-header">
                <div className="title-wrap">
                  <em className="icon-icon_myinfo" />
                  <p>내 할당 정보</p>
                </div>
              </div>
              <div className="box-contents flexRow">
                {appState?.userNsData.map((item, index) => {
                  return (
                    <div className="user-my-info-item flexCol" key={`item-${index}`}>
                      <p className="label">{item.label}</p>
                      <p className="value">
                        {getCommaNumber(item.value)}&nbsp;
                        <span>{item.label === "CPU" && "Core"}</span>
                        <span>{item.label === "Memory" && "MB"}</span>
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="mec-app box-layout">
              <div className="box-header">
                <div className="title-wrap">
                  <em className="icon-icon_mgmtapp" />
                  <p>MEC 앱</p>
                </div>
                <div className="flexRow">
                  <Icon
                    className="add_dashboard_icon"
                    path={mdiPlus}
                    color={"#adb5bd"}
                    size={"32px"}
                    onClick={() => {
                      setOpenModal(true);
                      navigate(`/user/mymec`);
                    }}
                  />
                  <em className="icon-icon_open_pop" onClick={() => navigate(`/user/mymec`)} />
                </div>
              </div>
              <div className="box-contents flexCol">
                <div className="user-mec-app flexCol">
                  <div className="top flexRow">
                    {appState?.appStat.map((item, index) => {
                      return (
                        <div className="user-mec-item flexCol" key={`item-${index}`}>
                          <p className="label">{item.label}</p>
                          <p className="value">{getCommaNumber(item.value)}</p>
                        </div>
                      );
                    })}
                  </div>
                  <div className="bot">
                    <CustomTable header={getTableHeader(headerItems)} rowData={appState?.appData} perPage={11} rowClickCallBack={clickedRow} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", minWidth: "60%", height: "100%" }}>
            <div className="user-center flexCol" style={{ justifyContent: "flex-start" }}>
              <div className="mec-service box-layout">
                <div className="box-header">
                  <div className="title-wrap">
                    <em className="icon-icon_service" />
                    <p>MEC 서비스 정보</p>
                  </div>
                  <div className="flexRow">
                    <Icon
                      className="add_dashboard_icon"
                      path={mdiPlus}
                      color={"#adb5bd"}
                      size={"32px"}
                      onClick={() => {
                        setOpenModal(true);
                        navigate(`/user/myservice`);
                      }}
                    />
                    <em className="icon-icon_open_pop" onClick={() => navigate(`/user/myservice`)} />
                  </div>
                </div>
                <div className="box-contents flexRow">
                  <div className="service-info-wrap flexRow">
                    {serviceState?.serviceStat.map((item, index) => {
                      return (
                        <div className="mec-item-info" key={`item-${index}`}>
                          <p className="mec-item-label">{item.label}</p>
                          <p className="mec-item-value">{item.value}</p>
                        </div>
                      );
                    })}
                  </div>
                  <div className="line" />
                  <div className="service-wrap">
                    <div className="top5-service-wrap">
                      <p className="title">상위 5 MEC 서비스</p>
                      <div className="mec-bar-wrap flexCol">
                        {top5MecService?.map((item, index) => {
                          return (
                            <div className="mec-bar-item flexRow" key={`item-${index}`}>
                              <div className="bar flexRow">
                                <div
                                  className="fill"
                                  style={{
                                    width: `${(item.use_cnt / item.total_cnt) * 100}%`,
                                  }}
                                />
                                <p>{item.service_name}</p>
                              </div>
                              <p>{item.use_cnt}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="top5-service-wrap">
                      <p className="title">내 MEC 서비스</p>
                      <div className="mec-bar-wrap flexCol">
                        {serviceState?.rankService.map((item, index) => {
                          return (
                            <div className="mec-bar-item flexRow" key={`item-${index}`}>
                              <div className="bar flexRow">
                                <div
                                  className="fill-green"
                                  style={{
                                    width: `${(item.use_cnt / item.total_cnt) * 100}%`,
                                  }}
                                />
                                <p>{item.serviceName}</p>
                              </div>
                              <p>{item.use_cnt}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="user-right">
              <div className="monitoring box-layout">
                <div className="box-header">
                  <div className="title-wrap">
                    <em className="icon-icon_monitoring" />
                    <p>모니터링</p>
                  </div>
                  <em className="icon-icon_open_pop" onClick={() => navigate(`/user/monitoring`)} />
                </div>
                <div className="box-contents flexRow">
                  <div className="user-mon-wrap flexCol">
                    <div className="user-mon-chart" ref={chartRef1}></div>
                    <div className="user-mon-chart" ref={chartRef2}></div>
                  </div>
                  <div className="user-mon-wrap flexCol">
                    <div className="user-mon-chart" ref={chartRef3}></div>
                    <div className="user-mon-chart" ref={chartRef4}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;

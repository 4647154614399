import { mdiChevronLeft, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { IconButton } from "@mui/material";
import CustomSelect from "components/customselect/CustomSelect";
import { getClusterList } from "data/CommonFunction";
import { monitoringAtom } from "data/Store";
import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ComponentHeader.scss";

export const ComponentHeader = ({ title, subTitle, isVim, isMonitoring, isNoBack, addBtn }) => {
  const navigate = useNavigate();

  const [filterOneValue, setFilterOneValue] = useState("");
  const filterOneItems = [
    {
      label: "대전",
      value: "대전",
    },
    {
      label: "대구",
      value: "대구",
    },
    {
      label: "부산",
      value: "부산",
    },
  ];
  const filterOneHandler = (v) => {
    setFilterOneValue(v.value);
  };

  const [filterTwoValue, setFilterTwoValue] = useState();
  const [filterTwoItems, setFilterTwoItems] = useState([]);

  useEffect(() => {
    isMonitoring &&
      getClusterList().then((clusterResp) => {
        const itemList = [];
        clusterResp.forEach((cluster, idx) => {
          const clusterItem = {
            label: `${cluster.clusterName} (${cluster.clusterLocation.split(' ')[0]})`,
            value: `${cluster.clusterNo}|${cluster.clusterDatasource}`,
          };
          itemList.push(clusterItem);
        });
        setFilterTwoItems(itemList);
        itemList.length && setFilterTwoValue(itemList[0].value);
      });
  }, [isMonitoring]);

  const filterTwoHandler = (v) => {
    setFilterTwoValue(v.value);
  };

  const setMonitoring = useSetAtom(monitoringAtom);

  useEffect(() => {
    isMonitoring &&
      filterTwoValue?.split("|").length === 2 &&
      setMonitoring({
        clusterNo: filterTwoValue.split("|")[0],
        dataSource: filterTwoValue.split("|")[1],
      });
  }, [filterTwoValue, isMonitoring, setMonitoring]);

  return (
    <div className="detail-header flexRow">
      <div className="detail-info flexRow">
        {isNoBack ? (
          <div className="empty"></div>
        ) : (
          <IconButton onClick={() => navigate("/")}>
            <Icon path={mdiChevronLeft} size="40px" color="#fff" />
          </IconButton>
        )}
        <p className="detail-name">{title}</p>
        <div className="ver-line" />
        <p className="detail-sub-name">{subTitle}</p>
      </div>
      {false && isVim && (
        <div className="select-wrap flexRow">
          <div className="left-select-wrap">
            <CustomSelect value={filterOneValue} items={filterOneItems} selectCallBack={filterOneHandler} />
          </div>
          <div className="right-select-wrap">
            <CustomSelect value={filterTwoValue} items={filterTwoItems} selectCallBack={filterTwoHandler} />
          </div>
        </div>
      )}
      {isMonitoring && (
        <div className="select-wrap flexRow">
          <div className="right-select-wrap">
            <CustomSelect value={filterTwoValue} items={filterTwoItems} selectCallBack={filterTwoHandler} />
          </div>
        </div>
      )}
      {addBtn && (
        <button className="add-btn blue-btn-main" onClick={() => addBtn()}>
          <Icon size="22px" path={mdiPlus} color="#fff" />
          생성
        </button>
      )}
    </div>
  );
};
export default ComponentHeader;

import "./CustomSelect.scss";
import { mdiMenuDown, mdiMenuUp } from "@mdi/js";
import Icon from "@mdi/react";
import { useState, useEffect, useRef } from "react";
function CustomSelect({ value, items, selectCallBack }) {
  const [isClickedSelect, setIsClickedSelect] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  useEffect(() => {
    const currentValue = items.filter((item) => item.value === value);
    if (currentValue.length) {
      setSelectedValue(currentValue[0].label);
    }
  }, [items, value]);
  const selectClickRef = useRef(null);
  const handleDocumentClick = (e) => {
    if (selectClickRef.current && !selectClickRef.current.contains(e.target)) {
      setIsClickedSelect(false);
    }
  };
  const handleSelectClick = () => {
    setIsClickedSelect(!isClickedSelect);
  };
  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const selectItem = (item) => {
    selectCallBack(item);
    setTimeout(() => {
      setIsClickedSelect(false);
    }, 100);
  };
  return (
    <div
      className={`custom-select-wrap ${isClickedSelect ? "active" : ""}`}
      ref={selectClickRef}
      onClick={handleSelectClick}
    >
      {value ? (
        <div className="selected-value">{selectedValue}</div>
      ) : (
        <div className="selected-value placeholder">선택</div>
      )}
      <Icon
        path={isClickedSelect ? mdiMenuUp : mdiMenuDown}
        size="20px"
        color="#ADB5BD"
      />
      {isClickedSelect && (
        <div className="custom-select-option">
          {items.map((item, idx) => (
            <div
              className="custom-option"
              key={`item-${idx}`}
              onClick={() => selectItem(item)}
            >
              <p>{item.label}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
export default CustomSelect;

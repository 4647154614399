import CustomTable from "components/customtable/CustomTable";
import { getCurrentDate, getVimData } from "data/CommonFunction";
import { refreshTimeAtom } from "data/Store";
import { pieChartOption } from "data/VIMMock";
import * as echarts from "echarts";
import getTableHeader from "hooks/GetTableHeader";
import { useSetAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import "./VIM.scss";

export const VIM = () => {
  const headerItems = [
    {
      name: "발생일시",
      key: "date",
      center: "no",
      type: "text",
      width:'270px',
      action: (row) => clickedRow(row),
    },
    {
      name: "사용자 에이전트",
      key: "user",
      center: "no",
      type: "text",
      width: '300px',
      action: (row) => clickedRow(row),
    },
    {
      name: "API 이름",
      key: "function",
      center: "no",
      type: "text",
      width:'250px',
      action: (row) => clickedRow(row),
    },
    {
      name: "API 타입",
      key: "type",
      type: "text",
      center: "no",
      width:'200px',
      action: (row) => clickedRow(row),
    },
    {
      name: "응답코드",
      key: "status",
      type: "text",
      center: "no",
      width:'142px',
      action: (row) => clickedRow(row),
    },
  ];
  const clickedRow = (v) => {
    console.debug(v);
  };

  const returnList = (top5List) => {
    return top5List?.slice(0, 5).map((item, index) => {
      return (
        <div className="list-item flexRow" key={`item-${index}`}>
          <p>
            <span>{index + 1}. </span>
            {item.name}
          </p>
          <p className="item-value">{item.value}</p>
        </div>
      );
    });
  };

  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);
  const chartRef4 = useRef(null);

  useEffect(() => {
    const chartInst = {};
    if (chartRef1.current) {
      const chart = echarts.init(chartRef1.current);
      chart.setOption(pieChartOption);
      chartInst["year"] = chart;
    }
    if (chartRef2.current) {
      const chart = echarts.init(chartRef2.current);
      chart.setOption(pieChartOption);
      chartInst["month"] = chart;
    }
    if (chartRef3.current) {
      const chart = echarts.init(chartRef3.current);
      chart.setOption(pieChartOption);
      chartInst["week"] = chart;
    }
    if (chartRef4.current) {
      const chart = echarts.init(chartRef4.current);
      chart.setOption(pieChartOption);
      chartInst["day"] = chart;
    }

    chartInst.year && chartInst.month && chartInst.week && chartInst.day && setChartInstance(chartInst);
  }, [chartRef1, chartRef2, chartRef3, chartRef4]);

  const [chartInstance, setChartInstance] = useState();
  const [vimData, setVimData] = useState();
  const setRefreshTime = useSetAtom(refreshTimeAtom);

  useEffect(() => {
    chartInstance &&
      getVimData().then((vimResp) => {
        let yearOption = chartInstance.year.getOption();
        yearOption.series[0].data = vimResp.vimYear;
        chartInstance.year.setOption(yearOption);

        let monthOption = chartInstance.month.getOption();
        monthOption.series[0].data = vimResp.vimMonth;
        chartInstance.month.setOption(monthOption);

        let weekOption = chartInstance.week.getOption();
        weekOption.series[0].data = vimResp.vimWeek;
        chartInstance.week.setOption(weekOption);

        let dayOption = chartInstance.day.getOption();
        dayOption.series[0].data = vimResp.vimDay;
        chartInstance.day.setOption(dayOption);

        setVimData(vimResp);
        setRefreshTime(getCurrentDate());
      });
  }, [chartInstance, setRefreshTime]);

  return (
    // <div className="page">
    //   <div className="page-inner">
        <div className="vim-wrap flexCol">
          <div className="contents-wrap flexRow" style={{ overflowY: "auto", height: "100%" }}>
            <div className="left-sec box-info shadow flexCol">
              <div className="vim-chart-wrap flexRow">
                <div className="vim-info-wrap flexCol">
                  <p className="info-title">이번년도 VIM API</p>
                  <div className="vim-info-list">{returnList(vimData?.vimYear)}</div>
                </div>
                <div className="vim-info-chart" ref={chartRef1}></div>
              </div>
              <div className="vim-chart-wrap flexRow">
                <div className="vim-info-wrap flexCol">
                  <p className="info-title">이번달 VIM API</p>
                  <div className="vim-info-list">{returnList(vimData?.vimMonth)}</div>
                </div>
                <div className="vim-info-chart" ref={chartRef2}></div>
              </div>
              <div className="vim-chart-wrap flexRow">
                <div className="vim-info-wrap flexCol">
                  <p className="info-title">이번주 VIM API</p>
                  <div className="vim-info-list">{returnList(vimData?.vimWeek)}</div>
                </div>
                <div className="vim-info-chart" ref={chartRef3}></div>
              </div>
              <div className="vim-chart-wrap flexRow">
                <div className="vim-info-wrap flexCol">
                  <p className="info-title">금일 VIM API</p>
                  <div className="vim-info-list">{returnList(vimData?.vimDay)}</div>
                </div>
                <div className="vim-info-chart" ref={chartRef4}></div>
              </div>
            </div>
            <div className="right-sec box-info shadow">
              <CustomTable header={getTableHeader(headerItems)} rowData={vimData?.vimList} perPage={17} rowClickCallBack={clickedRow} />
            </div>
          </div>
        </div>
    //   </div>
    // </div>
  );
};
export default VIM;

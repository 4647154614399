export const LocalItems = [
  {
    label: "서울",
    class: "sl",
    cluster: [
      {
        label: "Cluster 01",
        id: 1,
      },
      {
        label: "Cluster 02",
        id: 2,
      },
    ],
  },
  {
    label: "경기",
    class: "gk",
    cluster: [
      {
        label: "Cluster 01",
        id: 3,
      },
      {
        label: "Cluster 02",
        id: 4,
      },
    ],
  },
  {
    label: "인천",
    class: "ic",
    cluster: [
      {
        label: "Cluster 01",
        id: 5,
      },
      {
        label: "Cluster 02",
        id: 6,
      },
    ],
  },
  {
    label: "강원",
    class: "gw",
    cluster: [
      {
        label: "Cluster 01",
        id: 7,
      },
      {
        label: "Cluster 02",
        id: 8,
      },
    ],
  },
  {
    label: "충북",
    class: "cb",
    cluster: [
      {
        label: "Cluster 01",
        id: 9,
      },
      {
        label: "Cluster 02",
        id: 10,
      },
    ],
  },
  {
    label: "세종",
    class: "sj",
    cluster: [
      {
        label: "Cluster 01",
        id: 11,
      },
      {
        label: "Cluster 02",
        id: 12,
      },
    ],
  },
  {
    label: "충남",
    class: "cn",
    cluster: [
      {
        label: "Cluster 01",
        id: 13,
      },
      {
        label: "Cluster 02",
        id: 14,
      },
    ],
  },
  {
    label: "대전",
    class: "dj",
    cluster: [
      {
        label: "Cluster 01",
        id: 15,
      },
      {
        label: "Cluster 02",
        id: 16,
      },
    ],
  },
  {
    label: "경북",
    class: "kb",
    cluster: [
      {
        label: "Cluster 01",
        id: 17,
      },
      {
        label: "Cluster 02",
        id: 18,
      },
    ],
  },
  {
    label: "대구",
    class: "dg",
    cluster: [
      {
        label: "Cluster 01",
        id: 19,
      },
      {
        label: "Cluster Test test 02",
        id: 20,
      },
    ],
  },
  {
    label: "전북",
    class: "jb",
    cluster: [
      {
        label: "Cluster 01",
        id: 21,
      },
      {
        label: "Cluster 02",
        id: 22,
      },
    ],
  },
  {
    label: "경남",
    class: "kn",
    cluster: [
      {
        label: "Cluster 01",
        id: 23,
      },
      {
        label: "Cluster 02",
        id: 24,
      },
    ],
  },
  {
    label: "울산",
    class: "us",
    cluster: [
      {
        label: "Cluster 01",
        id: 25,
      },
      {
        label: "Cluster 02",
        id: 26,
      },
    ],
  },
  {
    label: "부산",
    class: "bs",
    cluster: [
      {
        label: "Cluster 01",
        id: 27,
      },
      {
        label: "Cluster 02",
        id: 28,
      },
    ],
  },
  {
    label: "광주",
    class: "gj",
    cluster: [
      {
        label: "Cluster 01",
        id: 29,
      },
      {
        label: "Cluster 02",
        id: 30,
      },
    ],
  },
  {
    label: "전남",
    class: "jn",
    cluster: [
      {
        label: "Cluster 01",
        id: 31,
      },
      {
        label: "Cluster 02",
        id: 32,
      },
    ],
  },
  {
    label: "제주",
    class: "jj",
    cluster: [
      {
        label: "Cluster 01",
        id: 33,
      },
      {
        label: "Cluster 02",
        id: 34,
      },
    ],
  },
];

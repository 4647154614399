import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import Icon from "@mdi/react";
import { IconButton } from "@mui/material";
import ComponentHeader from "components/componentheader/ComponentHeader";
import CustomTable from "components/customtable/CustomTable";
import Gauge from "components/gauge/Gauge";
import MultiGauge from "components/multigauge/MultiGauge";
import { getClusterDetail, getCurrentDate, useInterval } from "data/CommonFunction";
import { chart1Option, chart4Option, chart5Option, clusterInfo } from "data/DashboardMock";
import { refreshTimeAtom } from "data/Store";
import * as echarts from "echarts";
import getTableHeader from "hooks/GetTableHeader";
import { useSetAtom } from "jotai";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "./DashboardDetail.scss";

const DashboardDetail = () => {
  const [clusterDetail, setClusterDetail] = useState({
    clusterDetail: {},
    clusterInfo: {},
  });
  const [clusterStat, setClusterStat] = useState(clusterInfo);
  const setRefreshTime = useSetAtom(refreshTimeAtom);
  const [nodeDetail, setNodeDetail] = useState();
  const [showRacNum, setShowRacNum] = useState(0);
  const [selectedRac, setSelectedRac] = useState();
  const [showRacNumArr, setShowRacNumArr] = useState([]);
  const [originItems, setOriginItems] = useState([]);
  const [chartInstance, setChartInstance] = useState();
  const [podList, setPodList] = useState([]);

  const clusterNo = useParams().clusterNo;
  const stackRef = useRef(null);
  //차트만들기
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);
  const chartRef4 = useRef(null);
  const chartRef5 = useRef(null);

  useEffect(() => {
    let chartInst = {};

    if (chartRef1.current) {
      const chart = echarts.init(chartRef1.current, "dark");
      chart.setOption(chart1Option);
      chartInst["diskio"] = chart;
    }
    // if (chartRef2.current) {
    //   const chart = echarts.init(chartRef2.current, "dark");
    //   chart.setOption(chart2Option);
    //   chartInst["bandwidth"] = chart;
    // }
    // if (chartRef3.current) {
    //   const chart = echarts.init(chartRef3.current, "dark");
    //   chart.setOption(chart3Option);
    //   chartInst["packet"] = chart;
    // }
    if (chartRef2.current) {
      const chart = echarts.init(chartRef2.current, "dark");
      chart.setOption(chart5Option);
      chartInst["bandpacket"] = chart;
    }
    if (chartRef4.current) {
      const chart = echarts.init(chartRef4.current, "dark");
      chart.setOption(chart4Option);
      chartInst["nodediskio"] = chart;
    }
    if (chartRef5.current) {
      const chart = echarts.init(chartRef5.current, "dark");
      chart.setOption(chart5Option);
      chartInst["nodebandpacket"] = chart;
    }

    // chartInst.diskio && chartInst.bandwidth && chartInst.packet && chartInst.nodediskio && chartInst.nodebandpacket && setChartInstance(chartInst);
    chartInst.diskio && chartInst.bandpacket && chartInst.nodediskio && chartInst.nodebandpacket && setChartInstance(chartInst);
  }, [chartRef1, chartRef2, chartRef3, chartRef4, chartRef5]);

  //rac 아이템

  useEffect(() => {
    setShowRacNumArr(originItems.filter((item) => item.itemIndex <= showRacNum + 1).map((item) => item.itemIndex));
  }, [originItems, showRacNum]);

  useEffect(() => {
    if (chartInstance && nodeDetail) {
      let nodebandpacketOption = chartInstance.nodebandpacket.getOption();

      nodebandpacketOption.series[0].data[0] = nodeDetail.receiveBadwidth;
      nodebandpacketOption.series[1].data[0] = nodeDetail.transmitBadwidth;
      nodebandpacketOption.series[0].data[1] = nodeDetail.receivePackets;
      nodebandpacketOption.series[1].data[1] = nodeDetail.transmitPackets;

      chartInstance.nodebandpacket.setOption(nodebandpacketOption);
      chartInstance.nodebandpacket.resize();

      let nodediskioOption = chartInstance.nodediskio.getOption();

      nodediskioOption.legend[0].data = nodeDetail.diskIO.legend;
      nodediskioOption.xAxis[0].data = nodeDetail.diskIO.xAxis;
      nodediskioOption.series = nodeDetail.diskIO.series;
      nodediskioOption.yAxis[0].axisLabel.formatter = (value, index) => {
        return value === 0 ? `(${nodeDetail.diskIO.unit})${value}` : value;
      };

      chartInstance.nodediskio.setOption(nodediskioOption, true);
      chartInstance.nodediskio.resize();
    }
  }, [chartInstance, nodeDetail]);

  const filterRacHandler = (isMove) => {
    const updatedNumbers = showRacNumArr.map((number) => (isMove === "up" ? number - 1 : number + 1));
    setShowRacNumArr(updatedNumbers);
  };
  const headerItems = [
    {
      name: "Name",
      key: "name",
      width: "256px",
      center: "no",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "IP",
      key: "ip",
      width: "200px",
      center: "no",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "생성일시",
      key: "createdDate",
      width: "180px",
      center: "no",
      type: "text",
      action: (row) => clickedRow(row),
    },
  ];
  const clickedRow = (v) => {
    console.debug(v);
  };

  const getDashboardDetail = useCallback(() => {
    if (clusterNo && chartInstance) {
      getClusterDetail(clusterNo).then((clusterRes) => {
        setClusterDetail({
          clusterDetail: clusterRes.clusterDetail,
          clusterInfo: clusterRes.clusterInfo,
          nodeInfo: clusterRes.nodeInfo,
        });

        //node list
        const newItems = [];
        clusterRes.nodeList.forEach((item, index) => {
          item.itemIndex = index + 1;
          newItems.push(item);
        });

        setOriginItems(newItems);

        //pod list
        setPodList(clusterRes.podList);

        //cluster info
        setClusterStat((prev) => {
          return [
            { ...prev[0], value: clusterRes.clusterInfo.mecOrch?.broker },
            { ...prev[1], value: clusterRes.clusterInfo.mecOrch?.user },
            { ...prev[2], value: clusterRes.clusterInfo.mecOrch?.vim },
            { ...prev[3], value: clusterRes.clusterInfo.mecOrch?.method },
          ];
        });

        // let bandwidthOption = chartInstance.bandwidth.getOption();

        // bandwidthOption.series[0].data[0].value = clusterRes.clusterInfo.networkinfo.receive_badwidth;
        // bandwidthOption.series[0].data[1].value = clusterRes.clusterInfo.networkinfo.transmit_badwidth;

        // chartInstance.bandwidth.setOption(bandwidthOption);
        // chartInstance.bandwidth.resize();

        // let packetOption = chartInstance.packet.getOption();

        // packetOption.series[0].data[0].value = clusterRes.clusterInfo.networkinfo.rate_of_received_packets;
        // packetOption.series[0].data[1].value = clusterRes.clusterInfo.networkinfo.rate_of_transmitted_packets;

        // chartInstance.packet.setOption(packetOption);
        // chartInstance.packet.resize();

        let bandpacketOption = chartInstance.bandpacket.getOption();

        bandpacketOption.series[0].data[0] = clusterRes.clusterInfo.networkinfo.receive_badwidth;
        bandpacketOption.series[1].data[0] = clusterRes.clusterInfo.networkinfo.transmit_badwidth;
        bandpacketOption.series[0].data[1] = clusterRes.clusterInfo.networkinfo.rate_of_received_packets;
        bandpacketOption.series[1].data[1] = clusterRes.clusterInfo.networkinfo.rate_of_transmitted_packets;

        chartInstance.bandpacket.setOption(bandpacketOption);
        chartInstance.bandpacket.resize();

        let diskioOption = chartInstance.diskio.getOption();

        diskioOption.legend[0].data = clusterRes.diskIo.legend;
        diskioOption.xAxis[0].data = clusterRes.diskIo.xAxis;
        diskioOption.series = clusterRes.diskIo.series;
        diskioOption.yAxis[0].axisLabel.formatter = (value, index) => {
          return value === 0 ? `(${clusterRes.diskIo.unit})${value}` : value;
        };

        chartInstance.diskio.setOption(diskioOption, true);
        chartInstance.diskio.resize();
      });
    }

    if (stackRef.current) {
      const height = stackRef.current.getBoundingClientRect().height;
      const showNum = Math.floor((height - 100) / 85);
      setShowRacNum(showNum);
    }

    setRefreshTime(getCurrentDate());
  }, [chartInstance, clusterNo, setRefreshTime]);

  useEffect(() => {
    getDashboardDetail();
  }, [getDashboardDetail]);

  useEffect(() => {
    originItems?.length > 0 && (!selectedRac ? setSelectedRac(originItems[0].id) : setNodeDetail({ ...clusterDetail.nodeInfo }[selectedRac.split("|")[1]]));
  }, [clusterDetail.nodeInfo, originItems, selectedRac]);

  useInterval(() => {
    getDashboardDetail();
  }, 10000);

  return (
    <div className="page" style={{ background: "#212529" }}>
      <div className="page-inner no-scroll">
        <div className="dash-detail-wrap flexCol">
          <ComponentHeader title={clusterDetail.clusterDetail.clusterName} subTitle={clusterDetail.clusterDetail.clusterLocation} />
          <div className="detail-contents flexRow">
            <div className="left-sec flexCol">
              <div className="mec-app-wrap box-layout">
                <div className="box-header">
                  <div className="title-wrap">
                    <em className="icon-icon_cluster" />
                    <p>클러스터 이벤트 수</p>
                  </div>
                </div>
                <div className="box-contents flexRow">
                  {clusterStat.map((item, index) => {
                    return (
                      <div className="mec-item flexRow" key={`item-${index}`}>
                        <div className="mec-item-info flexCol">
                          <p className="mec-item-label">{item.label}</p>
                          <p className="mec-item-value">{item.value}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="cluster-monitoring box-layout">
                <div className="box-header">
                  <div className="title-wrap">
                    <em className="icon-icon_cluster" />
                    <p>클러스터 모니터링</p>
                  </div>
                </div>
                <div className="box-contents flexRow">
                  <div className="cluster-info">
                    <div className="cluster-usage-wrap flexRow">
                      <Gauge label="CPU(평균)" percent={(clusterDetail.clusterInfo.headline?.cpu_req * 100).toFixed(2)} />
                      <Gauge label="Memory(평균)" percent={(clusterDetail.clusterInfo.headline?.memory_req * 100).toFixed(2)} />
                      <Gauge label="Disk(평균)" percent={parseFloat(clusterDetail.clusterInfo.storagusage)} />
                    </div>
                    <div className="cluster-disk-io" ref={chartRef1}></div>
                    <div className="cluster-other-chart flexRow">
                      <div className="cluster-bandpacket" ref={chartRef2}></div>
                      {/*<div className="cluster-bandwidth" ref={chartRef2}></div>
               <div className="cluster-packet" ref={chartRef3}></div>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="center-sec box-layout">
              <div className="box-header">
                <div className="title-wrap">
                  <em className="icon-icon_cluster_stack" />
                  <p>노드 정보</p>
                </div>
              </div>
              <div className="box-contents flexRow">
                <div className="stack-wrap flexCol" ref={stackRef}>
                  <div className="stack-top" />
                  {showRacNum &&
                    originItems.map((item, index) => {
                      return (
                        showRacNumArr.includes(item.itemIndex) && (
                          <div
                            className={`stack-item flexRow ${selectedRac === item.id ? "active" : ""}`}
                            key={`item-${index}`}
                            onClick={() => setSelectedRac(item.id)}
                          >
                            <div className="stack-info flexCol">
                              <p className="stack-name">{item.name}</p>
                              <p>{item.ip}</p>
                              <p>{item.date}</p>
                            </div>
                          </div>
                        )
                      );
                    })}
                </div>
                {showRacNum && (
                  <div className="stack-control-wrap flexCol">
                    <IconButton className={`move-stack ${showRacNumArr[0] === 1 && "disabled"}`} onClick={() => filterRacHandler("up")}>
                      <Icon path={mdiChevronUp} size="24px" color={showRacNumArr[0] === 1 ? "#343a40" : "#fff"} />
                    </IconButton>
                    <IconButton
                      className={`move-stack ${showRacNumArr[showRacNumArr.length - 1] === originItems[originItems?.length - 1]?.itemIndex && "disabled"}`}
                      onClick={() => filterRacHandler("down")}
                    >
                      <Icon
                        path={mdiChevronDown}
                        size="24px"
                        color={showRacNumArr[showRacNumArr.length - 1] === originItems[originItems?.length - 1]?.itemIndex ? "#343a40" : "#fff"}
                      />
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
            <div className="right-sec box-layout">
              <div className="box-header">
                <div className="title-wrap">
                  <em className="icon-icon_cluster_stack" />
                  <p>{`${[selectedRac?.split("|")[1]] ?? ""}`}</p>
                </div>
              </div>
              <div className="box-contents flexCol">
                <div className="pod-info-wrap flexCol">
                  <p className="title">노드 내 파드 정보</p>
                  <div className="pod-info-table-wrap">
                    <CustomTable header={getTableHeader(headerItems)} rowData={podList[selectedRac?.split("|")[1]]} perPage={3} rowClickCallBack={clickedRow} />
                  </div>
                </div>
                <div className="node-monitoring flexCol">
                  <p className="title">노드 모니터링</p>
                  <MultiGauge cpu={(nodeDetail?.cpu * 100).toFixed(2)} mem={(nodeDetail?.memory * 100).toFixed(2)} disk={nodeDetail?.disk} />
                </div>
                <div className="cluster-disk-io" ref={chartRef4}></div>
                <div className="cluster-bar-io" ref={chartRef5}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DashboardDetail;

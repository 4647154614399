import ClusterMap from "components/clustermap/ClusterMap";
import Gauge from "components/gauge/Gauge";
import {
  axiosCancel,
  getAppStatus,
  getClusterDetail,
  getCurrentDate,
  getOrchStatus,
  getServiceData,
  getTop5Service,
  getUserStatus,
  useInterval,
} from "data/CommonFunction";
import { chart1Option, chart5Option, mecAppInfo, mecServiceInfo, orchestrationInfo, userStatus } from "data/DashboardMock";
import { refreshTimeAtom } from "data/Store";
import * as echarts from "echarts";
import { useSetAtom } from "jotai";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Dashboard.scss";

const Dashboard = () => {
  const [appStatus, setAppStatus] = useState(mecAppInfo);
  const [serviceStatus, setServiceStatus] = useState(mecServiceInfo);
  const [userStat, setUserStat] = useState(userStatus);
  const [orchStatus, setOrchStatus] = useState(orchestrationInfo);
  const [clusterDetail, setClusterDetail] = useState({
    clusterDetail: {},
    clusterInfo: {},
  });
  const [chartInstance, setChartInstance] = useState({});
  const setRefreshTime = useSetAtom(refreshTimeAtom);
  const [top5MecService, setTop5MecService] = useState([]);
  const [activeLocal, setActiveLocal] = useState();
  const [activeClusterId, setActiveClusterId] = useState();

  const navigate = useNavigate();

  const activeLocalHandler = (local) => {
    setActiveLocal(activeLocal === local ? "" : local);
  };
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);

  useEffect(() => {
    let chartInst = {};

    if (chartRef1.current) {
      const chart = echarts.init(chartRef1.current, "dark");
      chart.setOption(chart1Option);
      chartInst["diskio"] = chart;
    }
    // if (chartRef2.current) {
    //   const chart = echarts.init(chartRef2.current, "dark");
    //   chart.setOption(chart2Option);
    //   chartInst["bandwidth"] = chart;
    // }
    // if (chartRef3.current) {
    //   const chart = echarts.init(chartRef3.current, "dark");
    //   chart.setOption(chart3Option);
    //   chartInst["packet"] = chart;
    // }
    if (chartRef2.current) {
      const chart = echarts.init(chartRef2.current, "dark");
      chart.setOption(chart5Option);
      chartInst["bandpacket"] = chart;
    }
    // chartInst.diskio && chartInst.bandwidth && chartInst.packet && setChartInstance(chartInst);
    chartInst.diskio && chartInst.bandpacket && setChartInstance(chartInst);
  }, [chartRef1, chartRef2, chartRef3]);

  const getDashboardData = useCallback(() => {
    if (activeClusterId) {
      axiosCancel();

      getAppStatus().then((appStat) => {
        appStat &&
          setAppStatus((prev) => {
            return [
              { ...prev[0], value: appStat.user },
              { ...prev[1], value: appStat.app },
              { ...prev[2], value: appStat.init },
              { ...prev[3], value: appStat.run },
              { ...prev[4], value: appStat.stop },
            ];
          });
      });

      getServiceData().then((svcStat) => {
        svcStat &&
          setServiceStatus((prev) => {
            return [
              { ...prev[0], value: svcStat.total },
              { ...prev[1], value: svcStat.requested },
              { ...prev[2], value: svcStat.approved },
            ];
          });
      });

      getUserStatus().then((usrStat) => {
        usrStat &&
          setUserStat((prev) => {
            return [
              { ...prev[0], value: usrStat.approved, label: "승인된 사용자 수" },
              { ...prev[1], value: usrStat.requested, label: "요청한 사용자 수" },
              { ...prev[2], value: usrStat.rejected, label: "거절된 사용자 수" },
            ];
          });
      });

      getOrchStatus().then((orchStat) => {
        orchStat &&
          setOrchStatus((prev) => {
            return [
              { ...prev[0], value: orchStat.year, label: "연간 건수" },
              { ...prev[1], value: orchStat.month, label: "월간 건수" },
              { ...prev[2], value: orchStat.day, label: "일간 건수" },
            ];
          });
      });

      getTop5Service().then((top5Stat) => {
        top5Stat && setTop5MecService(top5Stat);
      });

      getClusterDetail(activeClusterId).then((clusterRes) => {
        if (clusterRes) {
          setClusterDetail({
            clusterDetail: clusterRes.clusterDetail,
            clusterInfo: clusterRes.clusterInfo,
          });

          let bandpacketOption = chartInstance.bandpacket.getOption();

          bandpacketOption.series[0].data[0] = clusterRes.clusterInfo.networkinfo?.receive_badwidth ?? 0;
          bandpacketOption.series[1].data[0] = clusterRes.clusterInfo.networkinfo?.transmit_badwidth ?? 0;
          bandpacketOption.series[0].data[1] = clusterRes.clusterInfo.networkinfo?.rate_of_received_packets ?? 0;
          bandpacketOption.series[1].data[1] = clusterRes.clusterInfo.networkinfo?.rate_of_transmitted_packets ?? 0;

          chartInstance.bandpacket.setOption(bandpacketOption);
          chartInstance.bandpacket.resize();

          let diskioOption = chartInstance.diskio.getOption();

          diskioOption.legend[0].data = clusterRes.diskIo.legend;
          diskioOption.xAxis[0].data = clusterRes.diskIo.xAxis;
          diskioOption.series = clusterRes.diskIo.series;
          diskioOption.yAxis[0].axisLabel.formatter = (value, index) => {
            return value === 0 ? `(${clusterRes.diskIo.unit})${value}` : value;
          };

          chartInstance.diskio.setOption(diskioOption, true);
          chartInstance.diskio.resize();
        }
      });
    }

    setRefreshTime(getCurrentDate());
  }, [activeClusterId, chartInstance, setRefreshTime]);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  useInterval(() => {
    getDashboardData();
  }, 10000);

  return (
    <div
      className="page"
      style={{
        background: "#212529",
      }}
    >
      <div className="page-inner no-scroll">
        <div className="dash-wrap">
          <div className="left-sec flexCol">
            <div className="mec-app-wrap box-layout">
              <div className="box-header">
                <div className="title-wrap">
                  <em className="icon-icon_mgmtapp" />
                  <p>MEC 앱 정보</p>
                </div>
                <em className="icon-icon_open_pop" onClick={() => navigate(`/admin/mgmtapp`)} />
              </div>
              <div className="box-contents flexRow">
                {appStatus.map((item, index) => {
                  return (
                    <div className="mec-item flexRow" key={`item-${index}`}>
                      <em className={item.icon} />
                      <div className="mec-item-info">
                        <p className="mec-item-label">{item.label}</p>
                        <p className="mec-item-value">{item.value}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="cluster-wrap box-layout">
              <div className="box-header">
                <div className="title-wrap">
                  <em className="icon-icon_cluster" />
                  <p>클러스터 정보</p>
                </div>
                <em className="icon-icon_open_pop" onClick={() => navigate(`/admin/monitoring`)} />
              </div>
              <div className="box-contents flexRow">
                <div className="map-sec">
                  <ClusterMap
                    selectedLocal={activeLocal}
                    localSelect={activeLocalHandler}
                    selectedClusterId={activeClusterId}
                    clusterSelect={setActiveClusterId}
                  />
                </div>
                <div className="cluster-info">
                  <div
                    className={
                      "cluster-name-wrap flexRow" + (!clusterDetail.clusterInfo.networkinfo || clusterDetail.clusterInfo.vimStat === "fail" ? " disabled" : "")
                    }
                    onClick={() =>
                      !clusterDetail.clusterInfo.networkinfo || clusterDetail.clusterInfo.vimStat === "fail"
                        ? null
                        : navigate(`/admin/main/detail/${activeClusterId}`)
                    }
                  >
                    <em className="icon-icon_cluster_stack" />
                    <div className="flexCol">
                      <p className="cluster-name">{clusterDetail.clusterDetail.clusterName}</p>
                      <p className="cluster-address">{clusterDetail.clusterDetail.clusterLocation}</p>
                    </div>
                  </div>
                  <div className="cluster-usage-wrap flexRow">
                    <Gauge label="CPU(평균)" percent={((clusterDetail.clusterInfo.headline?.cpu_req || 0) * 100).toFixed(2)} />
                    <Gauge label="Memory(평균)" percent={((clusterDetail.clusterInfo.headline?.memory_req || 0) * 100).toFixed(2)} />
                    <Gauge label="Disk(평균)" percent={clusterDetail.clusterInfo.storagusage || 0} />
                  </div>
                  <div className="cluster-disk-io" ref={chartRef1}></div>
                  <div className="cluster-other-chart flexRow">
                    <div className="cluster-bandpacket" ref={chartRef2} />
                    {/*<div className="cluster-bandwidth" ref={chartRef2}></div>
              <div className="cluster-packet" ref={chartRef3}></div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right-sec flexCol">
            <div className="mec-service-wrap box-layout">
              <div className="box-header">
                <div className="title-wrap">
                  <em className="icon-icon_service" />
                  <p>MEC 서비스 정보</p>
                </div>
                <em className="icon-icon_open_pop" onClick={() => navigate(`/admin/mgmtsv`)} />
              </div>
              <div className="box-contents">
                <div className="service-info-wrap flexRow">
                  {serviceStatus.map((item, index) => {
                    return (
                      <div className="mec-item-info" key={`item-${index}`}>
                        <p className="mec-item-label">{item.label}</p>
                        <p className="mec-item-value">{item.value}</p>
                      </div>
                    );
                  })}
                </div>
                <div className="line" />
                <div className="top5-service-wrap">
                  <p className="title">상위 5 MEC 서비스</p>
                  <div className="mec-bar-wrap flexCol">
                    {top5MecService?.map((item, index) => {
                      return (
                        <div className="mec-bar-item flexRow" key={`item-${index}`}>
                          <div className="bar flexRow">
                            <div
                              className="fill"
                              style={{
                                width: `${(item.use_cnt / item.total_cnt) * 100}%`,
                              }}
                            />
                            <p>{item.service_name}</p>
                          </div>
                          <p>{item.use_cnt}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="status-wrap flexRow">
              <div className="user-status-wrap box-layout">
                <div className="box-header">
                  <div className="title-wrap">
                    <em className="icon-icon_user" />
                    <p>유저 정보</p>
                  </div>
                  <em className="icon-icon_open_pop" onClick={() => navigate(`/admin/mgmtuser`)} />
                </div>
                <div className="box-contents flexCol" style={{ paddingTop: "0px" }}>
                  {userStat.map((item, index) => {
                    return (
                      <div className="flex-col-item flexCol" key={`item-${index}`}>
                        <p className="item-title">{item.label}</p>
                        <p className="item-value">{item.value}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="orchestration-wrap box-layout">
                <div className="box-header">
                  <div className="title-wrap">
                    <em className="icon-icon_vim" />
                    <p>오케스트레이션</p>
                  </div>
                  <em className="icon-icon_open_pop" onClick={() => navigate(`/admin/orchestrationvim`)} />
                </div>
                <div className="box-contents flexCol" style={{ paddingTop: "0px" }}>
                  {orchStatus.map((item, index) => {
                    return (
                      <div className="flex-col-item flexCol" key={`item-${index}`}>
                        <p className="item-title">{item.label}</p>
                        <p className="item-value">{item.value}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import "./LogViewer.scss";

export const LogViewer = ({ log }) => {
  let lines = log.split("\n");

  const maxLength = 28;
  const initialLinesLength = lines.length;

  if (initialLinesLength < maxLength) {
    const emptyLines = Array(maxLength - initialLinesLength).fill("");
    lines = lines.concat(emptyLines);
  }

  return (
    <div>
      <div className="log-wrap">
        {lines.map((line, index) => (
          <div key={index} className="log-line flexRow">
            <div className="line-number flexRow">
              <p>{index + 1}</p>
            </div>
            <pre className="log-content">
              <p>{line}</p>
            </pre>
          </div>
        ))}
      </div>
    </div>
  );
};
export default LogViewer;

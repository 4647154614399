import { mdiAccountCircle, mdiClockOutline } from "@mdi/js";
import Icon from "@mdi/react";
import MyInfoModal from "components/myinfo_modal/MyInfoModal";
import { refreshTimeAtom, userAuthAtom } from "data/Store";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Hamburger from "../../assets/images/icons/icon_hamburger.svg";
import GigaMEC from "../../assets/images/logo2.png";
import "./Layout.scss";

const Header = () => {
  const [useHeader, setUseHeader] = useState(true);
  const location = useLocation();
  const currentPath = location.pathname;

  const refreshTime = useAtomValue(refreshTimeAtom);
  const [userAuth, setUserAuth] = useAtom(userAuthAtom);

  //유저타입부분 *조건 변경요망
  const userType = userAuth.mecUserTypeNo === 0 ? "admin" : "user";
  const [showMyInfo, setShowMyInfo] = useState(false);

  const menuRef = useRef();

  const navigate = useNavigate();
  useEffect(() => {
    if (currentPath) {
      setUseHeader(currentPath.includes("login") ? false : true);
    }
    setIsShowMenu(false);
  }, [currentPath]);

  const [isShowMenu, setIsShowMenu] = useState(false);

  useEffect(() => {
    const clickOutside = (e) => {
      if (isShowMenu && !menuRef.current.contains(e.target)) {
        setIsShowMenu(false);
      }
    };

    document.addEventListener("mousedown", clickOutside);

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [isShowMenu]);

  const [isShowUserDetail, setIsShowUserDetail] = useState(false);
  if (!useHeader) {
    return null;
  }

  const navigateHandler = (path) => {
    navigate(path);
    setIsShowMenu(false);
  };

  const getActiveMenu = (path) => {
    if (
      (path === "/" && (currentPath === "/" || currentPath === "/detail" || currentPath.startsWith("/detail/"))) ||
      currentPath === path ||
      currentPath.startsWith(`${path}/`)
    ) {
      return true;
    }

    return false;
  };

  const adminMenuItems = [
    {
      label: "대시보드",
      path: "/admin/main",
      icon: "icon-icon_dashboard",
    },
    {
      label: "앱 관리",
      path: "/admin/mgmtapp",
      icon: "icon-icon_mgmtapp",
    },
    {
      label: "서비스 관리",
      path: "/admin/mgmtsv",
      icon: "icon-icon_service",
    },
    {
      label: "모니터링",
      path: "/admin/monitoring",
      icon: "icon-icon_monitoring",
    },
    {
      label: "오케스트레이션/VIM",
      path: "/admin/orchestrationvim",
      icon: "icon-icon_vim",
    },
    // {
    //   label: "오케스트레이션",
    //   path: "/admin/orchestration",
    //   icon: "icon-icon_ocastration",
    // },
    // {
    //   label: "VIM",
    //   path: "/admin/vim",
    //   icon: "icon-icon_vim",
    // },
    {
      label: "사용자 관리",
      path: "/admin/mgmtuser",
      icon: "icon-icon_user",
    },
    {
      label: "클러스터 관리",
      path: "/admin/mgmtcluster",
      icon: "icon-icon_ocastration",
    },
  ];
  const userMenuItems = [
    {
      label: "대시보드",
      path: "/user/main",
      icon: "icon-icon_dashboard",
    },
    {
      label: "내 MEC 앱",
      path: "/user/mymec",
      icon: "icon-icon_mgmtapp",
    },
    {
      label: "내 MEC 서비스",
      path: "/user/myservice",
      icon: "icon-icon_service",
    },
    {
      label: "모니터링",
      path: "/user/monitoring",
      icon: "icon-icon_monitoring",
    },
  ];

  const getMenuItems = () => {
    if (userType === "admin") {
      return adminMenuItems;
    }
    return userMenuItems;
  };

  return (
    <div className="main-header flexRow">
      {showMyInfo && <MyInfoModal isShowHandler={setShowMyInfo} />}
      <div className={`sub-menu-wrap ${isShowMenu ? "active-menu" : ""}`} ref={menuRef}>
        {getMenuItems().map((item, index) => {
          return (
            <div className={`sub-menu-item ${getActiveMenu(item.path) ? "active" : ""}`} key={`item-${index}`} onClick={() => navigateHandler(item.path)}>
              <em className={item.icon} />
              {item.label}
            </div>
          );
        })}
      </div>

      <div className="logo-sec flexRow">
        <button className="hamburger-btn flexCol" onClick={() => setIsShowMenu(!isShowMenu)}>
          <img src={Hamburger} alt="Hamburger" />
        </button>
        <Link to="/">
          <img src={GigaMEC} alt="GigaMEC" />
        </Link>
        <div className="path-wrap flexRow">
          <div className="ver-line" />
          {currentPath === "/admin/mgmtapp" && (
            <div className="flexRow">
              <p>앱 관리</p>
            </div>
          )}
          {currentPath === "/admin/mgmtsv" && (
            <div className="flexRow">
              <p>서비스 관리</p>
            </div>
          )}
          {currentPath.includes("/monitoring") && (
            <div className="flexRow">
              <p>모니터링</p>
            </div>
          )}
          {currentPath === "/admin/orchestrationvim" && (
            <div className="flexRow">
              <p>오케스트레이션/VIM</p>
            </div>
          )}
          {/* {currentPath === "/admin/orchestration" && (
            <div className="flexRow">
              <p>오케스트레이션</p>
            </div>
          )}
          {currentPath === "/admin/vim" && (
            <div className="flexRow">
              <p>VIM</p>
            </div>
          )} */}
          {currentPath === "/admin/mgmtuser" && (
            <div className="flexRow">
              <p>사용자 관리</p>
            </div>
          )}
          {currentPath === "/admin/mgmtcluster" && (
            <div className="flexRow">
              <p>클러스터 관리</p>
            </div>
          )}
          {currentPath === "/user/myservice" && (
            <div className="flexRow">
              <p>내 MEC 서비스</p>
            </div>
          )}
          {currentPath === "/user/mymec" && (
            <div className="flexRow">
              <p>내 MEC 앱</p>
            </div>
          )}
        </div>
      </div>
      <div className="nav-sec flexRow">
        <div className="logo-sec-date flexRow">
          <Icon path={mdiClockOutline} size="36px" color="#c9ced4" /> {refreshTime}
        </div>
        <div className="ver-line" />
        <div className="user-id flexRow" onClick={() => setIsShowUserDetail(!isShowUserDetail)}>
          <Icon path={mdiAccountCircle} size="36px" color="#adb5bd" />
          {userAuth.userName}
          {!isShowUserDetail && (
            <div className="userinfo-hover flexRow">
              <Icon path={mdiAccountCircle} size="36px" color="#fff" />
              <p>{userAuth.userName}</p>
            </div>
          )}
          {isShowUserDetail && (
            <div className="userinfo-detail flexCol">
              {userAuth.mecUserTypeNo === 0 ? (
                <p className="user-type admin">
                  <em className="icon-icon_sheild" />
                  {userAuth.userRole}
                </p>
              ) : (
                <p className="user-type user">
                  <em className="icon-icon_sheild" />
                  {userAuth.userRole}
                </p>
              )}
              <div className="user-id-email-wrap">
                <p className="user-info-id">{userAuth.userName}</p>
                <p className="user-info-email">{userAuth.userEmail}</p>
              </div>
              <div className="hor-line" />
              <div className="user-info-btn-wrap flexCol">
                <button onClick={() => setShowMyInfo(true)}>
                  <em className="icon-icon_myinfo" />내 정보
                </button>
                <button onClick={() => setUserAuth({ mecUserTypeNo: -1 })}>
                  <em className="icon-icon_logout" />
                  로그아웃
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;

import GigaMEC from "assets/images/gigiMec-logo.png";
import Etri from "assets/images/etri-logo.png";
import "./LoginWrap.scss";

const LoginWrap = ({ children }) => {
  return (
    <div className={"login-wrap"}>
      <div className={"logo-area"}>
        <img
          src={GigaMEC ?? ""}
          width={100}
          height={70}
          sizes={"15px"}
          alt="GigaMEC"
        />
        <img
          src={Etri ?? ""}
          width={250}
          height={50}
          sizes={"15px"}
          alt="GigaMEC"
        />
      </div>
      {children}
    </div>
  );
};
export default LoginWrap;

import { mdiAlert, mdiChevronDoubleLeft, mdiChevronDoubleRight, mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "./CustomTable.scss";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import addDays from "date-fns/addDays";
import CustomSelect from "../../components/customselect/CustomSelect";

function CustomTable({ header, rowData, usePerPageSelect, use10PerPage, perPage, rowClickCallBack }) {
  const [tableHeader, setHeader] = useState([]);
  const [tableRowData, setRowData] = useState([]);
  const [paginationPerPage, setPaginationPerPage] = useState(15);
  useEffect(() => {
    if (header.length && rowData) {
      setHeader([...header]);
      setRowData([...rowData]);
      // setCurrentPage(1);

      if (!usePerPageSelect && perPage) {
        setPaginationPerPage(perPage);
      }
      if (usePerPageSelect && use10PerPage) {
        setPaginationPerPage(5);
      }
    }
  }, [header, perPage, rowData, use10PerPage, usePerPageSelect]);

  // const filteredData = tableRowData.filter((item) =>
  //   item.name.toLowerCase().includes(searchText.toLowerCase())
  // );
  const filteredData = tableRowData;

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: "All",
  };

  const paginationPerPageItems = [
    {
      label: 15,
      value: 15,
    },
    {
      label: 30,
      value: 30,
    },
    {
      label: 45,
      value: 45,
    },
  ];
  const paginationPerPageItems10 = [
    {
      label: 5,
      value: 5,
    },
    {
      label: 10,
      value: 10,
    },
    {
      label: 15,
      value: 15,
    },
  ];
  const handlePaginationPerPageItem = (v) => {
    setCurrentPage(1);
    setPaginationPerPage(v.value);
  };

  const customPagination = () => {
    const page = Math.ceil(filteredData.length / paginationPerPage) || 1; 
    const pageButtons = [];
    const displayPageCount = 5;
    let startPage = Math.max(1, currentPage - Math.floor(displayPageCount / 2));
    let endPage = startPage + displayPageCount - 1;

    if (endPage > page) {
      endPage = page;
      startPage = Math.max(1, endPage - displayPageCount + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button key={i} className={`btn-pagination ${currentPage === i ? "active-btn" : ""}`} onClick={() => setCurrentPage(i)}>
          {i}
        </button>
      );
    }

    return (
      <div className="pagination-container">
        {/* <p className="total-page-text">{`${currentPage} - ${page} / ${paginationPerPage} Total Row`}</p> */}
        <div className="page-control flexRow">
          <button className="btn-pagination" onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>
            <Icon path={mdiChevronDoubleLeft} size="17px" color={currentPage === 1 ? "#343A40" : "#868E96"} />
          </button>
          <button className="btn-pagination" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
            <Icon path={mdiChevronLeft} size="17px" color={currentPage === 1 ? "#343A40" : "#868E96"} />
          </button>
          {pageButtons}
          <button className="btn-pagination" onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === page}>
            <Icon path={mdiChevronRight} size="17px" color={currentPage === page ? "#343A40" : "#868E96"} />
          </button>
          <button className="btn-pagination" onClick={() => setCurrentPage(page)} disabled={currentPage === page}>
            <Icon path={mdiChevronDoubleRight} size="17px" color={currentPage === page ? "#343A40" : "#868E96"} />
          </button>
          {usePerPageSelect && (
            <div className="custom-select-wrapper-perpage">
              <CustomSelect
                value={paginationPerPage}
                items={use10PerPage ? paginationPerPageItems10 : paginationPerPageItems}
                selectCallBack={handlePaginationPerPageItem}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const [currentPage, setCurrentPage] = useState(1);
  const paginatedDataFunc = () => {
    const dataTmp = filteredData.slice((currentPage - 1) * paginationPerPage, currentPage * paginationPerPage);

    if(dataTmp.length ===  0 && currentPage !== 1) {
      setCurrentPage(1)
    }

    if (dataTmp.length < paginationPerPage) {
      for (let i = dataTmp.length; i < paginationPerPage; i++) {
        dataTmp.push({})
      }
    }

    return dataTmp;
  };
  const paginatedData = paginatedDataFunc();

  const noDataDiv = (
    <div className="table-no-data-wrap flexCol">
      <Icon size="35px" path={mdiAlert} color="#868E96" />
      <p>데이터가 존재 하지 않습니다.</p>
    </div>
  );

  const getDataTable = (
    <DataTable
      className="custom-table"
      columns={tableHeader}
      data={paginatedData}
      pagination
      paginationServer
      paginationTotalRows={filteredData.length}
      onChangePage={(page) => setCurrentPage(page)}
      paginationComponentOptions={paginationOptions}
      paginationComponent={customPagination}
      noDataComponent={noDataDiv}
      highlightOnHover
      pointerOnHover
      onRowClicked={(row) => {
        rowClickCallBack(row);
      }}
    />
  );

  return (
    <>
      <div className="custom-table-wrap">{getDataTable}</div>
    </>
  );
}

export default CustomTable;
